import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { initialize, reduxForm, Field, change } from "redux-form";
import { useHistory } from "react-router-dom";
import i18n from "i18next";
import M from "materialize-css";

import PageTitle from "../../components/UI/PageTitle";
import PageContainer from "../../components/UI/PageContainer";
import ContentContainer from "../../components/UI/ContentContainer";
import Spacer from "../../components/UI/Spacer";
import FormButton from "../../components/UI/FormButton";
import Divider from "../../components/UI/Divider";
import ScreensUserSelector from "../../screens/User/Selector";
import ScreensUserNew from "../../screens/User/New";
import DatePicker from "../../components/UI/DatePicker";

import {
    renderFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns,
    renderTextareaOneColumn,
    renderCheckFieldTwoColumns,
} from "../../components/UI/Fileds";

import { sendCustomer } from "../../actions/customer";
import {
    startLoading,
    endLoading,
    fetchDemarcacionList,
} from "../../actions/ui";
// import { fetchCustomerUserList, sendUser } from '../../actions/user';

import { showError, showConfirmation } from "../../helpers/messages";
import { dateToDatepicker, stringTrim } from "../../helpers/converter";
import { isEmail, max2digits } from "../../helpers/validators";

const ScreensCustomerForm = ({ submitting, handleSubmit, dirty }) => {
    const fileInputFoto = React.createRef();

    const fileInputAdjunto = React.createRef();

    const { t } = useTranslation();

    const { user: userLogged } = useSelector((state) => state.auth);

    const { selected } = useSelector((state) => state.customer);

    const [user, setUser] = useState(
        selected && selected.user
            ? selected.user
            : { id: "", label: t("selector-user-no-selected") }
    );

    const { demarcacionList } = useSelector((state) => state.ui);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchDemarcacionList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll("select");
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            dispatch(
                initialize("customerForm", {
                    id: selected.id,
                    nombre_comercial: selected.nombre_comercial,
                    razon_social: selected.razon_social,
                    persona_contacto: selected.persona_contacto,
                    email: selected.email,
                    nif: selected.nif,
                    telefono_fijo: selected.telefono_fijo,
                    telefono_movil: selected.telefono_movil,
                    trabajo: selected.trabajo,
                    direccion: selected.direccion,
                    codigo_postal: selected.codigo_postal,
                    poblacion: selected.poblacion,
                    provincia: selected.provincia,
                    pais: selected.pais,
                    otros_datos: selected.otros_datos,
                    // foto: selected.foto,
                    // foto_local: selected.foto_local,
                    // adjunto: selected.adjunto,
                    // adjunto_local: selected.adjunto_local,
                    // user_id: selected.user_id,
                    // demarcacion_id: selected.demarcacion_id,
                })
            );

            //   if (selected.user) {
            //     setUser(selected.user);
            //     dispatch(change("customerForm", "user_id", selected.user.id));
            //   }
        } else {
            dispatch(
                initialize("customerForm", {
                    nombre_comercial: "",
                    razon_social: "",
                    persona_contacto: "",
                    email: "",
                    nif: "",
                    telefono_fijo: "",
                    telefono_movil: "",
                    trabajo: "",
                    direccion: "",
                    codigo_postal: "",
                    poblacion: "",
                    provincia: "",
                    pais: "",
                    otros_datos: "",
                    // foto: '',
                    // foto_local: '',
                    // adjunto: '',
                    // adjunto_local: ''
                })
            );
        }

        const elems = document.querySelectorAll(".modal");
        M.Modal.init(elems, {});
    }, [dispatch, selected]);

    const handleSend = (values) => {
        dispatch(startLoading());

        // const archivoFoto = fileInputFoto.current.files[0];
        // const archivoAdjunto = fileInputAdjunto.current.files[0];

        dispatch(
            sendCustomer({
                ...values,
                nombre_comercial: stringTrim(values.nombre_comercial),
                razon_social: stringTrim(values.razon_social),
                persona_contacto: stringTrim(values.persona_contacto),
                email: stringTrim(values.email),
                nif: stringTrim(values.nif),
                telefono_fijo: stringTrim(values.telefono_fijo),
                telefono_movil: stringTrim(values.telefono_movil),
                trabajo: stringTrim(values.trabajo),
                direccion: stringTrim(values.direccion),
                codigo_postal: stringTrim(values.codigo_postal),
                poblacion: stringTrim(values.poblacion),
                provincia: stringTrim(values.provincia),
                pais: stringTrim(values.pais),
                otros_datos: stringTrim(values.otros_datos),
                // foto: archivoFoto,
                // adjunto: archivoAdjunto,
            })
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t("message-title-error"), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t("message-title-confirmation"),
                t("message-abandom-form-text"),
                t("button-title-exit"),
                t("button-title-keep-here"),
                () => {
                    history.goBack();
                }
            );
        } else {
            history.goBack();
        }
    };

    const handleSearchUser = () => {
        const elem = document.getElementById("modal-selector-usuario");
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectUser = (user) => {
        const elem = document.getElementById("modal-selector-usuario");
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setUser(user);
        dispatch(change("customerForm", "user_id", user.id));
    };

    const handleShowNewUser = () => {
        const emailValue = document.getElementById("email").value;
        if (emailValue && isEmail(emailValue)) {
            dispatch(change("userNewForm", "email", emailValue));
            const elem = document.getElementById("modal-nuevo-usuario");
            const instance = M.Modal.getInstance(elem);
            instance.open();
        } else {
            const elem = document.getElementById("modal-nuevo-usuario");
            const instance = M.Modal.getInstance(elem);
            instance.close();
            showError(t("message-title-error"), t("message-email-before-new-user"));
        }
    };

    // const handleNewUser = (values) => {
    //     const elem = document.getElementById('modal-nuevo-usuario');
    //     const instance = M.Modal.getInstance(elem);
    //     instance.close();

    //     dispatch(startLoading());
    //     dispatch(
    //         sendUser({ Users: values })
    //     ).then((res) => {
    //         dispatch(endLoading());

    //         if (res.ok) {
    //             setUser(res.message.user);
    //             dispatch(change('customerForm', 'user_id', res.message.user.id));
    //         } else {
    //             showError(t('message-title-error'), t(res.message));
    //         }
    //     });
    // };

    const isValidArea = (demarcacion_id) => {
        if (userLogged.organizadores.length > 0) {
            const demarcacionUser = userLogged.organizadores[0].demarcacion.id;
            const demarcacionParentUser =
                userLogged.organizadores[0].demarcacion.parent_id;
            return (
                demarcacionUser === demarcacion_id ||
                demarcacionParentUser === demarcacion_id
            );
            // return userLogged.organizadores[0].demarcacion_id === demarcacion_id;
        } else if (
            userLogged.alumnos.length > 0 &&
            userLogged.alumnos[0].formadores_homologados.length > 0
        ) {
            const demarcacionUser =
                userLogged.alumnos[0].formadores_homologados.demarcacion.id;
            const demarcacionParentUser =
                userLogged.alumnos[0].formadores_homologados.demarcacion.parent_id;
            return (
                demarcacionUser === demarcacion_id ||
                demarcacionParentUser === demarcacion_id
            );
            // return userLogged.alumnos[0].formadores_homologados.demarcacion_id === demarcacion_id;
        } else {
            return true;
        }
    };

    const renderForm = () => {
        return (
            <form onSubmit={handleSubmit(handleSend)}>
                {/* Campos del formulario */}
                <div className="row margin">
                    {selected ? (
                        <Field
                            type="hidden"
                            name="id"
                            id="id"
                            component={renderFieldOneColumn}
                        />
                    ) : (
                        ""
                    )}

                    <Field
                        type="text"
                        name="nombre_comercial"
                        id="nombre_comercial"
                        label={t("screens-customer-list-nombre_comercial")}
                        component={renderFieldTwoColumns}
                        icon="business"
                        key="nombre"
                        maxLength="128"
                    />

                    <Field
                        type="text"
                        name="razon_social"
                        id="razon_social"
                        label={t("screens-customer-list-razon_social")}
                        component={renderFieldTwoColumns}
                        icon="business"
                        key="razon_social"
                        maxLength="128"
                    />
                </div>
                <div className="row margin">
                    <Field
                        type="text"
                        name="persona_contacto"
                        id="persona_contacto"
                        label={t("screens-customer-list-persona_contacto")}
                        component={renderFieldTwoColumns}
                        icon="person"
                        key="persona_contacto"
                        maxLength="128"
                    />
                    <Field
                        type="text"
                        name="nif"
                        id="nif"
                        label={t("screens-customer-list-nif")}
                        component={renderFieldTwoColumns}
                        icon="fingerprint"
                        key="nif"
                        maxLength="9"
                    />
                </div>

                <Divider />

                <div className="row margin">

                    <Field
                        type="text"
                        name="telefono"
                        id="telefono"
                        label={t("screens-customer-list-telefono")}
                        component={renderFieldTwoColumns}
                        icon="phone"
                        key="telefono"
                        maxLength="24"
                    />

                    <Field
                        type="text"
                        name="email"
                        id="email"
                        label={t("screens-customer-list-email")}
                        component={renderFieldTwoColumns}
                        icon="email"
                        key="email"
                        maxLength="128"
                    />

                </div>
                <Divider />

                <div className="row margin">
                    <Field
                        type="text"
                        name="trabajo"
                        id="trabajo"
                        label={t("screens-customer-list-trabajo")}
                        component={renderFieldTwoColumns}
                        icon="work"
                        key="trabajo"
                        maxLength="128"
                    />
                    <Field
                        type="text"
                        name="direccion"
                        id="direccion"
                        label={t("screens-customer-list-direccion")}
                        component={renderFieldTwoColumns}
                        icon="address"
                        key="direccion"
                        maxLength="256"
                    />
                </div>

                <div className="row margin">
                    <Field
                        type="text"
                        name="poblacion"
                        id="poblacion"
                        label={t("screens-customer-list-poblacion")}
                        component={renderFieldTwoColumns}
                        icon="location_city"
                        key="poblacion"
                        maxLength="128"
                    />
                    <Field
                        type="text"
                        name="provincia"
                        id="provincia"
                        label={t("screens-customer-list-provincia")}
                        component={renderFieldTwoColumns}
                        icon="map"
                        key="provincia"
                        maxLength="128"
                    />
                </div>

                <div className="row margin">
                    <Field
                        type="text"
                        name="codigo_postal"
                        id="codigo_postal"
                        label={t("screens-customer-list-codigo_postal")}
                        component={renderFieldTwoColumns}
                        icon="domain"
                        key="codigo_postal"
                        maxLength="5"
                    />
                    <Field
                        type="text"
                        name="pais"
                        id="pais"
                        label={t("screens-customer-list-pais")}
                        component={renderFieldTwoColumns}
                        icon="map"
                        key="pais"
                        maxLength="128"
                    />
                </div>

                <div className="row margin">
                    <Field
                        type="text"
                        name="otros_datos"
                        id="otros_datos"
                        label={t("screens-customer-list-otros_datos")}
                        component={renderTextareaOneColumn}
                        icon="assignment"
                        key="otros_datos"
                        maxLength="512"
                    />
                </div>

                <Divider />

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton
                            type="submit"
                            title={t("form-button-send")}
                            disabled={submitting}
                        />

                        <FormButton
                            type="reset"
                            title={t("form-button-cancel")}
                            disabled={submitting}
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={t("screens-customer-form-title")} />

            <ContentContainer>{renderForm()}</ContentContainer>

            <div id="modal-selector-usuario" className="modal">
                <div className="modal-content">
                    {/* <ScreensUserSelector
                        onSelect={ handleSelectUser }
                        fetchUserList={ fetchCustomerUserList }
                    /> */}
                </div>
            </div>

            <div id="modal-nuevo-usuario" className="modal">
                <div className="modal-content">
                    {/* <ScreensUserNew
                        onSelect={ handleNewUser }
                        role="ROLE_ALUMNO"
                        hideEmail
                    /> */}
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );
};

const validate = (values) => {
    const errors = {};

    if (!values.nombre) {
        errors.nombre = i18n.t("validator-error-nombre");
    }

    if (!values.apellidos) {
        errors.apellidos = i18n.t("validator-error-apellidos");
    }

    if (!values.email) {
        errors.email = i18n.t("validator-error-email");
    }

    if (!values.demarcacion_id) {
        errors.demarcacion_id = i18n.t("validator-error-demarcacion");
    }

    return errors;
};

export default reduxForm({
    form: "customerForm",
    validate: validate,
    destroyOnUnmount: false, //true
})(ScreensCustomerForm);
