import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';

import { 
    renderFieldOneColumn,
    renderFieldTwoColumns 
} from '../../components/UI/Fileds';

import { sendDocument } from '../../actions/document';
import { startLoading, endLoading } from '../../actions/ui';

import { showError, showConfirmation } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const ScreensDocumentForm = ({ submitting, handleSubmit, dirty }) => {

    const fileInput = React.createRef();
    
    const { t } = useTranslation();

    const { selected } = useSelector(state => state.document);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        if (selected) {
            dispatch(initialize('documentForm', { id: selected.id, descripcion: selected.descripcion }));
        } else {
            dispatch(initialize('documentForm', { descripcion: '' }));
        }
    }, [dispatch, selected]);

    const handleSend = (values) => {
        const archivo = fileInput.current.files[0];

        if (!selected && !archivo) {
            showError(t('message-title-error'), t('message-error-no-file'));
        } else {
            dispatch(startLoading());

            dispatch(
                sendDocument({ 
                    ...values, 
                    nombre_original: archivo,
                    descripcion: stringTrim(values.descripcion)
                })
            ).then((res) => {
                dispatch(endLoading());

                if (res.ok) {
                    history.goBack();
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });
        }
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden" 
                                    name="id" 
                                    id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <div className={`input-field file-field col s12 m6`}>
                        <div className="btn">
                            <span>{ t('screens-document-list-nombre-original') }</span>
                            <input name="nombre_local" type="file" key="nombre_local" id="nombre_local" ref={ fileInput } />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" />
                        </div>
                        <small className="errorTxt6">
                            <div id="crole-error" className="error">
                            </div>
                        </small>      
                    </div>

                    <Field type="text" name="descripcion" id="descripcion" 
                        label={ t('screens-document-list-descripcion') } 
                        placeholder=""
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="descripcion"
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-document-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.descripcion) {
        errors.descripcion = i18n.t('validator-error-descripcion');
    }

    return errors;
};

export default reduxForm({
    form: 'documentForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensDocumentForm);