import React from 'react';
import { useSelector } from 'react-redux';

import rbac_rules from './RBACRules';

export const checkRBAC = (user, rules, action, data) => {
    const roles = user.roles.map(role => role.code.toLowerCase());

    for (let i = 0; i < roles.length; i++) {
        const role = roles[i];
        
        const permissions = rules[role];
        if (!permissions) {
            continue;
        }

        const staticPermissions = permissions.static;
        if (staticPermissions && staticPermissions.includes(action)) {
            return true;
        }

        const dynamicPermissions = permissions.dynamic;
        if (dynamicPermissions) {
            const permissionCondition = dynamicPermissions[action];
            if (!permissionCondition) {
                continue;
            }
            if (permissionCondition(user, data)) {
                return true;
            } else {
                continue;
            }
        }
    }

    return false;
};

const Can = (props) => {
    const { user } = useSelector(state => state.auth);

    return checkRBAC(user, rbac_rules, props.perform, props.data) 
        ? props.yes() 
        : props.no();
};

Can.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Can;
