import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../components/UI/FormButton';
import { 
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns 
} from '../../components/UI/Fileds';

import { 
    fetchBookSectionList
} from '../../actions/ui';

import { setBookFilter } from '../../actions/book';

const BookSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { filter } = useSelector(state => state.book);

    const { bookSectionList } = useSelector(state => state.ui);

    useEffect(() => {

        const collapsibles = document.querySelectorAll('.collapsible');
        M.Collapsible.init(collapsibles, {});

        dispatch(fetchBookSectionList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });
        
        dispatch(initialize('bookSearchForm', filter));

    }, [dispatch, filter]);
    
    const handleReset = () => {
        dispatch(setBookFilter({}));
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(setBookFilter(values));
    }

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>

                {/* Campos del formulario */}
                <div className="row margin">
                    <Field type="text" name="autor" id="autor" 
                        label={ t('screens-book-list-autor') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="autor" 
                    />

                    <Field type="text" name="titulo" id="titulo" 
                        label={ t('screens-book-list-titulo') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="titulo" 
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="editorial" id="editorial" 
                        label={ t('screens-book-list-editorial') } 
                        component={ renderFieldTwoColumns } 
                        icon="business" 
                        key="editorial" 
                    />
                    <Field name="biblioteca_seccion_id" id="biblioteca_seccion_id" 
                        label={ t('screens-book-list-seccion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="seccion" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            bookSectionList.map(section => (
                                <option value={ section.id } key={ section.id }>{ section.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> { t('filter-title') }
                </div>
                <div className="collapsible-body">
                    { renderForm() }
                </div>
            </li>
            
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'bookSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(BookSearchForm);