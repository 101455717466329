import React from "react";
import {
  Grid,
  Tooltip,
  Card,
  CardContent,
  Typography,
  Icon,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { has } from "lodash";

const colorClasses = [
  "gradient-45deg-red-pink", // no planificado
  //"gradient-45deg-green-teal",
  //"gradient-45deg-light-blue-cyan",  
  "gradient-45deg-brown-brown", //borrador
  "gradient-45deg-amber-amber", // suspendido
  "gradient-45deg-purple-deep-orange", // SIN FINALIZAR VENCIDOS

];

const DynamicTile = ({
  icon,
  title,
  value,
  newLabel = "Deals",
  estado,
  total,
  index,
  onClick,
}) => {
  const colorClass = colorClasses[index % colorClasses.length];
  let formattedTitle = title;
  if (title === "sin_planificar") {
    formattedTitle = "Sin planificar";
  } else {
    formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);
  }
  const handleClick = () => {
    onClick();
  };

  return (
    <div className="col s12 m6 l6 xl3">
      <div
        className={`card ${colorClass} gradient-shadow min-height-100 white-text animate fadeLeft ${estado}`}
        onClick={handleClick}
      >
        <div className="padding-4">
          <div className="row">
            <div className="col s7 m7">
              <i className="material-icons background-round mt-5">{icon}</i>
              <p style={{ fontSize: "1em" }}>
                <strong>{formattedTitle}</strong>
              </p>
            </div>
            <div className="col s5 m5 right-align">
              <h5 className="mb-0 white-text">{value}</h5>
              <p className="no-margin">{newLabel}</p>
              <p>{total}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const KPITiles = ({ kpis = [] }) => {
  const filteredKpis = kpis.filter(
    //(kpi) => kpi.estado !== "cancelado" && kpi.estado !== "finalizado"
    (kpi) => kpi.show
  );

  return (
    <div className="container">
      {filteredKpis.map((kpi, index) => (
        <DynamicTile
          key={kpi.title}
          title={kpi.title} // ESTADO DE KPI
          value={kpi.value} // CANTIAD DE KPI
          description={kpi.description}
          icon={kpi.icon}
          estado={kpi.estado}
          index={index}
          onClick={kpi.onClick}
          show={kpi.show}
        />
      ))}
    </div>
  );
};

export default KPITiles;
