// React and third-party libraries
import React, { useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formValueSelector } from "redux-form";
import { useHistory } from "react-router-dom";

// Actions
import {
  fetchCourses,
  downloadExcel,
  selectCourse,
  setCourseFilter,
  deleteGroup,
} from "../../actions/course";
import { startLoading, endLoading } from "../../actions/ui";

// Components
import Can from "../../routes/Can";
import CourseSearchForm from "./SearchForm";
import PageTitle from "../../components/UI/PageTitle";
import PageContainer from "../../components/UI/PageContainer";
import ContentContainer from "../../components/UI/ContentContainer";
import Pagination from "../../components/UI/Pagination";
import ExcelButton from "../../components/UI/ExcelButton";
import Spacer from "../../components/UI/Spacer";
import ActionButton from "../../components/UI/ActionButton";
import SortingHeader from "../../components/UI/SortingHeader";
import FloatingButtonAction from "../../components/UI/FloatingButtonAction";

import { Typography, Chip } from "@material-ui/core";
import moment from "moment";

import PdfButton from '../../components/UI/PdfButton';
import CustomKPI from "../Planner/components/CustomKPI";
import { InfoCircleOutlined } from '@ant-design/icons';

// Helpers
import {
  showError,
  showConfirmation,
  showSuccess,
} from "../../helpers/messages";
import { set } from "date-fns";

const ScreensCourseList = ({ titulo, cursos_tipo_id, programados }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { list, paging, filter } = useSelector((state) => state.course);

  const { sort = "p_update_time", direction = "desc" } = paging;
  const history = useHistory();

  const sortByDealAndRemoveDuplicates = (a, b) => {
    //return  a.deal_id_pipedrive - b.deal_id_pipedrive;
    return '';
  };

  const sortedAndUniqueList = [...list]
    .sort(sortByDealAndRemoveDuplicates)
    .reduce((acc, current) => {
      const x = acc.find(
        (item) => item.deal_id_pipedrive === current.deal_id_pipedrive
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

  const sortedList = [...sortedAndUniqueList].sort((a, b) => {
    // if (sort === "titulo") {
    //   return a.titulo.localeCompare(b.titulo) * (direction === "desc" ? 1 : -1);
    // }
    // if (sort === "fecha_inicio") {
    //   return (
    //     new Date(a.fecha_inicio) -
    //     new Date(b.fecha_inicio) * (direction === "desc" ? 1 : -1)
    //   );
    // }
    return a[sort] - b[sort] * (direction === "desc" ? 1 : -1);
  });

  const fetchData = useCallback(
    (options) => {
      dispatch(startLoading());
      dispatch(fetchCourses(options)).then((res) => {
        dispatch(endLoading());
        if (!res.ok) {
          showError(t("message-title-error"), t(res.message));
        }
      });
    },
    [dispatch, t]
  );

  //set course filter group eq 1
  filter.group = 1;
  useEffect(() => {
    fetchData({ ...paging, ...filter });
  }, [dispatch, filter, fetchData]);

  useEffect(() => {
    dispatch(selectCourse(null));
  }, [dispatch]);

  const handlePaginate = (index) => {
    fetchData({ ...paging, group: 1, page: index });
  };

  const handleSort = (name) => {
    let newDirection = "desc";
    if (sort === name) {
      newDirection = direction === "asc" ? "desc" : "asc";
    }

    fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
  };

  const handleEdit = (item) => {
    dispatch(selectCourse(item));
    history.push("/cursos/formulario");
  };
  const handlePlan = (item) => {
    dispatch(selectCourse(item));
    history.push("/planner/formgroup");
  };

  const handleDeleteDeal = (id, name) => {
    showConfirmation(
      t("message-title-confirmation"),
      t("message-delete-deal-text", { name }),
      t("button-title-delete"),
      t("button-title-cancel"),
      () => {
       const deleteKey = [
          { key: 'deal', value: id },
        ];
        dispatch(startLoading());
        dispatch(deleteGroup(deleteKey)).then((res) => {
          dispatch(endLoading());
          if (res.ok) {
            showSuccess(t("message-title-success"), t(res.message));
            fetchData({ ...paging, ...filter, page: 1 });
          } else {
            showError(t("message-title-error"), t(res.message));
          }
        });
      }
    );
  };

  const handleExcel = () => {
    dispatch(startLoading());
    dispatch(
      downloadExcel({
        titulo: titulo,
        cursos_tipo_id: cursos_tipo_id,
        programados: programados,
      })
    ).then((res) => {
      dispatch(endLoading());
      if (!res.ok) {
        showError(t("message-title-error"), t(res.message));
      }
    });
  };



  const handleAdd = () => {
    dispatch(selectCourse(null));
    history.push("/cursos/formulario");
  };

  const handleView = (item) => {
    dispatch(selectCourse(item));
    history.push("/cursos/detalle");
  };

  const groupedItems = sortedList.reduce((grouped, item) => {
    const key = item.deal_id_pipedrive;
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(item);
    return grouped;
  }, {});

  const [expandedGroups, setExpandedGroups] = React.useState({});

  const toggleGroup = (deal_id_pipedrive) => {
    setExpandedGroups({
      ...expandedGroups,
      [deal_id_pipedrive]: !expandedGroups[deal_id_pipedrive],
    });
  };

  function getContactName(item) {
    try {
      const parsed = JSON.parse(item.p_persona_contacto);
      return parsed.name + " (" + parsed.email[0].value + ")" || "";
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  }

  const filterLabels = {
    group: "Filtro",
    deal_id_pipedrive: "Deal ID",
    gep_tipo_formacion: "Tipo de formación",
    estados: "Estados",
    customer_name_pipedrive: "Cliente",
    // Add more mappings as needed
  };

  const filterValueLabels = {
    group: {
      1: "Sin Planificar",
      // Add more mappings for other group values as needed
    },
    estados: {
      0: "Sin Planificar",
      1: "Planificado",
      2: "Borrador",
      3: "Suspendido",
      4: "Cancelado",
      5: "Finalizado",
    },
  };

  

  const hiddenFilterGroups = ["group"];

  const renderTable = () => {
    const filterEntries = Object.entries(filter).filter(
      ([key, value]) => value !== undefined && value !== ""
    );

    const filterChips =
      filterEntries.length > 0
        ? filterEntries
          .filter(([key]) => !hiddenFilterGroups.includes(key)) // filter out the hidden filter groups
          .map(([key, value]) => {
            // Use the mapping object to get the user-friendly text for the value
            const valueLabel = filterValueLabels[key]
              ? filterValueLabels[key][value]
              : value;
            // Use the filterLabels object to get the label for the key
            const keyLabel = filterLabels[key] || key;
            return (
              <Chip
                key={key}
                label={`${keyLabel}: ${valueLabel}`} // Display the label and the mapped value
                style={{ margin: "4px" }}
              />
            );
          })
        : t("no-filters-applied");

    return (
      <div>
        <Typography variant="h4" component="h3" gutterBottom>
          {t("Negocios ganados")}
        </Typography>
        <div
          style={{ display: "flex", flexWrap: "wrap", marginBottom: "16px" }}
        >
          {filterChips}
        </div>
        <table className="dataTable striped highlight responsive-table" style={{ fontSize: "12px" }}>
          <thead>
            <tr>
              <SortingHeader
                title={t("Deal")}
                direction={sort === "deal_id_pipedrive" ? direction : "desc"}
                onClick={() => handleSort("deal_id_pipedrive")}
              />

              <SortingHeader
                title={t("screens-course-list-titulo")}
                direction={sort === "titulo" ? direction : ""}
                onClick={() => handleSort("titulo")}
              />
              <th>{t("screens-course-list-customer_name")}</th>
              <SortingHeader
                title={t("Sede")}
                direction={sort === "p_sede_formacion" ? direction : ""}
                onClick={() => handleSort("p_sede_formacion")}
              />

              {/* <th>{t("Persona de contacto")}</th> */}
              <th>{t("screens-course-list-gep_tipo_formacion")}</th>
              <th>{t("Comercial")}</th>

              <SortingHeader
                title={t("Ultima actualización")}
                direction={sort === "p_update_time" ? direction : ""}
                onClick={() => handleSort("p_update_time")}
              />
              <SortingHeader
                title={t("Fecha Ganado")}
                direction={sort === "p_won_time" ? direction : ""}
                onClick={() => handleSort("p_won_time")}
              />
              <SortingHeader
                title={t("Precio")}
                direction={sort === "precio" ? direction : ""}
                onClick={() => handleSort("precio")}
              />
              <th>{t("screens-list-acciones")}</th>
            </tr>
          </thead>
          <tbody>
            {sortedList.map((item) => (
              <tr
                style={item.planificado ? {} : { color: "rgba(198, 40, 40)" }}
                key={item.id}
              >
                <td>
                  <a
                    href={item.url_deal_pipedrive}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.deal_id_pipedrive}
                  </a>
                </td>
                <td>
                  <a
                    href={item.url_deal_pipedrive}
                    target="_blank"
                    rel="noopener noreferrer"
                  >

                    {item.p_webhook_notification !== null ? <InfoCircleOutlined title = "Recientemente se ha actualizado PIPEDRIVE. Por favor, verifica si es necesario." style={{ fontSize: '24px', color: "red" }} />  : null}
                    {item.p_titulo_deal}
                  </a>
                </td>
                <td>
                  <a
                    style={{ color: "rgba(198, 40, 40)" }}
                    href={item.url_customer_pipedrive}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.customer_name_pipedrive}
                  </a>
                </td>
                {/* <td>{getContactName(item)}</td> */}
                <td>{item.p_sede_formacion}</td>
                <td>{item.p_categoria_formacion}</td>
                <td>{item.p_owner_name}</td>
                <td>
                  {moment(item.p_update_time).format("DD-MM-YYYY")}
                </td>
                <td>
                  {moment(item.p_won_time).format("DD-MM-YYYY")}
                </td>
                <td>{item.p_precio_total_deal} €</td>
                <td>
                  {/* <Can
                  perform="Cursos:edit"
                  yes={() => (
                    <ActionButton
                      icon="edit"
                      tooltip={t("screens-list-acciones-edit")}
                      extraClass="btn-floating btn-small waves-effect waves-light green darken-3"
                      onClick={() => handleEdit(item)}
                    />
                  )}
                  data={item}
                /> */}
                  <Can
                    perform="Planner:add"
                    yes={() => (
                      <ActionButton
                        icon="list"
                        tooltip={t("screens-list-acciones-group")}
                        extraClass="btn-floating btn-small waves-effect waves-light blue darken-3"
                        onClick={() => handlePlan(item)}
                      />
                    )}
                    data={item}
                  />
                  {/* {
                    (item.tiene_documento)
                      ? <Can perform="Planner:add" yes={() => (
                        <PdfButton
                          onPressButton={() => handlePlan(item)} />
                      )} />
                      : ''
                  } */}
                  <Can perform="Planner:delete"
                    yes={() => (
                      <ActionButton icon="delete_forever"
                        tooltip={t('screens-list-acciones-delete')}
                        extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-3"
                        onClick={() => handleDeleteDeal(item.deal_id_pipedrive, `${item.deal_id_pipedrive + ' - ' + item.p_titulo_deal}`)}
                      />
                    )}
                    data={item}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <PageContainer>
      <CustomKPI />
      <PageTitle title={t("screens-course-list-title")}>
        <Can
          perform="Cursos:excel"
          yes={() => <ExcelButton onPressButton={handleExcel} />}
        />
      </PageTitle>

      <CourseSearchForm />

      <ContentContainer>{renderTable()}</ContentContainer>

      <Pagination paging={paging} onPaginate={handlePaginate} />

      <Spacer height="5" />

      {/* <Can
        perform="Cursos:add"
        yes={() => (
          <FloatingButtonAction
            icon="add"
            title={t("screens-list-acciones-add")}
            action={handleAdd}
          />
        )}
      /> */}
    </PageContainer>
  );
};

const formSelector = formValueSelector("courseSearchForm");

export default connect((state) => ({
  titulo: formSelector(state, "titulo"),
  cursos_tipo_id: formSelector(state, "cursos_tipo_id"),
  programados: formSelector(state, "programados"),
}))(ScreensCourseList);
