import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import ScreensStudentSelectorMultiple from '../Student/SelectorMultiple';
import DatePicker from '../../components/UI/DatePicker';

import { 
    renderFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns,
    renderCheckFieldTwoColumns
} from '../../components/UI/Fileds';

import { sendDiploma } from '../../actions/diploma';
import { startLoading, endLoading, fetchDiplomaTipoList } from '../../actions/ui';
import { fetchStudentList } from '../../actions/student';

import { showError, showConfirmation } from '../../helpers/messages';
import { dateToDatepicker, stringTrim } from '../../helpers/converter';

const ScreensDiplomaForm = ({ submitting, handleSubmit, dirty }) => {
    
    const { t } = useTranslation();

    const { selected } = useSelector(state => state.diploma);

    const { diplomaTipoList } = useSelector(state => state.ui);

    const [students, setStudents] = useState([]);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchDiplomaTipoList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            dispatch(initialize('diplomaForm', { 
                id: selected.id,
                entrada: selected.entrada,
                motivo: selected.motivo,
                ciudad: selected.ciudad,
                fecha: selected.fecha,
                tiene_documento: selected.tiene_documento,
                firma_organizadora: selected.firma_organizadora,
                diplomas_tipo_id: selected.diplomas_tipo_id,
                datepicker_fecha: dateToDatepicker(selected.fecha)
             }));
        } else {
            dispatch(initialize('diplomaForm', { 
                entrada: '', 
                motivo: '', 
                ciudad: '',
                fecha: '',
                datepicker_fecha: '',
                tiene_documento: '',
                firma_organizadora: '',
                diplomas_tipo_id: ''
            }));
        }

        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
    }, [dispatch, selected]);

    const handleSend = (values) => {
        dispatch(startLoading());

        const tipo = diplomaTipoList.find((type, index) => {
            return values.diplomas_tipo_id === type.id;
        });
        let entrada = values.entrada;
        let motivo = values.motivo;
        if (tipo.nombre === 'Experto 200 h.' || tipo.nombre === 'Maestría 340 h.') {
            entrada = 'empty';
            motivo = 'empty';
        } else {
            if (!entrada) {
                showError(t('message-title-error'), t('validator-error-entrada'));
                return;
            } else if (!motivo) {
                showError(t('message-title-error'), t('validator-error-motivo'));
                return;
            }
        }

        if (students.length === 0) {
            showError(t('message-title-error'), t('validator-error-alumnos'));
            return;
        }

        dispatch(
            sendDiploma({
                Diplomas: {
                    ...values,
                    alumnos_seleccionados: students,
                    entrada: stringTrim(entrada),
                    motivo: stringTrim(motivo),
                    ciudad: stringTrim(values.ciudad),
                    firma_organizadora: (values.firma_organizadora) ? 1 : 0,
                    tiene_documento: (values.tiene_documento) ? 1 : 0,
                }
            })
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const handleSearchStudent = () => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectStudent = (studentsSelected) => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setStudents(studentsSelected);
    };

    const handleTipoChange = (event) => {
        if (event.target.value) {
            const tipo = diplomaTipoList.find((type, index) => {
                return event.target.value === type.id;
            });
            if (tipo.nombre === 'Experto 200 h.' || tipo.nombre === 'Maestría 340 h.') {
                document.getElementById('input-block-entrada').classList.add('hide');
                document.getElementById('input-block-motivo').classList.add('hide');
            } else {
                document.getElementById('input-block-entrada').classList.remove('hide');
                document.getElementById('input-block-motivo').classList.remove('hide');
            }
        } else { 
            document.getElementById('input-block-entrada').classList.remove('hide');
            document.getElementById('input-block-motivo').classList.remove('hide');
        }
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden" 
                                    name="id" 
                                    id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <Field type="text" name="entrada" id="entrada" 
                        label={ t('screens-diploma-list-entrada') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="diploma"
                        visible={ true }
                    />

                    <Field type="text" name="motivo" id="motivo" 
                        label={ t('screens-diploma-list-motivo') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="motivo" 
                        visible={ true } 
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="ciudad" id="ciudad" 
                        label={ t('screens-diploma-list-ciudad') } 
                        component={ renderFieldTwoColumns } 
                        icon="location" 
                        key="ciudad" 
                    />
                    <Field name="diplomas_tipo_id" id="diplomas_tipo_id" 
                        label={ t('screens-diploma-list-tipo') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="diplomas_tipo_id"
                        onChange={ handleTipoChange } 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            diplomaTipoList.map(tipo => (
                                <option value={ tipo.id } key={ tipo.id }>{ tipo.nombre }</option>
                            ))
                        }
                    </Field>
                </div>
                <div className="row margin">
                    <DatePicker form="diplomaForm" name="fecha" 
                        value={(selected) ? selected.fecha : ''}
                        label={ t('screens-diploma-list-fecha') }
                        render={ renderFieldTwoColumns }
                    />

                    <Field type="checkbox" name="tiene_documento" id="tiene_documento" 
                        className="filled-in"
                        label={ t('screens-diploma-list-documento') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="tiene_documento"
                    />

                    <Field type="checkbox" name="firma_organizadora" id="firma_organizadora" 
                        className="filled-in"
                        label={ t('screens-diploma-list-firma') } 
                        component={ renderCheckFieldTwoColumns } 
                        icon="check"
                        key="firma_organizadora"
                    />
                </div>

                <div className="row margin">
                    <label className="ml-1">{t('screens-student-selector-alumnos')}</label>
                    <p>
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-alumno"
                            onClick={ handleSearchStudent }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ t('selector-students-no-selected') }</span>
                    </p>
                </div>
                <div className="row margin">
                    <table className="ml-1 mt-2 mb-2">
                        <thead>
                            <tr>{ t('screens-student-selector-alumno') }</tr>
                        </thead>
                        <tbody>
                            {
                                students.map((item) => (
                                    <tr key={ item.id }>
                                        <td>{ item.label }</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />
                        
                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-diploma-form-title') }>
                <blockquote className="small red-text">
                    { t("Si selecciona un título como 'Experto 200 h.' o 'Maestría 340 h.' no podrá editar los campos 'Entrada' y 'Motivo'.") }
                </blockquote>
            </PageTitle>

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <div id="modal-selector-alumno" className="modal">
                <div className="modal-content">
                    <ScreensStudentSelectorMultiple
                        onSelect={ handleSelectStudent }
                        fetchStudentList={ fetchStudentList }
                    />
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.fecha) {
        errors.datepicker_fecha = i18n.t('validator-error-fecha');
    }

    if (!values.ciudad) {
        errors.ciudad = i18n.t('validator-error-ciudad');
    }

    if (!values.diplomas_tipo_id) {
        errors.diplomas_tipo_id = i18n.t('validator-error-tipo');
    }

    return errors;
};

export default reduxForm({
    form: 'diplomaForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensDiplomaForm);