import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchOrganizers = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/organizadores', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setOrganizers(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setOrganizers = (data) => ({
   type: TYPES.SET_ORGANIZERS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile('/organizadores/excel', 'organizadores.xlsx', filter, {
                nombre: 'Organizadores',
                apellidos: 'Organizadores',
                demarcacion_id: 'Organizadores'
            });
            const blob = await res.blob();
            fileDownload(blob, 'organizadores.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectOrganizer = (data) => ({
    type: TYPES.SELECT_ORGANIZER, payload: data
});

export const setOrganizerFilter = (filter) => ({
    type: TYPES.SET_ORGANIZER_FILTER, payload: filter
});

export const deleteOrganizer = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/organizadores/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendOrganizer = (organizer) => {
    return async () => {
        try {
            let res;

            if (organizer.Organizadores.id) {
                res = await apiClientWithToken().put(
                    `/organizadores/${organizer.Organizadores.id}`,
                    organizer
                );
            } else {
                res = await apiClientWithToken().post(
                    '/organizadores', organizer
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};