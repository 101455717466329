import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { initialize, reduxForm, Field } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import M from "materialize-css";

import FormButton from "../../components/UI/FormButton";
import {
  renderFieldTwoColumns,
  renderSelectFieldTwoColumns,
  renderCheckFieldTwoColumns,
  renderAutosuggestFieldTwoColumns,
} from "../../components/UI/Fileds";

import {
  fetchDemarcacionList,
  fetchFormadorList,
  fetchCustomerList,
} from "../../actions/ui";

import { setCourseFilter } from "../../actions/course";
import { isValid } from "date-fns";
import useSuggestions from "../../hooks/common/useSuggestions";

const CourseSearchForm = ({ submitting, handleSubmit }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { filter } = useSelector((state) => state.course);
  const { customerList } = useSelector((state) => state.ui);

  const renderSuggestion = (suggestion) => <div>{suggestion.razon_social}</div>;

  const filterFunc = (customer, inputValue, inputLength) =>
    customer.razon_social.toLowerCase().slice(0, inputLength) === inputValue;

  const {
    suggestions,
    getSuggestionValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    inputProps,
  } = useSuggestions(customerList, filterFunc, "razon_social");

  const handleSelectChange = (selectedOption) => {
    // handle selected value
  };
  // a pdedio de juan
  const normalizeCheckbox = (value) => (value ? 1 : 0);

  // Array local con los tipos de curso
  const courseTypeList = [
    { id: "FORMACION ABIERTA", nombre: "Formación Abierta" },
    { id: "FORMACION EMPRESA", nombre: "Formación Empresas" },
    { id: "GEP SERVICES", nombre: "Gep Services" },
    // Añade más tipos de curso según sea necesario
  ];

  useEffect(() => {
    const collapsibles = document.querySelectorAll(".collapsible");
    M.Collapsible.init(collapsibles, {});

    // Simula una respuesta exitosa para courseTypeList
    const resCourseTypeList = {
      ok: true,
      data: courseTypeList,
    };

    if (resCourseTypeList.ok) {
      const selects = document.querySelectorAll("select");
      M.FormSelect.init(selects, {});
    }
    dispatch(fetchFormadorList()).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });

    dispatch(fetchDemarcacionList()).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });

    dispatch(fetchCustomerList()).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });

    dispatch(initialize("courseSearchForm", filter));
  }, [dispatch, filter]);

  const handleReset = () => {
    dispatch(setCourseFilter({}));
  };

  const handleSearch = (values) => {
    const collapsibles = document.getElementsByClassName(
      "collapsible-search-form"
    );
    const instance = M.Collapsible.getInstance(collapsibles[0]);
    instance.close();

    dispatch(setCourseFilter(values));
  };

  const renderForm = () => {
    return (
      <form className="search-form" onSubmit={handleSubmit(handleSearch)}>
        <div className="row margin">
          <Field
            type="text"
            name="deal_id_pipedrive"
            id="deal_id_pipedrive"
            label={t("screens-course-list-deal")}
            component={renderFieldTwoColumns}
            icon="text_fields"
            key="deal_id_pipedrive"
          />
          <Field
            type="text"
            name="titulo"
            id="titulo"
            label={t("screens-course-list-titulo")}
            component={renderFieldTwoColumns}
            icon="text_fields"
            key="titulo"
          />
        </div>

        <div className="row margin">
        {/* <Field
            type="text"
            name="customer_name_pipedrive"
            id="customer"
            label={t("screens-course-customer")}
            component={renderFieldTwoColumns}
            icon="person"
            key="customer_name_pipedrive"
          /> */}
          {/* <Field
            type="text"
            name="customer_name_pipedrive"
            key="customer_name_pipedrive"
            id="customer"
            label={t("screens-course-customer")}
            icon="person"            
            component={renderAutosuggestFieldTwoColumns}
            suggestions={suggestions}
            getSuggestionValue={getSuggestionValue}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            inputProps={inputProps}
            renderSuggestion={renderSuggestion}
            style={{ marginLeft: "40px" }}
          /> */}

          <Field
            name="gep_tipo_formacion"
            id="gep_tipo_formacion"
            type="select-multiple"
            label={t("screens-course-list-gep_tipo_formacion")}
            component={renderSelectFieldTwoColumns}
            icon="bookmark"
            key="gep_tipo_formacion"
            multiple={true}
          >
            <option value="" disabled>
              {t("form-select-choose-one")}
            </option>
            {courseTypeList.map((courseType) => (
              <option value={courseType.id} key={courseType.id}>
                {courseType.nombre}
              </option>
            ))}
          </Field>

          {/* <Field
            name="fecha_inicio"
            id="fecha_inicio"
            type="date"
            label={t("screens-course-start-date")}
            component={renderFieldTwoColumns}
            icon="date_range"
            key="fecha_inicio"
          /> */}
        </div>

        {/* <div>
          <label htmlFor="customer">{t("screens-course-customer")}</label>
          <Autosuggest
            id="customer"
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            component={renderFieldTwoColumns}
          />
        </div> */}
        <div className="row margin">
          {/* <Field
            type="text"
            name="ciudad"
            id="ciudad"
            label={t("screens-student-degree-list-poblacion")}
            component={renderFieldTwoColumns}
            icon="location_city"
            key="ciudad"
          /> */}

        </div>

        {/*<div
          className="row margin"
          //   title={t("screens-course-list-planificados-tooltip")}
        >
           <Field
            type="checkbox"
            name="planificado"
            id="planificado"
            className="filled-in"
            label={t("screens-course-list-planificados")}
            component={renderCheckFieldTwoColumns}
            key="planificado"
            normalize={normalizeCheckbox}
          />

          <Field
            type="checkbox"
            name="programados"
            id="programados"
            className="filled-in"
            label={t("screens-course-list-programados")}
            component={renderCheckFieldTwoColumns}
            key="programados"
            normalize={normalizeCheckbox}
            defaultChecked={true}
          />
        </div> */}

        <div className="row">
          <div className="input-field col s12">
            <FormButton
              type="submit"
              title={t("search-form-button-title")}
              disabled={submitting}
            />

            <FormButton
              type="reset"
              title={t("reset-form-button-title")}
              disabled={submitting}
              onClick={handleReset}
            />
          </div>
        </div>
      </form>
    );
  };

  return (
    <ul className="collapsible popout collapsible-search-form">
      <li>
        <div className="collapsible-header">
          <i className="material-icons">tune</i> {t("filter-title")}
        </div>
        <div className="collapsible-body">{renderForm()}</div>
      </li>
    </ul>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.fecha_inicio) {
    errors.fecha_inicio = "Required";
  } else if (!isValid(new Date(values.fecha_inicio))) {
    errors.fecha_inicio = "Invalid date";
  }
  return errors;
};

export default reduxForm({
  form: "courseSearchForm",
  // validate: validate,
  destroyOnUnmount: false,
})(CourseSearchForm);
