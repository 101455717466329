import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchStudentTasks = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/tareas-alumnos', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudentTasks(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentTasks = (data) => ({
   type: TYPES.SET_STUDENT_TASKS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/tareas-alumnos/excel', 
                'tareas-alumnos.xlsx',
                filter, {
                    'nombre': 'Alumnos',
                    'apellidos': 'Alumnos',
                    'titulo': 'Tareas',
                    'tareas_estado_id' :'TareasAlumnos'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'tareas-alumnos.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const setStudentTaskFilter = (filter) => ({
    type: TYPES.SET_STUDENT_TASK_FILTER, payload: filter
});

export const deleteStudentTask = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/tareas-alumnos/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendStudentTask = (studentTask) => {
    return async () => {
        try {
            const res = await apiClientWithToken().post(
                '/tareas-alumnos', studentTask
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectStudentTask = (data) => ({
    type: TYPES.SELECT_STUDENT_TASK, payload: data
});

export const approveTask = (task) => {
    return async () => {
        try {
            const res = await apiClientWithToken().post(
                `/tareas-alumnos/aprobar/${task.id}`, {}
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};