import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { initialize, reduxForm, Field } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import M from "materialize-css";

import FormButton from "../../../components/UI/FormButton";
import {
  renderFieldTwoColumns,
  renderFieldOneColumn,
  renderSelectFieldTwoColumns,
  renderSelectFieldOneColumn,
} from "../../../components/UI/Fileds";

import { setCourseDocumentFilter } from "../../../actions/courseDocument";

const DocumentCourseSearchForm = ({ submitting, handleSubmit }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { filter } = useSelector((state) => state.courseDocument);

  useEffect(() => {
    const elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems, {});

    dispatch(initialize("courseDocumentSearchForm", filter));
  }, [dispatch, filter]);

  const handleReset = () => {
    dispatch(setCourseDocumentFilter({}));
  };

  const handleSearch = (values) => {
    const collapsibles = document.getElementsByClassName(
      "collapsible-search-form"
    );
    const instance = M.Collapsible.getInstance(collapsibles[0]);
    instance.close();

    dispatch(setCourseDocumentFilter(values));
  };

  // Array local con los tipos de curso
  const courseTypeList = [
    { id: "FORMACION ABIERTA", nombre: "Formación Abierta" },
    { id: "FORMACION EMPRESAS", nombre: "Formación Empresas" },
    { id: "GEP SERVICES", nombre: "Gep Services" },
    // Añade más tipos de curso según sea necesario
  ];

  const statusList = [
    { id: "0", nombre: "Sin Planificar", color: "#6F9FFE" },
    { id: "1", nombre: "Planificado", color: "#87FD7E" },
    { id: "2", nombre: "Borrador", color: "#D1D3D1" },
    { id: "3", nombre: "Suspendido", color: "#FE7E6F" },
    { id: "4", nombre: "Cancelado", color: "#6F9FFE" },
    { id: "5", nombre: "Finalizado", color: "#FEF962" },
  ];
  const renderForm = () => {
    return (
      <form className="search-form" onSubmit={handleSubmit(handleSearch)}>
        <div className="row margin">
          <Field
            type="text"
            name="titulo"
            id="titulo"
            label={t("screens-course-document-list-titulo")}
            component={renderFieldTwoColumns}
            icon="event"
            key="titulo"
          />
          <Field
            type="text"
            name="nombre_original"
            id="nombre_original"
            label={t("screens-course-document-list-archivo")}
            component={renderFieldTwoColumns}
            icon="attach_file"
            key="nombre_original"
          />
        </div>

        <div className="row margin">
          <Field
            type="text"
            name="deal_id_pipedrive"
            id="deal_id_pipedrive"
            label={t("screens-course-document-list-pipe_drive_deal")}
            component={renderFieldTwoColumns}
            icon="text_fields"
            key="deal_id_pipedrive"
          />
          <Field
            type="text"
            name="codigo_grupo_alumnos"
            id="codigo_grupo_alumnos"
            label={t("screens-course-document-list-group")}
            component={renderFieldTwoColumns}
            icon="group"
            key="codigo_grupo_alumnos"
          />
        </div>

        <div className="row margin">
          {/* <Field
            name="gep_tipo_formacion"
            id="gep_tipo_formacion"
            type="select-multiple"
            label={t("screens-course-list-gep_tipo_formacion")}
            component={renderSelectFieldTwoColumns}
            icon="bookmark"
            key="gep_tipo_formacion"
            multiple={true}
          >
            <option value="" disabled>
              {t("form-select-choose-one")}
            </option>
            {courseTypeList.map((courseType) => (
              <option value={courseType.id} key={courseType.id}>
                {courseType.nombre}
              </option>
            ))}
          </Field> */}

          {/* <Field
            name="estados"
            id="estados"
            type="select"
            label={t("screens-calendar-select-state")}
            component={renderSelectFieldTwoColumns}
            icon="bookmark"
            key="estados"
            multiple={false}
          >
            <option value="" disabled>
              {t("form-select-choose-one")}
            </option>
            {statusList.map((status) => (
              <option value={status.id} key={status.id}>
                {status.nombre}
              </option>
            ))}
          </Field> */}
          {/* <Field
            type="text"
            name="codigo_grupo_alumnos"
            id="codigo_grupo_alumnos"
            label={t("screens-course-document-list-group")}
            component={renderFieldTwoColumns}
            icon="group"
            key="codigo_grupo_alumnos"
          /> */}
        </div>

        <div className="row margin">
          <Field
            type="text"
            name="descripcion"
            id="descripcion"
            label={t("screens-course-document-list-descripcion")}
            component={renderFieldOneColumn}
            icon="text_fields"
            key="descripcion"
          />
        </div>

        <div className="row">
          <div className="input-field col s12">
            <FormButton
              type="submit"
              title={t("search-form-button-title")}
              disabled={submitting}
            />

            <FormButton
              type="reset"
              title={t("reset-form-button-title")}
              disabled={submitting}
              onClick={handleReset}
            />
          </div>
        </div>
      </form>
    );
  };

  return (
    <ul className="collapsible popout collapsible-search-form">
      <li>
        <div className="collapsible-header">
          <i className="material-icons">tune</i> {t("filter-title")}
        </div>
        <div className="collapsible-body">{renderForm()}</div>
      </li>
    </ul>
  );
};

const validate = (values) => ({});

export default reduxForm({
  form: "courseDocumentSearchForm",
  validate: validate,
  destroyOnUnmount: false,
})(DocumentCourseSearchForm);
