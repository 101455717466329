import TYPES from "../actions/types";

const INITIAL_STATE = {
  list: [],
  paging: {},
  filter: {},
  selected: null,
};

export const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_CUSTOMERS:
      const { count, current, page, pageCount, sort, direction } =
        action.payload.paging;
      return {
        ...state,
        list: action.payload.list,
        paging: { count, current, page, pageCount, sort, direction },
      };
    case TYPES.SELECT_CUSTOMER:
      return {
        ...state,
        selected: action.payload,
      };
    case TYPES.SET_CUSTOMER_FILTER:
      return {
        ...state,
        filter: action.payload,
        paging: {},
      };

    default:
      return state;
  }
};
