import React from "react";
import CustomMaterialTable from "../../../components/UI/CustomMaterialTable";

const ProductExtrasView = ({ productExtras }) => {
  // Define table columns
  const columns = [
    { title: "Nombre", field: "name" },
    {
      title: "Precio del Artículo",
      field: "item_price",
      render: row => `${row.item_price} ${row.currency}`
    },
    { title: "Cantidad", field: "quantity" },
    {
      title: "Suma",
      field: "sum",
      render: row => `${row.sum} ${row.currency}`
    },
    { title: "Comentarios", field: "comments" },
    {
      title: "Impuesto",
      field: "tax",
      render: row => `${row.tax} %`
    },
    {
      title: "Descuento",
      field: "discount",
      render: row => `${row.discount} ${row.discount_type === 'percentage' ? '%' : row.currency}`
    },
  ];

  // Parse product extras data
  const parsedData = JSON.parse(productExtras);
  const listProductExtras = JSON.parse(parsedData[0]);

  // Map product extras to table data format
  const data = listProductExtras.map(extra => ({
    name: extra.name || "N/A",
    item_price: (extra.item_price || 0).toFixed(2),
    quantity: (extra.quantity || 0).toFixed(2),
    sum: (extra.sum || 0).toFixed(2),
    comments: extra.comments || "N/A",
    tax: (extra.tax || 0).toFixed(2),
    discount: (extra.discount || 0).toFixed(2),
    discount_type: extra.discount_type || "N/A",
    currency: extra.currency || "N/A",
  }));

  return (
    <CustomMaterialTable
      title="Productos Extras"
      columns={columns}
      data={data}
      options={{
        search: true,
        paging: true,
        filtering: false,
        exportButton: false,
        grouping: false,
      }}
    />
  );
};

export default ProductExtrasView;