import TYPES from "./types";
import { apiClientWithToken, downloadExcelFile } from "../services/backend";
import fileDownload from "js-file-download";

export const fetchCustomers = (filter) => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/customers", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setCustomers(data));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

const setCustomers = (data) => ({
  type: TYPES.SET_CUSTOMERS,
  payload: data,
});

// export const downloadExcel = (filter) => {
//     return async () => {
//         try {

//             const res = await downloadExcelFile('/organizadores/excel', 'organizadores.xlsx', filter, {
//                 nombre: 'Organizadores',
//                 apellidos: 'Organizadores',
//                 demarcacion_id: 'Organizadores'
//             });
//             const blob = await res.blob();
//             fileDownload(blob, 'organizadores.xlsx');

//             return { ok: true };

//         } catch (error) {
//             console.log(error);
//             return { ok: false, message: 'message-server-error' };
//         }
//     };
// };

export const selectCustomer = (data) => ({
  type: TYPES.SELECT_CUSTOMER,
  payload: data,
});

export const setCustomerFilter = (filter) => ({
  type: TYPES.SET_CUSTOMER_FILTER,
  payload: filter,
});

export const deleteCustomer = (id) => {
  return async () => {
    try {
      const res = await apiClientWithToken().delete(`/customers/${id}`);

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const sendCustomer = (customer) => {
  return async () => {
    try {
      let res;

      if (customer.id) {
        res = await apiClientWithToken().put(
          `/customers/${customer.id}`,
          customer
        );
      } else {
        res = await apiClientWithToken().post("/customers", customer);
      }

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};
