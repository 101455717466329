import { useState } from "react";

const useSuggestions = (list, filterFunc, labelProp) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : list.filter((item) => filterFunc(item, inputValue, inputLength));
  };

  const getSuggestionValue = (suggestion) => suggestion[labelProp];

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    value,
    onChange,
  };

  return {
    suggestions,
    getSuggestionValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    inputProps,
  };
};

export default useSuggestions;
