import React from "react";
import Autosuggest from "react-autosuggest";
import ReactQuill from "react-quill";

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  icon,
  id,
  key,
  cols,
  maxLength = 256,
  min = 0,
  className = "",
  visible = true,
  editable = true,
  step = 1,
}) => (
  <div
    className={`input-field col ${cols} ${visible ? "" : "hide"}`}
    id={`input-block-${id}`}
  >
    <i className="material-icons prefix">{icon}</i>
    {editable ? (
      <input
        {...input}
        key={key}
        id={id}
        type={type}
        autoComplete="off"
        maxLength={maxLength}
        className={className}
        min={min}
        step={step}
      />
    ) : (
      <input
        {...input}
        key={key}
        id={id}
        type={type}
        autoComplete="off"
        maxLength={maxLength}
        className={className}
        min={min}
        step={step}
        readOnly
      />
    )}
    <label htmlFor={input.name} className="active">
      {label}
    </label>
    <small className="errorTxt6">
      <div id="crole-error" className="error">
        {touched && error}
      </div>
    </small>
  </div>
);

export const renderSelectField = ({
  children,
  input,
  label,
  meta: { touched, error },
  icon,
  id,
  key,
  cols,
  visible = true,
  multiple,
  className = "",
}) => (
  <div
    className={`input-field col ${cols} ${visible ? "" : "hide"}`}
    id={`input-block-${id}`}
  >
    <i className="material-icons prefix">{icon}</i>
    <select
      {...input}
      key={key}
      id={id}
      autoComplete="off"
      multiple={multiple}
      className={className}
    >
      {children}
    </select>
    <label htmlFor={input.name}>{label}</label>
    <small className="errorTxt6">
      <div id="crole-error" className="error">
        {touched && error}
      </div>
    </small>
  </div>
);

const renderAutocompleteField = ({
  input,
  label,
  type,
  meta: { touched, error },
  icon,
  id,
  key,
  cols,
  maxLength = 9,
  visible = true,
}) => (
  <div
    className={`input-field col ${cols} ${visible ? "" : "hide"}`}
    id={`input-block-${id}`}
  >
    <i className="material-icons prefix">{icon}</i>
    <input
      {...input}
      key={key}
      id={id}
      type={type}
      className="autocomplete"
      maxLength={maxLength}
    />
    <label htmlFor={input.name} className="active">
      {label}
    </label>
    <small className="errorTxt6">
      <div id="crole-error" className="error">
        {touched && error}
      </div>
    </small>
  </div>
);

const renderFileField = ({
  input,
  label,
  type,
  meta: { touched, error },
  icon,
  id,
  key,
  cols,
  visible = true,
}) => (
  /*
    <div class="file-field input-field">
      <div class="btn">
        <span>File</span>
        <input type="file">
      </div>
      <div class="file-path-wrapper">
        <input class="file-path validate" type="text">
      </div>
    </div>
                    */
  /*<div className={`input-field file-field col ${cols}`}>
        <div className="btn">
            <span>{ label }</span>
            <input { ...input } type="file" key={key} id={ id } />
        </div>
        <div className="file-path-wrapper">
            <input className="file-path validate" type="text" />
        </div>
        <small className="errorTxt6">
            <div id="crole-error" className="error">
                {touched && error}
            </div>
        </small>      
    </div>*/
  <div
    className={`input-field file-field col ${cols} ${visible ? "" : "hide"}`}
    id={`input-block-${id}`}
  >
    <div className="btn">
      <span>{label}</span>
      <input name={id} type="file" key={key} id={id} />
    </div>

    <small className="errorTxt6">
      <div id="crole-error" className="error">
        {touched && error}
      </div>
    </small>
  </div>
);

const renderTextarea = ({
  input,
  label,
  type,
  meta: { touched, error },
  icon,
  id,
  key,
  cols,
  maxLength = 512,
  visible = true,
}) => (
  <div
    className={`input-field col ${cols} ${visible ? "" : "hide"}`}
    id={`input-block-${id}`}
  >
    <i className="material-icons prefix">{icon}</i>
    <textarea
      {...input}
      className="materialize-textarea"
      key={key}
      id={id}
      type={type}
      autoComplete="off"
      maxLength={maxLength}
    ></textarea>
    <label htmlFor={input.name} className="active">
      {label}
    </label>
    <small className="errorTxt6">
      <div id="crole-error" className="error">
        {touched && error}
      </div>
    </small>
  </div>
);

const renderCheckField = ({
  input,
  label,
  type,
  meta: { touched, error },
  icon,
  id,
  key,
  cols,
  visible = true,
}) => (
  <div
    className={`input-field col ${cols} ${visible ? "" : "hide"}`}
    id={`input-block-${id}`}
  >
    <label htmlFor={input.name} className="active">
      <input
        {...input}
        key={key}
        id={id}
        type={type}
        className="indeterminate-checkbox"
      />
      <span>{label}</span>
    </label>
    <small className="errorTxt6">
      <div id="crole-error" className="error">
        {touched && error}
      </div>
    </small>
  </div>
);

const renderAutosuggestField = ({
  input,
  label,
  type,
  meta: { touched, error },
  icon,
  id,
  key,
  cols,
  maxLength = 256,
  min = 0,
  className = "",
  visible = true,
  editable = true,
  step = 1,
  style,
  suggestions,
  getSuggestionValue,
  renderSuggestion,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  inputProps,
}) => (
  <div
    className={`input-field col ${cols} ${visible ? "" : "hide"}`}
    id={`input-block-${id}`}
  >
    <i className="material-icons prefix">{icon}</i>
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{
        ...input,
        ...inputProps,
        id: id,
        type: type,
        autoComplete: "off",
        maxLength: maxLength,
        className: className,
        min: min,
        step: step,
        style,
      }}
    />
    <label htmlFor={input.name} className={`active ${className}`}>
      {label}
    </label>
    <small className="errorTxt6">
      <div id="crole-error" className="error">
        {touched && error}
      </div>
    </small>
  </div>
);

export const renderFieldOneColumn = (props) => {
  return renderField({ ...props, cols: "s12" });
};

export const renderFieldTwoColumns = (props) => {
  return renderField({ ...props, cols: "s12 m6" });
};

export const renderSelectFieldOneColumn = (props) => {
  return renderSelectField({ ...props, cols: "s12" });
};

export const renderSelectFieldTwoColumns = (props) => {
  return renderSelectField({ ...props, cols: "s12 m6" });
};

export const renderAutocompleteFieldOneColumn = (props) => {
  return renderAutocompleteField({ ...props, cols: "s12" });
};

export const renderAutocompleteFieldTwoColumns = (props) => {
  return renderAutocompleteField({ ...props, cols: "s12 m6" });
};

export const renderFileFieldOneColumn = (props) => {
  return renderFileField({ ...props, cols: "s12" });
};

export const renderFileFieldTwoColumns = (props) => {
  return renderFileField({ ...props, cols: "s12 m6" });
};

export const renderTextareaOneColumn = (props) => {
  return renderTextarea({ ...props, cols: "s12" });
};

export const renderTextareaTwoColumns = (props) => {
  return renderTextarea({ ...props, cols: "s12 m6" });
};

export const renderCheckFieldOneColumn = (props) => {
  return renderCheckField({ ...props, cols: "s12" });
};

export const renderCheckFieldTwoColumns = (props) => {
  return renderCheckField({ ...props, cols: "s12 m6" });
};

export const renderAutosuggestFieldTwoColumns = (props) => {
  return renderAutosuggestField({ ...props, cols: "s12 m6" });
};

export const richTextField = ({ input }) => (
  <ReactQuill {...input} readOnly={true} theme="bubble" />
);
