import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/free-solid-svg-icons";

export default function LoadingScreen() {
  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return (
      <div className="loading-screen">
        <FontAwesomeIcon
          icon={faFire}
          className="flame-icon"
          size="4x"
          spin
        />
        <p className="loading-text">Cargando...</p>
      </div>
    );
  }

  return null;
}