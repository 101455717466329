export const i18nDatepickerEs = {
    cancel:	'Cancelar',
    clear: 'Borrar',
    done: 'Ok',
    months:[
        'Enero', 
        'Febrero', 
        'Marzo', 
        'Abril', 
        'Mayo', 
        'Junio', 
        'Julio', 
        'Agosto', 
        'Septiembre', 
        'Octubre', 
        'Noviembre', 
        'Diciembre'
    ],
    monthsShort: [
        'Ene', 
        'Feb', 
        'Mar', 
        'Abr', 
        'May', 
        'Jun', 
        'Julo', 
        'Ago', 
        'Sep', 
        'Oct', 
        'Nov', 
        'Dic'
    ],
    weekdays: [
        'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
    ],
    weekdaysShort: [
        'Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'
    ],
    weekdaysAbbrev: ['D','L','M','X','J','V','S']
};