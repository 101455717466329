import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import M from "materialize-css";

import PageContainer from "../../components/UI/PageContainer";
import PageTitle from "../../components/UI/PageTitle";
import ContentContainer from "../../components/UI/ContentContainer";
import Pagination from "../../components/UI/Pagination";
import Spacer from "../../components/UI/Spacer";
import ActionButton from "../../components/UI/ActionButton";
import FormButton from "../../components/UI/FormButton";

import { dateToDatepicker } from "../../helpers/converter";

import { showError } from "../../helpers/messages";

import {
  renderFieldOneColumn,
  renderFieldTwoColumns,
  renderSelectFieldTwoColumns,
} from "../../components/UI/Fileds";

import CustomMaterialTable from "../../components/UI/CustomMaterialTable";
import {
  startLoading,
  endLoading,
  fetchCourseTypeList,
  fetchFormadorList,
} from "../../actions/ui";

const ScreensCourseSelector = ({
  onSelect,
  submitting,
  handleSubmit,
  reset,
  fetchCourseList,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { selected } = useSelector((state) => state.course);

  const [{ paging, list }, setSearch] = useState({ paging: {}, list: [] });

  const { courseTypeList, formadorList } = useSelector((state) => state.ui);

  useEffect(() => {
    // dispatch(fetchCourseTypeList()).then((res) => {
    //     if (res.ok) {
    //         const selects = document.querySelectorAll('select');
    //         M.FormSelect.init(selects, {});
    //     }
    // });
    Promise.all([
      dispatch(fetchCourseTypeList()),
      dispatch(fetchFormadorList()),
    ]).then(() => {
      const selects = document.querySelectorAll("select");
      M.FormSelect.init(selects, {});
      dispatch(endLoading());
    });
  }, []);

  const handleFetch = (resp) => {
    if (resp.ok) {
      const { count, current, page, pageCount } = resp.data.paging;
      setSearch({
        paging: { count, current, page, pageCount },
        list: resp.data.list,
      });
    } else {
      showError(t("message-title-error"), t(resp.message));
    }
  };

  const handlePaginate = (index) => {
    const values = {
      titulo: document.getElementById("titulo").value,
      // cursos_tipo_id: document.getElementById("cursos_tipo_id").value,
    };
    dispatch(fetchCourseList({ ...paging, ...values, page: index })).then(
      handleFetch
    );
  };

  const handleSearch = (values) => {
    dispatch(fetchCourseList({ ...paging, ...values, page: 1 })).then(
      handleFetch
    );
  };

  const handleReset = () => {
    dispatch(reset("courseSelectorForm"));
  };

  const renderForm = () => {
    return (
      <form className="search-form" onSubmit={handleSubmit(handleSearch)}>
        <div className="row margin">
          <Field
            type="text"
            name="deal_id_pipedrive"
            id="deal_id_pipedrive"
            label={t("screens-course-document-list-pipe_drive_deal")}
            component={renderFieldTwoColumns}
            icon="text_fields"
            key="deal_id_pipedrive"
          />

          <Field
            type="text"
            name="codigo_grupo_alumnos"
            id="codigo_grupo_alumnos"
            label={t("screens-course-document-list-group")}
            component={renderFieldTwoColumns}
            icon="group"
            key="codigo_grupo_alumnos"
          />
        </div>
        <div className="row margin">
          <Field
            type="text"
            name="titulo"
            id="titulo"
            label={t("screens-course-list-titulo")}
            component={renderFieldOneColumn}
            icon="text_fields"
            key="titulo"
          />
        </div>

        <div className="row">
          <div className="input-field col s12">
            <FormButton
              type="submit"
              title={t("search-form-button-title")}
              disabled={submitting}
            />

            <FormButton
              type="reset"
              title={t("reset-form-button-title")}
              disabled={submitting}
              onClick={handleReset}
            />
          </div>
        </div>
      </form>
    );
  };

  const renderTable = () => {
    const columns = [
      { title: t("screens-list-acciones"), field: "acciones", width: '5%' },
      { title: t("Deal"), field: "deal_id_and_titulo", defaultGroupOrder: 0 },      
      { title: t("screens-course-form-title"), field: "titulo", defaultGroupOrder: 1 },
      //{ title: t("screens-course-list-student_group_code"), field: "codigo_grupo_alumnos" },
      /* { title: t("screens-course-list-customer_name"), field: "customer_name_pipedrive" },
      { title: t("screens-course-list-sede"), field: "lugar" },
      { title: t("screens-course-list-gep_tipo_formacion"), field: "p_categoria_formacion" }, */
    ];

    const data = list.map((item) => ({
      acciones: (
        <ActionButton
          icon="arrow_forward"
          tooltip={t("screens-list-acciones-select")}
          extraClass="btn-floating btn-small waves-effect waves-light pink darken-4 ml-3"
          onClick={() => onSelect(item)}
        />
      ),
           
      deal_id_and_titulo: `${item.deal_id_pipedrive} - ${item.p_titulo_deal}`,
      titulo: item.titulo,
      //codigo_grupo_alumnos: item.codigo_grupo_alumnos,
      customer_name_pipedrive: item.customer_name_pipedrive,
      lugar: item.lugar,
      p_categoria_formacion: item.p_categoria_formacion,
    }));

    return <CustomMaterialTable
      columns={columns}
      data={data}
      options={{ grouping: true, pageSize: 20 }}
      />;
  };

  return (
    <PageContainer>
      <PageTitle title={t("screens-course-selector-title")} />

      <ContentContainer>{renderForm()}</ContentContainer>

      <ContentContainer>{renderTable()}</ContentContainer>

      <Pagination paging={paging} onPaginate={handlePaginate} />

      <Spacer height="5" />
    </PageContainer>
  );
};

const validate = (values) => ({});

export default reduxForm({
  form: "courseSelectorForm",
  validate: validate,
  destroyOnUnmount: true,
})(ScreensCourseSelector);
