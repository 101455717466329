import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../components/UI/FormButton';
import { 
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns 
} from '../../components/UI/Fileds';

import { 
    fetchDemarcacionList
} from '../../actions/ui';

import { setOrganizerFilter } from '../../actions/organizer';

const OrganizerSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { filter } = useSelector(state => state.organizer);

    const { demarcacionList } = useSelector(state => state.ui);

    useEffect(() => {

        const collapsibles = document.querySelectorAll('.collapsible');
        M.Collapsible.init(collapsibles, {});

        dispatch(fetchDemarcacionList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });
        
        dispatch(initialize('organizerSearchForm', filter));

    }, [dispatch, filter]);
    
    const handleReset = () => {
        dispatch(setOrganizerFilter({}));
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(setOrganizerFilter(values));
    }

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>
                <div className="row margin">
                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-organizer-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre" 
                        maxLength="128"
                    />

                    <Field type="text" name="apellidos" id="apellidos" 
                        label={ t('screens-organizer-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="apellidos" 
                        maxLength="128"
                    />
                </div>
                <div className="row margin">
                    <Field name="demarcacion_id" id="demarcacion_id" 
                        label={ t('screens-organizer-list-demarcacion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="demarcacion_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            demarcacionList.map(demarcacion => (
                                <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> { t('filter-title') }
                </div>
                <div className="collapsible-body">
                    { renderForm() }
                </div>
            </li>
            
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'organizerSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(OrganizerSearchForm);