import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, Field, initialize } from 'redux-form';
import { useHistory } from "react-router-dom";

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Spacer from '../../../components/UI/Spacer';
import FormButton from '../../../components/UI/FormButton';

import { 
    renderFieldOneColumn
} from '../../../components/UI/Fileds';

import { sendFaceToFaceInscriptions } from '../../../actions/course';
import { startLoading, endLoading } from '../../../actions/ui';

import { showError, showConfirmation, showErrorExcel } from '../../../helpers/messages';

import { dateToDatepicker } from '../../../helpers/converter';

const ScreensCourseFaceToFaceLoad = ({ submitting, handleSubmit, dirty }) => {

    const fileInput = React.createRef();
    
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const history = useHistory();

    const { selected } = useSelector(state => state.course);

    useEffect(() => {
        dispatch(initialize('courseFaceToFaceForm', {
            id: (selected) ? selected.id : 0
        }));
    }, [dispatch, selected]);

    const handleSend = (values) => {
        const archivo = fileInput.current.files[0];

        if (!archivo) {
            showError(t('message-title-error'), t('message-error-no-file'));
        } else {
            dispatch(startLoading());
            dispatch(
                sendFaceToFaceInscriptions({ archivo: archivo, id: values.id })
            ).then((res) => {
                //d
                dispatch(endLoading());
                if (res.ok) {
                    history.goBack();;
                } else {
                    if (res.errores_excel) {
                        const alumnos = res.errores_excel.reduce((acc, alumno) => {                            
                            console.log(alumno);
                            return ` ${acc ? acc+'<p style="text-align:left">' : '<p style="text-align:left">' + acc }${alumno}`;
                        }, '');
                        showErrorExcel(`${t(res.message)}`, `${alumnos}`);                               
                    }else {
                        showErrorExcel(t('message-title-error'), t(res.message)); 
                    }
                }
            });
        }
    };

    const handleCancel = () => {        
        history.replace('/planner');       
    };

    const renderForm = () => {
        if (!selected) {
            history.replace('/planner'); 
        }

        let lugar = selected.lugar;
        if (!lugar && selected.ciudad) {
            lugar = selected.ciudad;
        } else {
            //lugar = selected.demarcacion.nombre;
        }
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    <label>{`${selected.titulo} - `}</label>
                    <br />
                    <label>{`${dateToDatepicker(selected.fecha_inicio)} a ${dateToDatepicker(selected.fecha_fin)} - ${lugar}`}</label>
                </div>
                <div className="row margin">
                    <Field type="hidden" name="id" id="id"
                        component={ renderFieldOneColumn }
                    />
                </div>
                <div className="row margin">
                    <div className={`input-field file-field col s12`}>
                        <div className="btn">
                            <span>{ t('screens-course-online-archivo') }</span>
                            <input name="archivo" type="file" key="archivo" id="archivo" ref={ fileInput } />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" />
                        </div>
                        <small className="errorTxt6">
                            <div id="crole-error" className="error">
                            </div>
                        </small>      
                    </div>
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-course-face-to-face-load-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};
    return errors;
};

export default reduxForm({
    form: 'courseFaceToFaceForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensCourseFaceToFaceLoad);