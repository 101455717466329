import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../../components/UI/FormButton';
import DatePicker from '../../../components/UI/DatePicker';
import { 
    renderSelectFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns
} from '../../../components/UI/Fileds';

import { setStudentDegreeFilter } from '../../../actions/studentDegree';

import { 
    startLoading,
    endLoading,
    fetchDemarcacionList, 
    fetchDiplomaTipoList 
} from '../../../actions/ui';

import { dateToDatepicker } from '../../../helpers/converter';

const DegreeStudentSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { filter } = useSelector(state => state.studentDegree);

    const { demarcacionList, diplomaTipoList } = useSelector(state => state.ui);

    useEffect(() => {
        const elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});

        dispatch(startLoading());
        Promise.all([
            dispatch(fetchDiplomaTipoList()),
            dispatch(fetchDemarcacionList())
        ]).then(() => {
            const selects = document.querySelectorAll('select');
            M.FormSelect.init(selects, {});
            dispatch(endLoading());
        });

        dispatch(initialize('studentDegreeSearchForm', {
            ...filter,
            datepicker_fecha_1: dateToDatepicker(filter.fecha_1),
            datepicker_fecha_2: dateToDatepicker(filter.fecha_2)
        }));
    }, [dispatch, filter]);
    
    const handleReset = () => {
        dispatch(setStudentDegreeFilter({}));
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(setStudentDegreeFilter({
            nombre: values.nombre,
            apellidos: values.apellidos,
            poblacion: values.poblacion,
            demarcacion_id: values.demarcacion_id,
            diplomas_tipo_id: values.diplomas_tipo_id,
            fecha_1: values.fecha_1,
            fecha_2: values.fecha_2,
        }));
    }

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>
                <div className="row margin">
                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-student-degree-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre" 
                    />
                    <Field type="text" name="apellidos" id="apellidos" 
                        label={ t('screens-student-degree-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="person" 
                        key="apellidos" 
                    />
                </div>

                <div className="row margin">
                    <Field type="text" name="poblacion" id="poblacion" 
                        label={ t('screens-student-degree-list-poblacion') } 
                        component={ renderFieldTwoColumns } 
                        icon="location_city" 
                        key="poblacion" 
                    />
                    <Field name="demarcacion_id" id="demarcacion_id" 
                        label={ t('screens-student-degree-list-demarcacion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="location_on" 
                        key="demarcacion_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            demarcacionList.map(demarcacion => (
                                <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                <div className="row margin">
                    <Field name="diplomas_tipo_id" id="diplomas_tipo_id" 
                        label={ t('screens-student-degree-list-tipo') } 
                        component={ renderSelectFieldOneColumn } 
                        icon="bookmark" 
                        key="diplomas_tipo_id"
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            diplomaTipoList.map(tipo => (
                                <option value={ tipo.id } key={ tipo.id }>{ tipo.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                <div className="row margin">
                    <DatePicker form="studentDegreeSearchForm" name="fecha_1" 
                        value={''}
                        label={ t('screens-student-degree-list-fecha_1') }
                        render={ renderFieldTwoColumns }
                    />
                    <DatePicker form="studentDegreeSearchForm" name="fecha_2" 
                        value={''}
                        label={ t('screens-student-degree-list-fecha_2') }
                        render={ renderFieldTwoColumns }
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> { t('filter-title') }
                </div>
                <div className="collapsible-body">
                    { renderForm() }
                </div>
            </li>
            
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'studentDegreeSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(DegreeStudentSearchForm);