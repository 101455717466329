import React from "react";
import CustomMaterialTable from "../../../components/UI/CustomMaterialTable";
import moment from "moment";

const NotesListView = ({ notes, actions }) => {
  const columns = [
    {
      title: "",
      field: "name",
      render: (row) => {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html:
                "<p style='color: #0892d2'>" +
                moment(row.modifiedDate).format("LL, h:mm a") +
                " - <span style='color: #0892d2'>" +
                row.user_name +
                "</span></p>" +
                row.name,
            }}
          />
        );
      },
    },
  ];

  const data = notes?.map((note) => ({
    ...note,
  }));

  return (
    <CustomMaterialTable
      title="Notes"
      columns={columns}
      data={data}
      //actions={actions}
      options={{
        search: true,
        paging: true,
        filtering: false,
        exportButton: false,
        grouping: false,
      }}
      groupingEnabled={false}
    />
  );
};

export default NotesListView;
