import TYPES from "./types";
import { apiClientWithToken } from "../services/backend";

export const setError = (message) => ({
  type: TYPES.UI_SET_ERROR,
  payload: message,
});

export const unsetError = () => ({ type: TYPES.UI_UNSET_ERROR });

export const startLoading = () => ({ type: TYPES.UI_START_LOADING });

export const endLoading = () => ({ type: TYPES.UI_END_LOADING });

export const deleteCache = () => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().post("users/deleteCache", {});

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchBookSectionList = () => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get(
        "/biblioteca-secciones/list",
        {}
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setBookSectionList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setBookSectionList = (sectionList) => ({
  type: TYPES.UI_SET_BOOK_SECTION_LIST,
  payload: sectionList,
});

export const fetchStudentList = (filter) => {
  return async () => {
    try {
      const res = await apiClientWithToken().get("/alumnos/list", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, data: data.list };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchTaskList = (filter) => {
  return async () => {
    try {
      const res = await apiClientWithToken().get("/tareas/list", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, data: data.list };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchDemarcacionList = () => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/demarcaciones/list", {});

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setDemarcacionList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchDemarcacionRaizList = () => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/demarcaciones/list", {
        params: { parent_id: 0 },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setDemarcacionList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setDemarcacionList = (demarcacionList) => ({
  type: TYPES.UI_SET_DEMARCACION_LIST,
  payload: demarcacionList,
});

export const fetchCourseTypeList = () => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/cursos-tipos/list", {});

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setCourseTypeList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setCourseTypeList = (courseTypeList) => ({
  type: TYPES.UI_SET_COURSE_TYPE_LIST,
  payload: courseTypeList,
});

export const fetchCourseList = (filter) => {
  return async () => {
    try {
      const res = await apiClientWithToken().get("/cursos/list", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, data: data.list };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchDocumentList = (filter) => {
  return async () => {
    try {
      const res = await apiClientWithToken().get("/documentos/list", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, data: data.list };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const fetchDiplomaTipoList = () => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/diplomas-tipos/list", {});

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setDiplomaTipoList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setDiplomaTipoList = (diplomaTipoList) => ({
  type: TYPES.UI_SET_DIPLOMA_TIPO_LIST,
  payload: diplomaTipoList,
});

export const fetchStatusTaskList = () => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/tareas-estados/list", {});

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setStatusTaskList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setStatusTaskList = (statusTaskList) => ({
  type: TYPES.UI_SET_STATUS_TASK_LIST,
  payload: statusTaskList,
});

export const fetchRoleList = () => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/roles/list", {});

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setRoleList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setRoleList = (roleList) => ({
  type: TYPES.UI_SET_ROLE_LIST,
  payload: roleList,
});

export const fetchOrganizadorList = (filter = {}) => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/organizadores/list", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setOrganizadorList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setOrganizadorList = (organizadorList) => ({
  type: TYPES.UI_SET_ORGANIZADOR_LIST,
  payload: organizadorList,
});

export const fetchFormadorList = (filter = {}) => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get(
        "/formadores-homologados/list",
        { params: { ...filter } }
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setFormadorList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setFormadorList = (formadorList) => ({
  type: TYPES.UI_SET_FORMADOR_LIST,
  payload: formadorList,
});

export const fetchCustomerList = (filter = {}) => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/customers/list", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setCustomerList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setCustomerList = (customerList) => ({
  type: TYPES.UI_SET_CUSTOMER_LIST,
  payload: customerList,
});

export const fetchCenterList = (filter = {}) => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/centros/", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setCenterList(data));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setCenterList = (centerList) => ({
  type: TYPES.UI_SET_CENTER_LIST,
  payload: centerList,
});

// trainers list

export const fetchTrainerList = (filter = {}) => {
  return async (dispatch) => {
    try {
      const timestamp = Date.now();
      const res = await apiClientWithToken().get(
        `/trainers/?timestamp=${timestamp}`,
        {
          params: { ...filter },
        }
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setTrainerList(data.list));
        return { ok: true, message: data.message, data: data.list };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setTrainerList = (trainerList) => ({
  type: TYPES.UI_SET_TRAINER_LIST,
  payload: trainerList,
});

// rooms list

export const fetchRoomList = (filter = {}) => {
  return async (dispatch) => {
    try {
      const res = await apiClientWithToken().get("/salas", {
        params: { ...filter },
      });

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setRoomList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (e) {
      console.log(e);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setRoomList = (roomList) => ({
  type: TYPES.UI_SET_ROOM_LIST,
  payload: roomList,
});

// kpis list
export const fetchKpiList = () => {
  return async (dispatch) => {
    try {
      const timestamp = Date.now();
      const res = await apiClientWithToken().get(
        "/cursos/contadorDealsByStatus",
        {
          params: {
            _: timestamp,
          },
        }
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setKpiList(data.list));
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (e) {
      console.log(e);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setKpiList = (kpiList) => ({
  type: TYPES.UI_SET_KPI_LIST,
  payload: kpiList,
});
