import { ro } from "date-fns/locale";
import TYPES from "../actions/types";

//---------------------
const INITIAL_STATE = {
  loading: false,
  message: null,
  bookSectionList: [],
  demarcacionList: [],
  courseTypeList: [],
  diplomaTipoList: [],
  statusTaskList: [],
  roleList: [],
  organizadorList: [],
  formadorList: [],
  customerList: [],
  centerList: [],
  trainerList: [],
  roomList: [],
  kpiList: [],
};

//-----------------------------------------------------------
export const uiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.UI_SET_ERROR:
      return { ...state, message: action.payload };

    case TYPES.UI_UNSET_ERROR:
      return { ...state, message: null };

    case TYPES.UI_START_LOADING:
      return { ...state, loading: true };

    case TYPES.UI_END_LOADING:
      return { ...state, loading: false };

    case TYPES.UI_SET_BOOK_SECTION_LIST:
      return { ...state, bookSectionList: action.payload };

    case TYPES.UI_SET_DEMARCACION_LIST:
      return { ...state, demarcacionList: action.payload };

    case TYPES.UI_SET_COURSE_TYPE_LIST:
      return { ...state, courseTypeList: action.payload };

    case TYPES.UI_SET_DIPLOMA_TIPO_LIST:
      return { ...state, diplomaTipoList: action.payload };

    case TYPES.UI_SET_STATUS_TASK_LIST:
      return { ...state, statusTaskList: action.payload };

    case TYPES.UI_SET_ROLE_LIST:
      return { ...state, roleList: action.payload };

    case TYPES.UI_SET_ORGANIZADOR_LIST:
      return { ...state, organizadorList: action.payload };

    case TYPES.UI_SET_FORMADOR_LIST:
      return { ...state, formadorList: action.payload };
    case TYPES.UI_SET_CUSTOMER_LIST:
      return { ...state, customerList: action.payload };

    case TYPES.UI_SET_CENTER_LIST:
      return { ...state, centerList: action.payload };

    case TYPES.UI_SET_TRAINER_LIST:
      return { ...state, trainerList: action.payload };

    case TYPES.UI_SET_ROOM_LIST:
      return { ...state, roomList: action.payload };
    
    case TYPES.UI_SET_KPI_LIST:
      return { ...state, kpiList: action.payload };

    default:
      return state;
  }
};
