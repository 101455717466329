import TYPES from "../actions/types";

const INITIAL_STATE = {
  eventsList: [],
  eventsPaging: {},
  eventFilter: {},
  chosenEvent: null,
  customersList: [],
  customersPaging: {},
  customerFilter: {},
  chosenCustomer: null,
  occupationsList: [],
  occupationsPaging: {},
  occupationFilter: {},
  openFormationDeals: [],
  chosenOccupation: null,
};

export const plannerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.STORE_CLEANUP:
      return INITIAL_STATE;

    case TYPES.SET_EVENTS:
      return {
        ...state,
        eventsList: action.payload.list,
        eventsPaging: action.payload.paging,
      };
    case TYPES.SET_PLAN_CUSTOMERS:
      return {
        ...state,
        customersList: action.payload.list,
        // customersPaging: action.payload.paging,
      };
    case TYPES.SELECT_EVENT:
      return {
        ...state,
        chosenEvent: action.payload,
      };
    case TYPES.SELECT_PLAN_CUSTOMER:
      return {
        ...state,
        chosenCustomer: action.payload,
      };
    case TYPES.SET_EVENT_FILTER:
      return {
        ...state,
        eventFilter: action.payload,
        eventsPaging: {},
      };
    case TYPES.SET_PLAN_CUSTOMER_FILTER:
      return {
        ...state,
        customerFilter: action.payload,
        customersPaging: {},
      };
    case TYPES.SET_OCCUPATION_FILTER:
      return {
        ...state,
        occupationFilter: action.payload,
        occupationsPaging: {},
      };
    case TYPES.SET_OCCUPATIONS:
      return {
        ...state,
        occupationsList: action.payload.list,
        occupationsPaging: action.payload.paging,
      };
    case TYPES.SELECT_OCCUPATION:
      return {
        ...state,
        chosenOccupation: action.payload,
      };
    case TYPES.SET_OPEN_FORMATION_DEALS:
      return {
        ...state,
        openFormationDeals: action.payload,
      };
      
    default:
      return state;
  }
};
