import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { showError } from '../../helpers/messages';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import SortingHeader from '../../components/UI/SortingHeader';

import { 
    fetchDocuments,
    download } from '../../actions/studentArea';
import { startLoading, endLoading } from '../../actions/ui';

const ScreensStudentAreaDocuments = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging } = useSelector(state => state.document);
    const { sort, direction } = paging;

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchDocuments(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging });
    }, [dispatch, fetchData]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, sort: name, direction: newDirection });
    };

    const handleDownload = (document) => {
        dispatch(download(document));
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-document-list-nombre-original') }
                            direction={ (sort === 'nombre_original') ? direction : '' }
                            onClick={ () => handleSort('nombre_original') }
                        />
                        <th>{ t('screens-document-list-descripcion') }</th>
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.nombre_original }</td>
                                <td>{ item.descripcion }</td>
                                <td>
                                    <ActionButton icon="file_download" 
                                        tooltip={ t('screens-list-acciones-download') }
                                        extraClass="pink darken-4" 
                                        onClick={ () => handleDownload(item) } />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-document-list-title') } />

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );

};

export default ScreensStudentAreaDocuments;