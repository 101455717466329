import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Table, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

const { Option } = Select;
const TrainingMaterials = ({
  isVisible,
  onClose,
  onSend,
  materials,
  setMaterials,
  editable,
}) => {
  const { t } = useTranslation();
  const [materialId, setMaterialId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const materialInputRef = useRef(null);
  const quantityRef = useRef(null);
  const addButtonRef = useRef(null);

  const deleteMaterial = (index) => {
    const newMaterials = [...materials];
    newMaterials.splice(index, 1);
    setMaterials(newMaterials);
  };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  // Mock data for firefighter materials

  const trainingMaterials = [
    {
      category: "EPI'S",
      items: [
        { name: "Chaquetones alumno", quantity: 4 },
        { name: "Guantes alumno", quantity: 4 },
        { name: "Cascos alumno", quantity: 4 },
        { name: "Chaquetón formador", quantity: 1 },
        { name: "Casco formador", quantity: 1 },
        { name: "Guantes formador", quantity: 1 },
      ],
    },
    {
      category: "Formación",
      items: [
        { name: "Bandeja simulador", quantity: 1 },
        { name: "Tubo de conexión", quantity: 1 },
        { name: "Baterías", quantity: 2 },
        { name: "Pulsador", quantity: 1 },
        { name: "Sopletes", quantity: 2 },
        { name: "Botellas de gas", quantity: 2 },
        { name: "Llave inglesa", quantity: 1 },
        { name: "Maquina de humo", quantity: 1 },
        { name: "Alargo", quantity: 1 },
        { name: "Botellas de propano", quantity: 2 },
        { name: "Árbol de gas", quantity: 1 },
        { name: "Mangueras 25mm", quantity: 2 },
        { name: "Mangueras 45mm", quantity: 2 },
        { name: "Manguera 70mm", quantity: 1 },
        { name: "Lanzas 25mm", quantity: 2 },
        { name: "Lanza 45mm", quantity: 2 },
        { name: "Bifurcación Y", quantity: 1 },
        { name: "Baterías", quantity: 2 },
        { name: "Pulsador", quantity: 1 },
        { name: "Soplete", quantity: 2 },
        { name: "Botellas de gas", quantity: 2 },
        { name: "Llave inglesa", quantity: 1 },
        { name: "Maquina de humo", quantity: 1 },
        { name: "Alargo", quantity: 1 },
        { name: "Botellas de propano", quantity: 2 },
      ],
    },
    {
      category: "Material riesgo químico",
      items: [
        { name: "Traje anti salpicaduras", quantity: 1 },
        { name: "Gafas de seguridad", quantity: 1 },
        { name: "Guantes nitrilo", quantity: 1 },
        { name: "1DUMMY", quantity: 1 },
        { name: "ERAS", quantity: 2 },
        { name: "Botellas de recambio", quantity: 2 },
        { name: "Kit absorbentes", quantity: 1 },
        { name: "Bidón grande con abertura en el medio", quantity: 1 },
        { name: "Bidón de agua con colorante", quantity: 1 },
        { name: "Mangueras 25mm", quantity: 1 },
        { name: "Lanza 25mm", quantity: 1 },
        { name: "Reducciones", quantity: 1 },
        { name: "Lona azul", quantity: 1 },
        { name: "Maceta de fibra", quantity: 1 },
        { name: "Cuñas fuga", quantity: 1 },
        { name: "Mat. recogida de residuos", quantity: 1 },
        { name: "Sepiolita", quantity: 1 },
        { name: "Imbornal metálico", quantity: 1 },
      ],
    },
    {
      category: "Vehículos",
      items: [
        { name: "Furgos", quantity: 1 },
        { name: "Seat Ibiza 3214 DTM", quantity: 1 },
        { name: "Peugeot Rifter (MAD) 4384 LTY", quantity: 1 },
        { name: "Volkswagen Caddy 9955 MDD", quantity: 1 },
        { name: "Ford Transit Courier (PCI) 3608 MJP", quantity: 1 },
        { name: "Ford Transit Courier (NAU) 3609 MJP", quantity: 1 },
      ],
    },
    {
      category: "Unidades Móviles",
      items: [
        { name: "NISSAN L 50 B 0870 PG", quantity: 1 },
        { name: "Renault Mascot (MAD) 6989 BXB", quantity: 1 },
        { name: "NISSAN NT400 CABSTAR (MAD) 9372 JJZ", quantity: 1 },
        { name: "13 Renault B180 10 (CAD) 3597 JYF", quantity: 1 },
        { name: "FORD TRANSIT 4531 LXZ", quantity: 1 },
        { name: "FORD TRANSIT 1467 MJM", quantity: 1 },
      ],
    },
    {
      category: "Vehículos preventivos",
      items: [
        { name: "FORD RANGER 2.2 TDCI XL 4WD 3970 HTH", quantity: 1 },
        { name: "RENAULT (CAMIÓ) 3276 JSG", quantity: 1 },
        { name: "Remolque (MAD)", quantity: 1 },
        { name: "Remolque (SBD)", quantity: 1 },
      ],
    },
  ];

  const showErrorAlert = (msg) => {
    setMessage(msg);
    setOpen(true);
  };

  const suggestedMaterialsMockWithId = trainingMaterials.flatMap(
    (category, categoryIndex) =>
      category.items.map((item, itemIndex) => ({
        id: categoryIndex * 1000 + itemIndex + 1, // Generate a unique ID for each item
        category: category.category,
        name: item.name,
        quantity: item.quantity,
      }))
  );

   const findMaterialInMaterials = (material) => {
    if (!Array.isArray(materials)) {
      return -1; // Return -1 if materials is not an array
    }
    return materials.findIndex((m) => m.name === material.name);
  };

  const findMaterialNameInList = (materialId) => {
    const material = suggestedMaterialsMockWithId.find(
      (material) => material.id == materialId
    );

    return material ? material.name : "";
  };

  const updateExistingMaterialQuantity = (materialIndex) => {
    const material = materials[materialIndex];
    const materialInTrainingMaterials = trainingMaterials
      .flatMap((category) => category.items)
      .find((item) => item.name === material.name);

    // Calculate the total quantity of the material that has been added previously
    const existingQuantity = materials.reduce((total, current) => {
      return current.name === material.name ? total + current.quantity : total;
    }, 0);

    // Check if the new quantity does not exceed the available quantity
    if (
      materialInTrainingMaterials &&
      existingQuantity + quantity <= materialInTrainingMaterials.quantity
    ) {
      const newMaterials = [...materials];
      newMaterials[materialIndex].quantity += quantity;
      setMaterials(newMaterials);
    } else {
      // Show an error message if the new quantity exceeds the available quantity
      showErrorAlert(t("screens-training-materials-quantity-error"));
    }
  };

  const addNewMaterial = (materialId, materialName) => {
    const material = suggestedMaterialsMockWithId.find(
      (material) => material.id == materialId
    );
  
    
    const materialsArray = Array.isArray(materials) ? materials : [];
  
    // Calculate the total quantity of the material that has been added previously
    const existingQuantity = materialsArray.reduce((total, current) => {
      return current.name == materialName ? total + current.quantity : total;
    }, 0);
  
    if (material && existingQuantity + quantity <= material.quantity) {
      setMaterials([
        ...materialsArray,
        { id: materialId, name: materialName, quantity },
      ]);
      setQuantity(1);
    } else {
      showErrorAlert(t("screens-training-materials-quantity-error"));
    }
  };

  const addMaterial = () => {
    if (materialId) {
      const materialIndex = findMaterialInMaterials(materialId);

      if (materialIndex !== -1) {
        updateExistingMaterialQuantity(materialIndex);
      } else {
        const materialName = findMaterialNameInList(materialId);
        addNewMaterial(materialId, materialName);
      }
    }
    materialInputRef.current.focus();
  };

  const handleMaterialChange = (value) => {
    setMaterialId(value);
    quantityRef.current.focus();
  };

  const handleQuantityChange = (value) => {
    setQuantity(value);
    addButtonRef.current.focus();
  };

  const columns = [
    {
      title: t("screens-training-materials-material-column"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("screens-training-materials-quantity-column"),
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  if (editable) {
    columns.push({
      title: t("screens-training-materials-action-column"),
      key: "action",
      render: (_, __, index) => (
        <Button type="danger" onClick={() => deleteMaterial(index)}>
          {t("screens-training-materials-delete-button")}
        </Button>
      ),
    });
  }

  // Set focus on the material input when the form becomes visible and is editable
  useEffect(() => {
    if (isVisible && editable && materialInputRef.current) {
      materialInputRef.current.focus();
    }
  }, [isVisible, editable]);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error">{message}</Alert>
      </Snackbar>

      <Modal
        title={
          editable
            ? t("screens-training-materials-add-title")
            : t("screens-training-materials-title")
        }
        open={isVisible} // Replaced 'visible' with 'open'
        onCancel={onClose}
        width={440}
        footer={
          editable
            ? [
              <Button key="cancel" onClick={onClose}>
                {t("button-title-cancel")}
              </Button>,
              <Button key="send" type="primary" onClick={onSend}>
                {t("button-title-send")}
              </Button>,
            ]
            : null
        }
      >
        <Form>
          <Form.Item
            label={t("screens-training-materials-material-label")}
            hidden={!editable}
          >
            <Select
              ref={materialInputRef}
              showSearch
              placeholder={t(
                "screens-training-materials-select-material-placeholder"
              )}
              optionFilterProp="children"
              onChange={handleMaterialChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {suggestedMaterialsMockWithId.map((material) => (
                <Option key={material.id}>{material.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("screens-training-materials-quantity-label")}
            hidden={!editable}
          >
            <InputNumber
              ref={quantityRef}
              min={1}
              value={quantity}
              onChange={handleQuantityChange}
            />
          </Form.Item>

          <Form.Item hidden={!editable}>
            <Button type="primary" ref={addButtonRef} onClick={addMaterial}>
              {t("screens-training-materials-add-material-button")}
            </Button>
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          dataSource={Array.isArray(materials) ? materials : []}
          rowKey="name"
          pagination={false}
          scroll={{ y: 240 }}
        />
      </Modal>
    </div>
  );
};
export default TrainingMaterials;