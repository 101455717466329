import TYPES from './types';
import { apiClientWithToken, downloadExcelFile, downloadFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchStudentCourseDone = (filter) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithToken().get(
                `/alumnos-cursos/cursosRealizadosAlumno`, { params: { ...filter } }
            );
            const { ok, result: { data } } = res.data;
            if (ok) {
                dispatch(setStudentCourseDone(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentCourseDone = (data) => ({
   type: TYPES.SET_STUDENT_COURSE_DONE, payload: data
});

export const downloadStudentCourseDoneExcel = (filter) => {
    return async () => {
        try {
            const res = await downloadExcelFile(
                `/alumnos-cursos/excelDoneAlumno`, 
                'cursos-realizados.xlsx',
                filter, {}
            );
            const blob = await res.blob();
            fileDownload(blob, 'cursos-realizados.xlsx');
            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const downloadCertificate = (id) => {
    return async () => {
        try {
            const res = await downloadFile(
                `/cursos/descargarCertificadoAlumno/${ id }`, 
                `curso-${id}.pdf`
            );
            const blob = await res.blob();
            fileDownload(blob, `curso-${id}.pdf`);

            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const downloadCertificateAttendance = (id) => {
    return async () => {
        try {
            const res = await downloadFile(
                `/cursos/descargarCertificadoAsistenciaAlumno/${ id }`, 
                `curso-${id}-asistencia.pdf`
            );
            const blob = await res.blob();
            fileDownload(blob, `curso-${id}.pdf`);

            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const download = (document) => {
    return async () => {
        try {
            const res = await downloadFile(
                `/documentos/download/${ document.id }`, 
                document.nombre_original
            );
            const blob = await res.blob();
            fileDownload(blob, document.nombre_original);

            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const fetchDocuments = (filter) => {
    return async (dispatch) => {
        try {            
            const res = await apiClientWithToken().get(
                '/documentos/documentosAlumno', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setDocuments(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setDocuments = (data) => ({
   type: TYPES.SET_DOCUMENTS, payload: data
});

export const fetchStudentTasks = (filter) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithToken().get(
                '/tareas-alumnos/tareasAlumno', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudentTasks(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentTasks = (data) => ({
   type: TYPES.SET_STUDENT_TASKS, payload: data
});
