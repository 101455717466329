import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEs from "./locales/es/translation.json";
import translationPt from "./locales/pt/translation.json";

const language = localStorage.getItem('language') || 'es';
localStorage.setItem('language', language);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "es", // use es if detected lng is not available
        lng: language,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        resources: {
            es: {
                translation: translationEs
            },
            pt: {
                translation: translationPt
            },
        }
        // have a common namespace used around the full app
        //ns: ["translations"],
        //defaultNS: "translations"
    });

export default i18n;
