import i18n from "i18next";
import M from "materialize-css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Field, change, initialize, reduxForm } from "redux-form";
import ContentContainer from "../../components/UI/ContentContainer";
import FormButton from "../../components/UI/FormButton";
import PageContainer from "../../components/UI/PageContainer";
import PageTitle from "../../components/UI/PageTitle";
import Spacer from "../../components/UI/Spacer";
import ScreensUserNew from "../../screens/User/New";
import ScreensUserSelector from "../../screens/User/Selector";
//import DatePicker from "react-datepicker";
import DateFnsUtils from "@date-io/date-fns";
import { MenuItem, Select, Snackbar, TextField, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Modal } from "antd";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CommentsTimelineModal from "./components/CommentsTimelineModal";
import NotesListView from "./components/NotesListView";

import { renderFieldTwoColumns } from "../../components/UI/Fileds";

import {
  deleteEvent,
  fetchEvents,
  fetchOccupations,
  plannerAll,
  sendEvent,
  storeCleanup,
} from "../../actions/planner";
import {
  endLoading,
  fetchCenterList,
  fetchDemarcacionList,
  fetchRoomList,
  fetchTrainerList,
  startLoading,
} from "../../actions/ui";
import { fetchStudentUserList, sendUser } from "../../actions/user";
import {
  showConfirmation,
  showError,
  showSuccess,
} from "../../helpers/messages";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { TimePicker, DateTimePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { updateDescription } from "../../actions/course";
import ActionButton from "../../components/UI/ActionButton";
import CustomTabContainer from "../../components/UI/CustomTabContainer";
import { isEmail } from "../../helpers/validators";
import LoadingScreen from "../../hooks/common/loadingScreen";
import TrainingMaterials from "./TrainingMaterials";
import { Autocomplete } from '@material-ui/lab';
import { get } from "lodash";


//Todo:
// 1. Define fields for the form
// 2. Define validation for the form
// 3. Define submit function for the form
// 4. Define cancel function for the form
// 5. Define render function for the form
// 6. Define edit function for the form
// 7. Define new function for the form
// 8. Define delete function for the form
// 9. Define search function for the form
// 10. Refactor code use solid principles
// 11. Refactor code use best practices
// 12. Refactor code use design patterns
// 13. Refactor code use clean code
// 14. Refactor code use clean architecture

const ScreensPlannerForm = ({ submitting, handleSubmit, dirty }) => {
  // const fileInputFoto = React.createRef();

  // const fileInputAdjunto = React.createRef();

  const { t } = useTranslation();

  const { user: userLogged } = useSelector((state) => state.auth);
  const { eventsList } = useSelector((state) => state.planner);
  const { eventFilter } = useSelector((state) => state.planner);
  const [events, setEvents] = useState([]);

  const [isInCompany, setIsInCompany] = useState(false); // puedes cambiar false por el valor inicial que necesites
  const [trainerSelectedOption, setTrainerSelectedOption] = useState(false);
  // occupation
  const { occupationsList } = useSelector((state) => state.planner);
  const { occupationFilter } = useSelector((state) => state.planner);
  const [occupations, setOccupations] = useState([]);
  // const { selected } = useSelector((state) => state.student);

  const { selected } = useSelector((state) => state.course);

  const [user, setUser] = useState(
    selected && selected.user
      ? selected.user
      : { id: "", label: t("selector-user-no-selected") }
  );

  const { demarcacionList } = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  const history = useHistory();
  const [sessions, setSessions] = useState([]);
  //planner index
  const [currentIndex, setCurrentIndex] = useState(null);
  // planner dialog
  const [openDialog, setOpenDialog] = useState(false);

  // planner state
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [occupiedTimeSlots, setOccupiedTimeSlots] = useState({});
  const centerList = useSelector((state) => state.ui.centerList);
  const roomList = useSelector((state) => state.ui.roomList);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isEventPending, setIsEventPending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEventsListFetched, setIsEventsListFetched] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // trainer list
  const { trainerList } = useSelector((state) => state.ui);

  const [isCenterEditable, setIsCenterEditable] = useState(false);
  const [isRoomEditable, setIsRoomEditable] = useState(false);
  const [isDateEditable, setIsDateEditable] = useState(false);
  const [isShiftEditable, setIsShiftEditable] = useState(false);
  const [isTrainerEditable, setIsTrainerEditable] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);

  // date picker
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  // save button
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  // material list
  const [materialsList, setMaterialsList] = useState(sessions.map(() => []));
  const [isMaterialsModalVisible, setMaterialsModalVisible] = useState(false);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  //handle description
  const [description, setDescription] = useState("");

  //set free trainers
  const [freeTrainers, setFreeTrainers] = useState([]);
  const [listTrainers, setListTrainers] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  // course
  const { list_notes } = useSelector((state) => state.course);

  const author = {
    id: userLogged.id,
    username: userLogged.username,
    email: userLogged.email,
    role: userLogged.role_id,
  };

  // save session comments
  const handleSaveComment = async (comment, sessionIndex) => {
    const updatedSessions = sessions.map((session, index) => {
      if (index === sessionIndex) {
        const existingCommentIndex = session.comments.findIndex(
          (c) => c.id === comment.id
        );
        let updatedComments;
        if (existingCommentIndex !== -1) {
          // Comment exists, update it
          updatedComments = session.comments.map((c, i) =>
            i === existingCommentIndex ? comment : c
          );
        } else {
          // Comment doesn't exist, add it
          updatedComments = [...session.comments, comment];
        }
        return {
          ...session,
          comments: updatedComments,
        };
      }
      return session;
    });

    setSessions(updatedSessions);
    const event = {
      id: updatedSessions[sessionIndex].id,
      observaciones: JSON.stringify(updatedSessions[sessionIndex].comments),
    };
    await dispatch(sendEvent(event));
  };

  const handleDeleteComment = async (commentId, sessionIndex) => {
    const updatedSessions = sessions.map((session, index) => {
      if (index === sessionIndex) {
        return {
          ...session,
          comments: session.comments.filter(
            (comment) => comment.id !== commentId
          ),
        };
      }

      return session;
    });

    setSessions(updatedSessions); // update the sessions state or prop

    const event = {
      id: updatedSessions[sessionIndex].id,
      observaciones: JSON.stringify(updatedSessions[sessionIndex].comments),
    };

    await dispatch(sendEvent(event)); // update the event in the database
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDescriptionChange = (content, delta, source, editor) => {
    // setDescription(selected.descripcion);
    setDescription(editor.getHTML());
  };

  const handleSave = () => {
    // Verificar si la descripción ha cambiado
    if (description !== selected.descripcion) {
      // Obtener la fecha y usuario actual para el registro de auditoría
      const auditTime = new Date().toLocaleString();
      const auditUser = userLogged.username + " - " + userLogged.email;

      // Crear el mensaje con la nueva descripción y el registro de auditoría
      const message = `
         ${auditTime}
         ${auditUser}
         ${description}
         ${selected.descripcion}
       `;

      // Actualizar el estado con el nuevo mensaje
      setDescription(description);

      // Crear el payload para la actualización
      const payload = {
        id: selected.id,
        descripcion: message,
      };

      // Actualizar el curso
      dispatch(startLoading());
      dispatch(updateDescription(payload)).then(() => {
        dispatch(endLoading());
        setIsModalVisible(false);
      });
    } else {
      setIsModalVisible(false);
    }
  };
  const getModalWidth = () => {
    const width = window.innerWidth;
    if (width > 1200) return "50%";
    if (width > 992) return "70%";
    if (width > 768) return "90%";
    return "100%";
  };

  // planner handle alert
  const showErrorAlert = (msg) => {
    setMessage(msg);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // planner settings time slots
  const timeSlots = [
    { id: "Madrugada", label: "Madrugada", start: "00:00", end: "7:00" },
    { id: "Mañana", label: "Mañana", start: "7:00", end: "13:00" },
    { id: "Tarde", label: "Tarde", start: "13:00", end: "19:00" },
    { id: "Noche", label: "Noche", start: "19:00", end: "23:59" },
    { id: "Todo el día", label: "Todo el día", start: "00:00", end: "23:59" },
  ];

  //set center list and room list
  useEffect(() => {
    dispatch(startLoading());
    dispatch(fetchCenterList()).then(() => {
      dispatch(fetchRoomList()).then((res) => {
        dispatch(endLoading());
        if (!res.ok) {
          showError(t("message-title-error"), t(res.message));
        }
      });
    });
  }, [dispatch, t]);

  //set trainer list
  useEffect(() => {
    dispatch(startLoading());
    dispatch(fetchTrainerList()).then((res) => {
      dispatch(endLoading());
      setListTrainers(trainerList);
      if (!res.ok) {
        showError(t("message-title-error"), t(res.message));
      }
    });
  }, [dispatch, t]);

  // planner determine time slot
  const determineTimeSlot = (event) => {
    const eventStart = new Date(event.f_inicio);
    const eventStartHours = new Date(event.f_inicio).getHours();
    const eventStartMinutes = new Date(event.f_inicio).getMinutes();
    eventStart.setHours(eventStartHours);
    eventStart.setMinutes(eventStartMinutes);
    eventStart.setSeconds(0);

    const eventEnd = new Date(event.f_fin);
    const eventEndHours = new Date(event.f_fin).getHours();
    const eventEndMinutes = new Date(event.f_fin).getMinutes();
    eventEnd.setHours(eventEndHours);
    eventEnd.setMinutes(eventEndMinutes);
    eventEnd.setSeconds(0);

    for (const timeSlot of timeSlots) {
      const [startHours, startMinutes] = timeSlot.start.split(":").map(Number);
      const [endHours, endMinutes] = timeSlot.end.split(":").map(Number);

      const slotStart = new Date(eventStart);
      slotStart.setHours(startHours, startMinutes, 0);

      const slotEnd = new Date(eventStart);
      slotEnd.setHours(endHours, endMinutes, 0);

      if (eventStart >= slotStart && eventEnd <= slotEnd) {
        return timeSlot.label;
      }
    }

    return null;
  };

  //retrieve occupations
  const retrieveOccupations = async (filter) => {
    // Fetch occupations from API...
    const response = await dispatch(fetchOccupations(filter));
    if (!response.ok) {
      showErrorAlert(t("message-error-occupations"));
    }

    // Calculate occupiedTimeSlots
    const updateOccupiedTimeSlots = response.data.list.reduce((acc, event) => {
      const date = new Date(event.f_inicio).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const timeSlot = determineTimeSlot(event);

      if (!timeSlot) {
        console.log("No time slot found for event", event);
        return acc;
      }

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(timeSlot);

      //if "Todo el día" is booked, set all time slots as booked
      if (timeSlot === "Todo el día") {
        acc[date] = timeSlots.map((slot) => slot.label);
      }

      return acc;
    }, {});

    //setOccupiedTimeSlots(updateOccupiedTimeSlots);
    return updateOccupiedTimeSlots;
  };

  const retrieveTrainersFree = async (session) => {
    console.log("retrieveTrainersFree", session);
    let startDate = session.startDate;
    if (typeof startDate === 'string') {
      startDate = new Date(startDate);
    }
    session.endDate = session.endDate ? session.endDate : session.startDate;
    let endDate = session.endDate;
    if (typeof endDate === 'string') {
      endDate = new Date(endDate);
    }

    // Ensure startDate and endDate are valid Date objects to prevent runtime errors
    const isValidDate = (date) => date instanceof Date && !isNaN(date);

    const getFilteredObject = (startDate, endDate) => {
      // Check if the dates are valid, if not, set them to today's date
      const validStartDate = isValidDate(startDate) ? startDate : new Date();
      const validEndDate = isValidDate(endDate) ? endDate : new Date();

      return {
        f_inicio: validStartDate.toISOString().split("T")[0],
        turno: 'Tarde', // Mañana, Tarde, Noche es indiferente el turno.
        f_fin: validEndDate.toISOString().split("T")[0],
      };
    };

    // Usage example (assuming startDate and endDate are defined and valid Date objects)
    const filter = getFilteredObject(startDate, endDate);

    const response = await dispatch(fetchTrainerList(filter));
    if (!response.ok) {
      showErrorAlert(t("message-error-trainers"));
    }

    // Ensure session.startDate exists
    if (!session || !session.startDate) {
      showErrorAlert(t("message-error-trainers"));
    }

    const addSelectedTrainer = (trainers, trainerId) => {
      if (!listTrainers) return trainers;

      const selectedTrainer = listTrainers.find(
        (trainer) => trainer.id === trainerId && trainer.activo === true
      );

      return selectedTrainer ? [...trainers, selectedTrainer] : trainers;
    };

    let trainers = Array.isArray(response.data) ? response.data : [];

    trainers = session.trainer1
      ? addSelectedTrainer(trainers, session.trainer1)
      : trainers;
    trainers = session.trainer2
      ? addSelectedTrainer(trainers, session.trainer2)
      : trainers;

    let sortedTrainers = trainers.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    // Delete duplicates by id
    let seen = new Set();
    sortedTrainers = sortedTrainers.filter((trainer) => {
      if (trainer && seen.has(trainer.id)) {
        return false;
      } else {
        seen.add(trainer?.id);
        return true;
      }
    });

    return sortedTrainers;
  };

  // Update sessions when eventsList is fetched
  useEffect(() => {
    if (!selected || Object.keys(selected).length === 0) {
      history.push("/planner");
      return;
    }
    if (isEventsListFetched) {
      const filteredEvents = eventsList.filter(
        (event) =>
          event.curso.codigo_grupo_alumnos === selected.codigo_grupo_alumnos
      );
      const mappedEvents = filteredEvents.map((event, index) => ({
        sessionNumber: index + 1,
        id: event.id,
        // startDate: new Date(event.f_inicio),
        // endDate: new Date(event.f_fin),
        startDate: event.f_inicio
          ? new Date(event.f_inicio).toISOString()
          : null,
        endDate: event.f_fin ? new Date(event.f_fin).toISOString() : null,
        center: event.centro_id,
        room: event.sala_id,
        timeSlot: event.turno,
        trainer1: event.trainer_id,
        trainer2: event.trainer_suplente_id,
        estados: event.estados,
        disabled: false,
        address: event.direccion_preventivo
          ? event.direccion_preventivo
          : event.curso.p_direccion,
        materialsList: JSON.parse(event.materiales),
        comments: event.observaciones ? JSON.parse(event.observaciones) : [],
      }));

      setAvailableTimeSlots(timeSlots);
      setSessions(mappedEvents);
      // map materials list
      const mappedMaterialsList = mappedEvents.map(
        (event) => event.materialsList
      );
      setMaterialsList(mappedMaterialsList);

      setIsEventsListFetched(false); // Reset for next update
      setIsLoading(false);
    }
  }, [isEventsListFetched, eventsList]);

  useEffect(() => {
    dispatch(fetchDemarcacionList()).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });

    if (selected) {
      dispatch(
        initialize("studentForm", {
          id: selected.id,
          nombreCurso: selected.titulo,
          dealId: selected.deal_id_pipedrive,
          cliente: selected.customer_name_pipedrive
            ? selected.customer_name_pipedrive
            : "",
          url_custumer_pipedrive: selected.url_custumer_pipedrive
            ? selected.url_custumer_pipedrive
            : "",
          tipo: selected.gep_tipo_formacion ? selected.gep_tipo_formacion : "",
          fecha_inicio: selected.fecha_inicio,
          grupo: selected.codigo_grupo_alumnos,
          estado: selected.planificado ? "Planificado" : "No Planificado",
          descripcion: selected.descripcion,
          p_titulo_deal: selected.p_titulo_deal,
        })
      );

      // Fetch new eventsList here
      if (selected.id) {
        dispatch(startLoading());
        dispatch(fetchEvents({ curso_id: selected.id })).then((res) => {
          dispatch(endLoading());
          if (!res.ok) {
            showError(t("message-title-error"), t(res.message));
          }
          setIsEventsListFetched(true);
        });
      }

      if (selected.user) {
        setUser(selected.user);
        dispatch(change("studentForm", "user_id", selected.user.id));
      }
    } else {
      dispatch(initialize("studentForm", {}));
    }

    const elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, {});
  }, [dispatch, selected]);

  const handleSend = (values) => {
    if (!values.id || values.id === "" || isEventPending) {
      showError(t("message-title-error"), t("message-error-course-id-empty"));
      return;
    }

    showConfirmation(
      t("message-title-confirmation"),
      t("message-send-notification-internally"),
      t("button-title-agree"),
      t("button-title-cancel"),
      () => {
        dispatch(startLoading());
        dispatch(plannerAll(values.id)).then((res) => {
          dispatch(endLoading());

          if (res.ok) {
            showSuccess(t("message-title-success"), t(res.message));
            dispatch(fetchEvents({ curso_id: values.id })).then((res) => {
              dispatch(endLoading());
              if (!res.ok) {
              }
              setIsEventsListFetched(true);
            });

            history.goBack();
          } else {
            showError(t("message-title-error"), t(res.message));
          }
        });
      }
    );
  };

  const handleCancel = () => {
    if (dirty) {
      showConfirmation(
        t("message-title-confirmation"),
        t("message-abandom-form-text"),
        t("button-title-exit"),
        t("button-title-keep-here"),
        () => {
          history.goBack();
        }
      );
    } else {
      history.goBack();
    }
  };

  const handleSelectUser = (user) => {
    const elem = document.getElementById("modal-selector-usuario");
    const instance = M.Modal.getInstance(elem);
    instance.close();

    setUser(user);
    dispatch(change("studentForm", "user_id", user.id));
  };

  const handleNewUser = (values) => {
    const elem = document.getElementById("modal-nuevo-usuario");
    const instance = M.Modal.getInstance(elem);
    instance.close();

    dispatch(startLoading());
    dispatch(sendUser({ Users: values })).then((res) => {
      dispatch(endLoading());

      if (res.ok) {
        setUser(res.message.user);
        dispatch(change("studentForm", "user_id", res.message.user.id));
      } else {
        showError(t("message-title-error"), t(res.message));
      }
    });
  };

  const convertDateFormat = (isoDateString) => {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-indexed in JS
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  // planner create event
  const createEventObject = (sessionIndex) => {
    const session = sessions[sessionIndex];
    return {
      id: session.id,
      curso_id: selected.id,
      //  Default center and room
      // Madrid In company n/a
      //centro_id: "bc444e84-d4db-46aa-8cfe-ac16610c4f10",
      // sala_id: "f6ee611e-4564-41f3-8724-91f16f2b7976",
      //turno: "Mañana",

      centro_id: session.center ? session.center : (selected.center ? selected.center : "53546800-6fae-4dd6-a08c-f5f91642f020"),
      sala_id: session.room ? session.room : (selected.room ? selected.room : "f6ee611e-4564-41f3-8724-91f16f2b7976"),
      // f_inicio: formatDate(session.startDate),
      // f_fin: formatDate(session.endDate),
      f_inicio: convertDateFormat(session.startDate),
      f_fin: convertDateFormat(session.endDate),
      turno: session.timeSlot ? session.timeSlot : "Mañana",
      direccion_preventivo: session.address,
      trainer_id: session.trainer1,
      trainer_suplente_id: session.trainer2,
      estados: session.estados || 0,
      materiales: JSON.stringify(materialsList[sessionIndex]),
      observaciones: JSON.stringify(session.comments),
    };
  };
  // planner handle date change
  const updateSessionsState = (index, event) => {
    const newSessions = [...sessions];
    newSessions[index] = {
      ...newSessions[index],
      startDate: event.f_inicio,
      endDate: event.f_fin,
      disabled: true,
    };
    setSessions(newSessions);
    setIsEventPending(false);
    setIsLoading(false);
    setIsEventsListFetched(true);
    setIsSaveDisabled(true);
    setEditingRowIndex(null);
  };

  // planner save session
  const saveSession = async (event, index) => {
    try {
      dispatch(startLoading());
      const saveResponse = await dispatch(sendEvent(event));

      if (saveResponse.ok) {
        updateSessionsState(index, event);
        showSuccess(t("message-title-success"), t(saveResponse.message));

        const fetchResponse = await dispatch(
          fetchEvents({ curso_id: selected.id })
        );
        if (fetchResponse.ok) {
          setIsEventsListFetched(true); // Set the flag indicating the event list is fetched
        } else {
          throw new Error(t(fetchResponse.message));
        }
      } else {
        throw new Error(t(saveResponse.message));
      }
    } catch (error) {
      showError(t("message-title-error"), error.message);
    } finally {
      dispatch(storeCleanup());
      dispatch(endLoading());
    }
  };

  const handleAddSession = () => {
    const newMaterialsListEntry = [];
    setIsCenterEditable(true);
    setEditingRowIndex(0);
    
    const trinerSinAsignar = listTrainers.find(
      (trainer) => trainer.label === "SIN, ASIGNAR"
    );
    setSessions([
      { name: "", date: "", timeSlot: null, disabled: false, estados: 2, address: selected.p_direccion, trainer1: trinerSinAsignar.id, trainer2: trinerSinAsignar.id },
      ...sessions,
    ]);
    setMaterialsList([newMaterialsListEntry, ...materialsList]);
    setIsEventPending(true);
    setSessions((prevSessions) =>
      prevSessions.map((session, i) =>
        i === 0
          ? { ...session, disabled: false }
          : { ...session, disabled: true }
      )
    );
  };

  const handleSaveSession = (index) => {
    const event = createEventObject(index);

    if (!selected?.id) {
      showError(t("message-title-error"), t("message-error-session-id-empty"));
      return;
    }

    const session = sessions[index];
    const parsedStartDate = new Date(session.startDate);
    const parsedEndDate = new Date(session.endDate);

    if (parsedStartDate > parsedEndDate) {
      showError(
        t("message-title-error"),
        t("message-error-start-date-greater-than-end-date")
      );
      return;
    }

    // Show confirmation dialog before saving
    showConfirmation(
      t("message-title-confirmation"),
      t("message-confirm-save-session"),
      t("button-title-save"),
      t("button-title-cancel"),
      () => saveSession(event, index) // Directly use saveSession here
    );
  };

  const handleRemoveSession = (index) => {
    showConfirmation(
      t("message-title-confirmation"),
      t("message-delete-course-session"),
      t("button-title-delete"),
      t("button-title-cancel"),
      () => {
        const newSessions = [...sessions];
        const event = newSessions[index];
        newSessions.splice(index, 1);
        setSessions(newSessions);
        console.log(newSessions);
        // validate is active
        if (!event.id) {
          setIsEventPending(false);
          setEditingRowIndex(null);
          resetEditingState();
          enableAllSessions();
          return;
        }
        dispatch(startLoading());
        dispatch(deleteEvent(event.id)).then((res) => {
          dispatch(storeCleanup());
          if (res.ok) {
            showSuccess(t("message-title-success"), t(res.message));
            dispatch(startLoading());
            dispatch(fetchEvents({ curso_id: selected.id })).then((res) => {
              if (res.ok) {
                setIsEventsListFetched(true);
                setIsEventPending(false);
              }
              dispatch(storeCleanup());
              dispatch(endLoading());
            });
            setEditingRowIndex(null);
          } else {
            showError(t("message-title-error"), t(res.message));
            setIsLoading(false);
            dispatch(endLoading());
            setIsEventsListFetched(true);
          }
        });
      }
    );
  };

  const fetchAvailableTimeSlotsForDate = async (date, busyTimeSlots) => {
    const dateString = date.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const existingTimeSlots = busyTimeSlots[dateString] || [];
    console.log("existingTimeSlots", existingTimeSlots);

    // Check if "Todo el día" is already booked or if there are any bookings for the day
    const isWholeDayBooked =
      existingTimeSlots.includes("Todo el día") || existingTimeSlots.length > 0;

    console.log("isWholeDayBooked", isWholeDayBooked);

    let availableSlots = [];
    if (!isWholeDayBooked) {
      availableSlots = timeSlots;
    } else if (existingTimeSlots.includes("Todo el día")) {
      availableSlots = [];
    } else {
      availableSlots = timeSlots.filter(
        (slot) =>
          !existingTimeSlots.includes(slot.label) && slot.id !== "Todo el día"
      );
    }

    setAvailableTimeSlots(availableSlots);
    // Display a message if no time slots are available
    if (availableSlots.length === 0) {
      showErrorAlert("No hay turnos disponibles para la fecha seleccionada.");
    }
  };
  // planner handle date change
  const handleDateChange = async (date, index) => {
    setIsShiftEditable(true);
    setSelectedDate(date);
    //setIsLoading(true);
    const newSessions = [...sessions];

    // Set the filter for occupations
    const filter = {
      centro_id: newSessions[index].center,
      sala_id: newSessions[index].room,
      f_inicio: date.toLocaleDateString("en-CA"),
      states: [0, 1, 2, 3],
    };
    // Fetch occupations and wait for the response
    setIsLoading(true);
    // const response = await dispatch(fetchOccupations(filter));
    // if (!response.ok) {
    //   showErrorAlert(t("message-error-occupations"));
    // } else {
    // get literal center selected
    const centerSelected = centerList.find(
      (center) => center.id === newSessions[index].center
    );

    // Check if the center is "incompany" regex find In company
    setIsInCompany(centerSelected?.centro.match(/In company/i) ? true : false);

    if (!centerSelected?.centro.match(/In company/i) ? true : false) {
      const busyTimeSlotsForDate = await retrieveOccupations(filter);
      if (!busyTimeSlotsForDate) {
        showErrorAlert(t("message-error-occupations"));
      }
      fetchAvailableTimeSlotsForDate(date, busyTimeSlotsForDate);
    } else {
      fetchAvailableTimeSlotsForDate(date, []);
    }
    setIsLoading(false);

    newSessions[index].startDate = date;
    newSessions[index].endDate = date;
    newSessions[index].timeSlot = null;
    setSessions(newSessions);
    setIsShiftEditable(true);
    checkSessionCompleteness();
  };

  // planner handle time change
  function createDateFromTimeSlot(date, timeSlot) {
    const [hours, minutes] = timeSlot.split(":");
    const newDate = new Date(date);
    newDate.setHours(hours, minutes, 0, 0);
    return newDate;
  }

  const handleTimeStart = async (index, date) => {
    const newSessions = [...sessions];
    const session = newSessions[index];


    session.startDate = newSessions[index].startDate = date;

    setIsLoading(true);
    const trainers = await retrieveTrainersFree(session);
    setFreeTrainers(trainers);
    setIsLoading(false);
    setSessions(newSessions);
    checkSessionCompleteness();
  };

  const handleTimeEnd = async (index, date) => {

    const newSessions = [...sessions];
    const session = newSessions[index];

    session.endDate = newSessions[index].endDate = date;
    // Validate startDate

    let startDate = new Date(session.startDate);

    let selectedDate = new Date(date);

    // Convert both dates to UTC milliseconds for comparison
    if (startDate && selectedDate && selectedTimeSlot && selectedTimeSlot.end) {
      // Compare the dates only if they are valid
      if (startDate.getTime() > selectedDate.getTime()) {
        showErrorAlert(t("endDateEarlierThanStartDate"));
        session.endDate = createDateFromTimeSlot(date, selectedTimeSlot.end);
        return;
      }
    }

    if (startDate && selectedDate && selectedTimeSlot && selectedTimeSlot.end) {
      // Check if selectedDate is less than session.startDate
      if (startDate.getTime() > selectedDate.getTime()) {
        showErrorAlert(t("dateLessThanStartDate"));
        session.endDate = createDateFromTimeSlot(date, selectedTimeSlot.end);
        return;
      }
    }

    setIsLoading(true);
    const trainers = await retrieveTrainersFree(session);
    setFreeTrainers(trainers);
    setIsLoading(false);

    setSessions(newSessions);
    checkSessionCompleteness();


  };

  const handleTimeChange = async (event, index) => {
    setIsTrainerEditable(true);
    const timeSlot = timeSlots.find(
      (slot) => slot.label === event.target.value
    );
    if (!timeSlot) {
      showErrorAlert("Invalid time slot selected");
      return;
    }

    const newSessions = [...sessions];
    if (checkSessionAction(index) === "create") {
      newSessions[index].estados = 1;
    }

    newSessions[index].timeSlot = event.target.value;
    // newSessions[index].startDate = createDateFromTimeSlot(
    //   selectedDate,
    //   timeSlot.start
    // );

    newSessions[index].startDate = newSessions[index].startDate
      ? createDateFromTimeSlot(newSessions[index].startDate, timeSlot.start)
      : createDateFromTimeSlot(selectedDate, timeSlot.start);

    // newSessions[index].endDate = createDateFromTimeSlot(
    //   selectedDate,
    //   timeSlot.end
    // );
    newSessions[index].endDate = newSessions[index].endDate
      ? createDateFromTimeSlot(newSessions[index].endDate, timeSlot.end)
      : createDateFromTimeSlot(selectedDate, timeSlot.end);
    setSessions(newSessions);

    setSelectedTimeSlot(timeSlot);


    const filter = {
      centro_id: newSessions[index].center,
      sala_id: newSessions[index].room,
    };

    // get literal center selected
    const centerSelected = centerList.find(
      (center) => center.id === newSessions[index].center
    );

    // Check if the center is "incompany" regex find In company
    setIsInCompany(centerSelected?.centro.match(/In company/i) ? true : false);

    // Fetch occupations and wait for the response

    // if (!isInCompany) {
    //   setIsLoading(true);
    //   const busyTimeSlotsForCenterAndRoom = await retrieveOccupations(filter);
    //   if (!busyTimeSlotsForCenterAndRoom) {
    //     showErrorAlert(t("message-error-occupations"));
    //   }

    //   setOccupiedTimeSlots(busyTimeSlotsForCenterAndRoom);
    //   setIsLoading(false);
    // }

    //get Trainer Free
    const session = newSessions[index];
    const trainers = await retrieveTrainersFree(session);
    setFreeTrainers(trainers);

    checkSessionCompleteness();
  };

  const handleCenterChange = (index, event) => {
    // setIsRoomEditable(true);
    const newSessions = [...sessions];
    const isCompanyCenter = event.target.value === "COMPANY_CENTER_ID";
    const updatedSession = {
      ...newSessions[index],
      center: event.target.value,
      room: isCompanyCenter ? "N/A" : null,
      startDate: null,
      endDate: null,
      timeSlot: null,
    };

    // get literal center selected
    const centerSelected = centerList.find(
      (center) => center.id === updatedSession.center
    );

    // Check if the center is "incompany" regex find In company
    setIsInCompany(centerSelected?.centro.match(/In company/i) ? true : false);

    newSessions[index] = updatedSession;
    setSessions(newSessions);
    setIsRoomEditable(!isCompanyCenter);
    checkSessionCompleteness();
  };

  const handleRoomChange = async (index, event) => {
    setIsDateEditable(true);
    const newSessions = [...sessions];
    const updatedSession = {
      ...newSessions[index],
      room: event.target.value,
      startDate: null,
      endDate: null,
      timeSlot: null,
    };
    newSessions[index] = updatedSession;
    setSessions(newSessions);

    // Set the filter for occupations
    const filter = {
      centro_id: updatedSession.center,
      sala_id: updatedSession.room,
      states: [0, 1, 2, 3],
    };

    // get literal center selected
    const centerSelected = centerList.find(
      (center) => center.id === updatedSession.center
    );

    // Check if the center is "incompany" regex find In company
    setIsInCompany(centerSelected?.centro.match(/In company/i) ? true : false);

    if (!isInCompany) {
      setIsLoading(true);
      const busyTimeSlotsForCenterAndRoom = await retrieveOccupations(filter);
      if (!busyTimeSlotsForCenterAndRoom) {
        showErrorAlert(t("message-error-occupations"));
      }
      console.log(
        "busyTimeSlotsForCenterAndRoom - 1",
        busyTimeSlotsForCenterAndRoom
      );
      setOccupiedTimeSlots(busyTimeSlotsForCenterAndRoom);
      setIsLoading(false);
    }

    checkSessionCompleteness();
  };

  // planner disable occupied dates
  /**
   * Disables dates that are already occupied.
   * @param {Date} date - The date to check.
   * @returns {boolean} - True if the date is disabled, false otherwise.
   */
  const disableOccupiedDates = (date, isInCompany) => {
    console.log("disableOccupiedDates", date, isInCompany);
    if (isInCompany) {
      return false;
    }

    // Format the date to a string
    const dateString = date.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Get the occupied slots for the date, or an empty array if none exist
    const occupiedSlots = Array.isArray(occupiedTimeSlots[dateString])
      ? occupiedTimeSlots[dateString]
      : [];
    console.log("occupiedSlots", occupiedSlots); //occupiedSlots Array [ "Madrugada", "Mañana" ] test ok
    // Check if the selected type of training is "FORMACIóN ABIERTA"
    if (selected.gep_tipo_formacion === "FORMACIóN ABIERTA") {
      // Get the selected month from the selected months string
      const selectedMonth = selected.meses
        ? parseInt(selected.meses.split(" - ")[0], 10) - 1
        : date.getMonth();

      const currentYear = new Date().getFullYear();

      // Return true if all time slots are occupied or
      // the selected month does not include the current date's month
      return (
        timeSlots.length === occupiedSlots.length ||
        date.getMonth() !== selectedMonth ||
        date.getFullYear() !== currentYear
      );
    }

    // return timeSlots.length === occupiedSlots.length;
    let cantTurnos = timeSlots.length - 1;
    console.log("cantTurnos", cantTurnos);

    return cantTurnos === occupiedSlots.length;
  };

  const handleEdit = async (index) => {
    if (editingRowIndex !== null) {
      revertChanges();
    }

    if (index !== editingRowIndex) {
      startEditing(index);
      // setIsCenterEditable(true);
      // setIsRoomEditable(true);
      setIsDateEditable(true);
      // setIsShiftEditable(true);
      setIsTrainerEditable(true);

      const session = sessions[index];
      setIsLoading(true);
      const trainers = await retrieveTrainersFree(session);
      console.log("session", session);
      console.log("trainers", trainers);
      setFreeTrainers(trainers);
      setIsLoading(false);

      const filter = {
        centro_id: session.center,
        sala_id: session.room,
      };

      // get literal center selected
      const centerSelected = centerList.find(
        (center) => center.id === session.center
      );

      // Check if the center is "incompany" regex find In company
      setIsInCompany(
        centerSelected?.centro.match(/In company/i) ? true : false
      );

      if (!centerSelected?.centro.match(/In company/i) ? true : false) {
        setIsLoading(true);
        const [busyTimeSlotsForCenterAndRoom, busyTimeSlotsForDate] =
          await Promise.all([
            retrieveOccupations(filter),
            retrieveOccupations({
              ...filter,
              f_inicio: session.startDate
                ? new Date(session.startDate).toLocaleDateString("en-CA")
                : null,
              states: [0, 1, 2, 3],
            }),
          ]);
        setIsLoading(false);

        if (!busyTimeSlotsForCenterAndRoom || !busyTimeSlotsForDate) {
          showErrorAlert(t("message-error-occupations"));
        }

        setOccupiedTimeSlots(busyTimeSlotsForCenterAndRoom);

        if (checkSessionAction(index) === "view") {
          const dateString = new Date(session.startDate).toLocaleDateString(
            "es-ES",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );
          if (busyTimeSlotsForDate[dateString]) {
            if (session.timeSlot === "Todo el día") {
              busyTimeSlotsForDate[dateString] = [];
            } else {
              const index = busyTimeSlotsForDate[dateString].indexOf(
                session.timeSlot
              );
              if (index > -1) {
                busyTimeSlotsForDate[dateString].splice(index, 1);
              }
            }
          }
        }

        fetchAvailableTimeSlotsForDate(
          new Date(session.startDate),
          busyTimeSlotsForDate
        );
      } else {
        fetchAvailableTimeSlotsForDate(new Date(session.startDate), []);
      }
    }
  };
  const revertChanges = () => {
    const originalSession = getOriginalSession();

    if (originalSession) {
      updateSession(originalSession);
    }
    resetEditingState();
    enableAllSessions();
  };

  const getOriginalSession = () => {
    return eventsList.find(
      (event) => event.id === sessions[editingRowIndex].id
    );
  };

  const updateSession = (originalSession) => {
    const newSessions = [...sessions];
    newSessions[editingRowIndex] = {
      ...newSessions[editingRowIndex],
      startDate: new Date(originalSession.f_inicio),
      endDate: new Date(originalSession.f_fin),
      center: originalSession.centro_id,
      room: originalSession.sala_id,
      timeSlot: originalSession.turno,
      trainer1: originalSession.trainer_id,
      trainer2: originalSession.trainer_suplente_id,
      estados: originalSession.estados,
    };
    setSessions(newSessions);
  };

  const resetEditingState = () => {
    setIsEventPending(false);
    setIsCenterEditable(false);
    setIsRoomEditable(false);
    setIsDateEditable(false);
    setIsShiftEditable(false);
    setIsTrainerEditable(false);
    setEditingRowIndex(null);
  };

  const enableAllSessions = () => {
    setSessions((prevSessions) =>
      prevSessions.map((session) => ({ ...session, disabled: false }))
    );
  };

  const startEditing = (index) => {
    setEditingRowIndex(index);
    disableOtherSessions(index);
    setIsEventPending(true);
    // setIsCenterEditable(true);
  };

  const disableOtherSessions = (index) => {
    setSessions((prevSessions) =>
      prevSessions.map((session, i) =>
        i === index
          ? { ...session, disabled: false }
          : { ...session, disabled: true }
      )
    );
  };

  // check is session is completed
  const checkSessionCompleteness = () => {
    const session = sessions[editingRowIndex];
    if (
      // session.center &&
      session.address &&
      session.startDate &&
      session.endDate &&
      // session.timeSlot &&
      session.trainer1
      // session.trainer2
    ) {
      setIsSaveDisabled(false);
    }
  };

  // handle material list

  const handleMaterialsModalClose = () => {
    setMaterialsModalVisible(false);
  };

  const checkSessionAction = (index = editingRowIndex) => {
    // Ensure the session exists
    const session = sessions[index];
    if (!session) {
      return;
    }

    // Define action types
    const VIEW_ACTION = "view";
    const UPDATE_ACTION = "update";
    const CREATE_ACTION = "create";

    // If the session has an id and is not being edited, return "view"
    if (session.id && editingRowIndex !== index) {
      return VIEW_ACTION;
    }

    // If the session has an id, return "update", otherwise return "create"
    return session.id ? UPDATE_ACTION : CREATE_ACTION;
  };



  const handleMaterialsModalSend = () => {
    if (checkSessionAction() === "update") {
      const updatedSessions = [...sessions];

      updatedSessions[editingRowIndex].materialsList =
        materialsList[currentSessionIndex];
      setSessions(updatedSessions);

      const updatedSession = {
        ...updatedSessions[editingRowIndex],
        materiales: JSON.stringify(materialsList[currentSessionIndex]),
      };

      saveSession(updatedSession, editingRowIndex);
      setMaterialsModalVisible(false);
    } else {
      setMaterialsModalVisible(false);
    }
  };

  const handleOnChange = (event, index, trainerNumber) => {
    const newSessions = [...sessions];
    const isTrainerNoAsignadoSelected =
      event.target.value === "ff32f1fe-c425-402b-a83f-3f1dd32cd752";

    if (trainerNumber === 1) {
      newSessions[index].trainer1 = event.target.value;
    } else if (trainerNumber === 2) {
      newSessions[index].trainer2 = event.target.value;
    }

    setSessions(newSessions);
    checkSessionCompleteness();

    setTrainerSelectedOption(isTrainerNoAsignadoSelected);
    console.log(trainerSelectedOption);

    if (isTrainerNoAsignadoSelected) {
      newSessions[index].estados = 2;
      setSessions(newSessions);
    }
  };

  const getTrainers = (index) => {
    const action = checkSessionAction(index);
    //console.log("checkSessionAction(index)", action);
    const trainers = action === "create" || action === 'update' ? freeTrainers : listTrainers;
    return trainers.filter(Boolean);
  };


  const renderForm = () => {
    return (
      <div style={{ position: "relative" }}>
        {isLoading && <LoadingScreen />}
        {/* Campos del formulario */}
        <div className="row margin">
          {/* deal id */}
          <Field
            type="text"
            name="dealId"
            id="dealId"
            label={"Deal ID"}
            component={renderFieldTwoColumns}
            icon="person"
            key="dealId"
            maxLength="128"
            editable={false}
          />
          {/* customer name */}
          <Field
            type="text"
            name="cliente"
            id="cliente"
            label={"Cliente"}
            component={renderFieldTwoColumns}
            icon="person"
            key="cliente"
            maxLength="128"
            editable={false}
          />
        </div>
        <div className="row margin">
          {/* type of training*/}

          <Field
            type="text"
            name="nombreCurso"
            id="nombreCurso"
            label={"Nombre del Curso"}
            component={renderFieldTwoColumns}
            icon="book"
            key="nombreCurso"
            maxLength="128"
            editable={false}
          />
          <Field
            name="tipo"
            id="tipo"
            label={"Tipo de curso"}
            component={renderFieldTwoColumns}
            icon="school"
            key="tipo"
            editable={false}
          />
        </div>

        {/* set status planner  */}
        <div className="row margin">
          <Field
            name="grupo"
            type="text"
            id="grupo"
            label={"Grupo"}
            component={renderFieldTwoColumns}
            icon="group"
            key="grupo"
            editable={false}
          />

          <Field
            name="estado"
            id="estado"
            label={"Estado"}
            component={renderFieldTwoColumns}
            icon="playlist_add_check"
            key="estado"
            editable={false}
          />
        </div>
      </div>
    );
  };

  const estadoColors = {
    1: "#87FD7E",
    2: "#D1D3D1",
    3: "#FEF962",
    4: "#FE7E6F",
    5: "#6F9FFE",
  };
  const getBackgroundColor = (estados, isEditing) => {
    if (isEditing) return "white";
    return estadoColors[estados] || "white";
  };

  const renderSesions = () => {
    return (
      <div style={{ position: "relative" }}>
        {isLoading && <LoadingScreen />}
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <form onSubmit={handleSubmit(handleSend)}>
            <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">Sesiones de capacitación</Typography>
              <Tooltip title={t("message-tooltip-add-session")}>
                <IconButton
                  color="primary"
                  className="btn-floating btn-small waves-effect waves-light darken-3"
                  onClick={handleAddSession}
                  disabled={isEventPending}
                >
                  <Icons.Add />
                </IconButton>
              </Tooltip>
            </Toolbar>
            <TableContainer component={Paper} style={{ maxHeight: "400px", overflow: "auto" }} className="responsive-table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell data-label="Session number">Número de sesión</TableCell>
                    <TableCell data-label="Address">Dirección</TableCell>
                    <TableCell data-label="StartTime">Fecha Inicio</TableCell>
                    <TableCell data-label="EndTime">Fecha Fin</TableCell>
                    <TableCell data-label="Trainer1">Primer Bombero</TableCell>
                    <TableCell data-label="Trainer2">Segundo Bombero</TableCell>
                    <TableCell data-label="Status">Estado</TableCell>
                    <TableCell data-label="Actions">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessions.map((session, index) => (
                    <TableRow
                      key={index}
                      className={index === editingRowIndex ? "editing-row" : ""}
                      style={{
                        backgroundColor: getBackgroundColor(session.estados, index === editingRowIndex),
                      }}
                    >
                      <TableCell data-label="Session Number">{index + 1}</TableCell>
                      <TableCell data-label="Address">
                        <Icons.LocationOn />
                        <TextField
                          value={session.address ? session.address : ""}
                          onChange={(event) => {
                            const newSessions = [...sessions];
                            newSessions[index].address = event.target.value;
                            setSessions(newSessions);
                          }}
                          disabled={index !== editingRowIndex || session.disabled}
                        />
                      </TableCell>
                      <TableCell data-label="StartDate">
                        <Icons.DateRange />
                        <DateTimePicker
                          value={session.startDate ? new Date(session.startDate) : null}
                          onChange={(date) => handleTimeStart(index, date)}
                          ampm={true}
                          minutesStep={5}
                          format="dd/MM/yyyy HH:mm"
                          disabled={isLoading || index !== editingRowIndex || session.disabled}
                        />
                      </TableCell>
                      <TableCell data-label="EndDate">
                        <Icons.DateRange />
                        <DateTimePicker
                          value={session.endDate ? new Date(session.endDate) : null}
                          onChange={(date) => handleTimeEnd(index, date)}
                          ampm={true}
                          minutesStep={5}
                          format="dd/MM/yyyy HH:mm"
                          disabled={isLoading || index !== editingRowIndex || session.disabled}
                        />
                      </TableCell>
                      <TableCell data-label="Trainer1">
                        <Icons.Person />
                        <Select
                          value={session.trainer1 ? session.trainer1 : ""}
                          onChange={(event) => handleOnChange(event, index, 1)}
                          disabled={isLoading || index !== editingRowIndex || session.disabled}
                        >
                          {getTrainers(index).map((trainer) => (
                            <MenuItem key={trainer.id} value={trainer.id}>
                              {trainer.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell data-label="Trainer2">
                        <Icons.Person />
                        <Select
                          value={session.trainer2 ? session.trainer2 : ""}
                          onChange={(event) => handleOnChange(event, index, 2)}
                          disabled={isLoading || index !== editingRowIndex || session.disabled}
                        >
                          {getTrainers(index).map((trainer) => (
                            <MenuItem key={trainer.id} value={trainer.id}>
                              {trainer.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell data-label="Status">
                        <Select
                          value={session.estados ? session.estados : ""}
                          onChange={(event) => {
                            const newSessions = [...sessions];
                            newSessions[index].estados = event.target.value;
                            setSessions(newSessions);
                            checkSessionCompleteness();
                          }}
                          disabled={!isEventPending || index !== editingRowIndex}
                        >
                          <MenuItem value={1}>Planificado</MenuItem>
                          <MenuItem value={2}>Borrador</MenuItem>
                          <MenuItem value={3}>Suspendido</MenuItem>
                          <MenuItem value={4}>Cancelado</MenuItem>
                          <MenuItem value={5}>Finalizado</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell data-label="Actions">
                        <ActionButton
                          icon="save"
                          tooltip={t("screens-list-acciones-save")}
                          extraClass={`btn-floating btn-small waves-effect ${
                            !session.trainer1 ||
                            index !== editingRowIndex ||
                            isSaveDisabled
                            ? "disabled-button"
                            : "green darken-3"
                            }`}
                          onClick={() => handleSaveSession(index)}
                          disabled={index !== editingRowIndex ||
                            isSaveDisabled ||
                            session.disabled}
                        />
                        <ActionButton
                          icon={!isEventPending ? "edit" : "restore"}
                          tooltip={t("screens-list-acciones-edit")}
                          extraClass={`btn-floating btn-small waves-effect waves-light ${isEventPending ? "disabled-button" : "blue darken-3"
                            }`}
                          onClick={() => handleEdit(index)}
                          disabled={session.disabled}
                        />
                        <ActionButton
                          icon="library_books"
                          tooltip={t("screens-list-acciones-add-materials")}
                          extraClass={`btn-floating btn-small waves-effect waves-light ${session.disabled ||
                            checkSessionAction(index) === "create"
                            ? "disabled-button"
                            : "pink darken-3"
                            }`}
                          onClick={() => {
                            setCurrentSessionIndex(index);
                            setMaterialsModalVisible(true);
                          }}
                          disabled={
                            session.disabled ||
                            checkSessionAction(index) === "create"

                          }
                        />
                        <ActionButton
                          icon="comment"
                          tooltip={t("screens-list-acciones-add-comment")}
                          extraClass={`btn-floating btn-small waves-effect waves-light ${session.disabled ||
                            checkSessionAction(index) === "create"
                            ? "disabled-button"
                            : "purple darken-3"
                            }`}
                          onClick={() => {
                            setCurrentSessionIndex(index);
                            setIsModalOpen(true); // Set the state to open the modal
                          }}
                          disabled={
                            session.disabled ||
                            checkSessionAction(index) === "create"
                          }
                        />
                        <ActionButton
                          icon="remove"
                          tooltip={t("screens-list-acciones-remove")}
                          //extraClass="btn-floating btn-small waves-effect waves-light pink darken-3"
                          extraClass={`btn-floating btn-small waves-effect waves-light ${session.disabled ||
                            checkSessionAction(index) === "create"
                            ? "disabled-button"
                            : "pink darken-3"
                            }`}
                          onClick={() => handleRemoveSession(index)}
                          disabled={session.disabled}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="row">
              <div className="input-field col s12">
                <FormButton
                  tooltip={t("message-tooltip-send-notification-internally")}
                  type="submit"
                  title={"Enviar Notif. Int."}
                  disabled={submitting}
                  onClick={handleSubmit(handleSend)}
                />
                <FormButton
                  type="back"
                  title={"Volver"}
                  disabled={submitting}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </form>
        </MuiPickersUtilsProvider>
        <TrainingMaterials
          isVisible={isMaterialsModalVisible}
          onClose={handleMaterialsModalClose}
          onSend={handleMaterialsModalSend}
          materials={materialsList[currentSessionIndex] || []}
          setMaterials={(newMaterials) => {
            const newMaterialsList = [...materialsList];
            newMaterialsList[currentSessionIndex] = newMaterials;
            setMaterialsList(newMaterialsList);
          }}
          editable={editingRowIndex === currentSessionIndex ? true : false}
        />
        <CommentsTimelineModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          comments={sessions[currentSessionIndex]?.comments ?? []}
          onSubmit={handleSaveComment}
          onDeleted={handleDeleteComment}
          saveCommentToDB={handleSaveComment}
          author={author}
          sessionIndex={currentSessionIndex}
        />
      </div>
    );
  };

  const renderInternalNotes = () => {
    return (
      <div style={{ position: "relative" }}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Notas</Typography>
          <Tooltip title={t("message-tooltip-edit-description")}>
            <IconButton
              color="primary"
              className="btn-floating btn-small waves-effect waves-light darken-3"
              onClick={() => setIsModalVisible(true)}
              disabled={isEventPending}
            >
              <Icons.Edit />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <Modal
          title="Notas"
          visible={isModalVisible}
          onOk={() => handleSave()}
          onCancel={() => setIsModalVisible(false)}
          width={getModalWidth()}
        >
          <ReactQuill value={description} onChange={handleDescriptionChange} />
          {/* <ReactQuill value={description} /> */}
        </Modal>
        <div
          className="col s12 m12 l12"
          dangerouslySetInnerHTML={{
            __html: description + (selected?.descripcion || ""),
          }}
        ></div>
      </div>
    );
  };

  // Mapping list notes
  const notes = list_notes?.map((note) => ({
    id: note.id,
    name: note.content,
    modifiedDate: note.update_time,
    user_name: note.user.name,
  }));

  const renderAtachedNotesList = () => {
    return (
      <div style={{ position: "relative" }}>
        <NotesListView notes={notes} />
      </div>
    );
  };
  const tabs = [
    { label: "Notas Producto", content: renderInternalNotes() },
    { label: "Notas Deal", content: renderAtachedNotesList() },
  ];
  return (
    <PageContainer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <PageTitle title={t("screens-planner-list-title")} />

      <ContentContainer>{renderForm()}</ContentContainer>
      <ContentContainer>{renderSesions()}</ContentContainer>
      <CustomTabContainer tabs={tabs} />
      <div id="modal-selector-usuario" className="modal">
        <div className="modal-content">
          <ScreensUserSelector
            onSelect={handleSelectUser}
            fetchUserList={fetchStudentUserList}
          />
        </div>
      </div>

      <div id="modal-nuevo-usuario" className="modal">
        <div className="modal-content">
          <ScreensUserNew
            onSelect={handleNewUser}
            role="ROLE_ALUMNO"
            hideEmail
          />
        </div>
      </div>
      <Spacer height="5" />
    </PageContainer>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.nombre) {
    errors.nombre = i18n.t("validator-error-nombre");
  }

  if (!values.apellidos) {
    errors.apellidos = i18n.t("validator-error-apellidos");
  }

  if (!values.email) {
    errors.email = i18n.t("validator-error-email");
  }

  if (!values.demarcacion_id) {
    errors.demarcacion_id = i18n.t("validator-error-demarcacion");
  }

  return errors;
};

export default reduxForm({
  form: "studentForm",
  validate: validate,
  destroyOnUnmount: false, //true
})(ScreensPlannerForm);
