import TYPES from './types';
import { apiClientWithToken } from '../services/backend';

export const fetchAreas = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/demarcaciones', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setAreas(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setAreas = (data) => ({
   type: TYPES.SET_AREAS, payload: data
});

export const selectArea = (data) => ({
    type: TYPES.SELECT_AREA, payload: data
});

export const deleteArea = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/demarcaciones/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendArea = (area) => {
    return async () => {
        try {
            let res;

            if (area.Demarcaciones.id) {
                res = await apiClientWithToken().put(
                    `/demarcaciones/${area.Demarcaciones.id}`,
                    area
                );
            } else {
                res = await apiClientWithToken().post(
                    '/demarcaciones', area
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};