import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import i18n from 'i18next';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';

import { 
    renderFieldTwoColumns
} from '../../components/UI/Fileds';

import { fetchRoleList } from '../../actions/ui';
import { stringTrim } from '../../helpers/converter';

const ScreensUserNew = ({ submitting, handleSubmit, onSelect, role, hideEmail }) => {
    
    const { t } = useTranslation();

    const { roleList } = useSelector(state => state.ui);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRoleList());

        dispatch(initialize('userNewForm', { 
            username: '',  
            email: '',
            password: '',
            confirm_password: ''
        }));
    }, [dispatch]);

    const handleSend = (values) => {
        const theRole = roleList.find(item => item.code === role);
        onSelect({ 
            ...values,
            username: stringTrim(values.username),
            email: stringTrim(values.email),
            password: stringTrim(values.password),
            role_id: [theRole.id], 
            active: 1 
        });
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    <Field type="text" name="username" id="username-new" 
                        label={ t('screens-user-list-username') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="username-new"
                    />

                    <Field type={(hideEmail ? 'hidden' : 'text')} name="email" id="email-new" 
                        label={ t('screens-user-list-email') } 
                        component={ renderFieldTwoColumns } 
                        icon="email" 
                        key="email-new"
                        visible={!hideEmail}
                    />
                </div>

                <div className="row">
                    <Field type="password" name="password" id="password" 
                        label={ t('screens-profile-password') } 
                        placeholder=""
                        component={ renderFieldTwoColumns } 
                        icon="lock" 
                        key="password"
                    />

                    <Field type="password" name="confirm_password" id="confirm_password" 
                        label={ t('screens-profile-confirm-password') } 
                        placeholder=""
                        component={ renderFieldTwoColumns } 
                        icon="lock_outline" 
                        key="confirm_password"
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-user-new-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.username) {
        errors.username = i18n.t('validator-error-username');
    }

    if (!values.email) {
        errors.email = i18n.t('validator-error-email');
    }

    if (!values.password) {
        errors.password = i18n.t('validator-error-password');
    }
        
    if (!values.confirm_password) {
        errors.confirm_password = i18n.t('validator-error-confirm-password');
    }
    
    if (values.password !== values.confirm_password) {
        errors.confirm_password = i18n.t('validator-error-password-confirm-password');
    }
    
    return errors;
};

export default reduxForm({
    form: 'userNewForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensUserNew);