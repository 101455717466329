import React from 'react';
import { Modal } from 'antd';
import CourseResultsCard from './CourseResultsCard';
import { useTranslation } from "react-i18next";

const OpenFormationsModal = ({ courseId, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={true}
            footer={null}
            onCancel={onClose}
            centered
            width="75%"
            style={{ maxWidth: '100%', top: 20 }}
            styles={{ body: { padding: 0 } }}
        >
            <CourseResultsCard
                courseTitle={t('screens.planner.eventDetailsModal.courseResultsCardTitle')}
                courseId={courseId}
            />
        </Modal>
    );
};

export default OpenFormationsModal;