import React, { useState, useEffect } from 'react';
import { Card, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseStudents } from '../../../actions/courseStudent';
import { useTranslation } from "react-i18next";
import { showError } from "../../../helpers/messages";

const allFields = [
    "id", "curso_id", "ocultar", "titulo", "customer_id", "p_titulo_deal",
    "p_alumnos_totales_formacion", "p_categoria_formacion", "p_hotel_pernocta",
    "p_sede_formacion", "p_fecha_formacion", "p_tipo_servicio", "p_direccion",
    "p_fundae", "p_caes", "p_precio_total_deal", "p_orden_compra", "p_documento_pago",
    "p_centro_de_coste", "p_forma_pago", "p_dia_pago", "p_productos_extras",
    "p_persona_contacto", "p_plazas_reservadas", "p_owner_name", "customer_name_pipedrive",
    "url_customer_pipedrive", "url_deal_pipedrive", "codigo_grupo_alumnos",
    "deal_id_pipedrive", "product_id_pipedrive", "gep_tipo_formacion", "planificado",
    "horas", "horas_asistencia", "fecha_inicio", "fecha_fin", "lugar", "ciudad",
    "pais", "precio", "plazas", "ocupadas", "num_unidad", "ciclo_formativo",
    "certificado_generado", "fecha_certificado", "demarcacion_id", "cursos_tipo_id",
    "organizador_id", "formadores_homologado_id", "descripcion", "p_won_time",
    "p_update_time", "created", "modified", "p_webhook_notification", "wc_id_variacion",
    "wc_sku", "wc_order_id", "meses", "estado_general_formacion"
];

const defaultColumns = [
    { title: 'DNI', dataIndex: 'dni', key: 'dni' },
    { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
    { title: 'Apellidos', dataIndex: 'apellidos', key: 'apellidos' },
    { title: 'Deal', dataIndex: 'deal_id_pipedrive', key: 'deal_id_pipedrive' },
    { title: 'Nombre Empresa', dataIndex: 'customer_name_pipedrive', key: 'customer_name_pipedrive' },
];

const AlumnosInscriptosResultsCard = ({ courseTitle, courseId, columns }) => {
    const {list} = useSelector((state) => state.courseStudent);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            if (courseId) {
                setLoading(true);
                try {
                    const res = await dispatch(fetchCourseStudents(courseId));
                    setLoading(false);
                    if (!res.ok) {
                        showError(t("message-title-error"), t(res.message));
                    }
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [courseId, dispatch]);

    useEffect(() => {
        if (list) {
            setResults(list);
        }else{
            console.log("No hay alumnos inscritos");
        }
    }, [list]); 


    const validColumns = columns && columns.length > 0
        ? columns.filter(col => allFields.includes(col.dataIndex))
        : defaultColumns;

        return (
            <Card 
                title={courseTitle} 
                loading={loading}
                style={{ maxWidth: '100%', width: '100%' }}
            >
                <Table                
                    dataSource={results} 
                    columns={validColumns} 
                    rowKey="id"
                    style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
                />
            </Card>
        );
};

export default AlumnosInscriptosResultsCard;