import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ProductList() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get('https://api.pipedrive.com/v1/products', {
                params: {
                    api_token: 'e3d30416c71bfd5916cb40685a34a8eaf38df976'
                }
            });
            setProducts(result.data.data);
        };
        fetchData();
    }, []);

    return (
        <div className="table-responsive">
            <h3>List of Products</h3>
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Category</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product => (
                        <tr key={product.id}>
                            <td>{product.name}</td>
                            <td>{product.price}</td>
                            <td>{product.category}</td>
                            <td>{product.add_time}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ProductList;