import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, change, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Spacer from '../../../components/UI/Spacer';
import FormButton from '../../../components/UI/FormButton';
import ScreensStudentSelector from '../../Student/Selector';
import ScreensCourseSelector from '../../Course/Selector';
import DatePicker from '../../../components/UI/DatePicker';

import { 
    renderFieldOneColumn, 
    renderCheckFieldOneColumn
} from '../../../components/UI/Fileds';

import { sendStudentCourse } from '../../../actions/studentCourse';
import { startLoading, endLoading } from '../../../actions/ui';
import { fetchStudentList } from '../../../actions/student';
import { fetchCourseList } from '../../../actions/course';

import { showError, showConfirmation } from '../../../helpers/messages';
import { stringTrim, dateToDatepicker } from '../../../helpers/converter';

const ScreensStudentCourseForm = ({ submitting, handleSubmit, dirty }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const history = useHistory();

    const { selected } = useSelector(state => state.studentCourse);

    const [student, setStudent] = useState((selected) ? selected.alumno : { id: '', label: t('selector-student-no-selected') });
    
    const [course, setCourse] = useState((selected) ? selected.curso : { id: '', label: t('selector-course-no-selected') });

    const [studentGroup, setStudentGroup] = useState({ id: '', label: t('selector-student-no-selected') });

    useEffect(() => {
        if (selected) {
            dispatch(initialize('studentCourseForm', { 
                id: selected.id,
                alumno_id: selected.alumno_id, 
                curso_id: selected.curso_id, 
                evaluacion: selected.evaluacion,
                aprobado: selected.aprobado,
                alumno_grupo_id: selected.alumno_grupo_id,
                fecha_inscripcion: selected.fecha_inscripcion,
                datepicker_fecha_inscripcion: dateToDatepicker(selected.fecha_inscripcion),
            }));
            handleSelectStudent(selected.alumno);
            handleSelectCourse(selected.curso);
        } else {
            dispatch(initialize('studentCourseForm', { 
                alumno_id: '', 
                curso_id: '', 
                evaluacion: '',
                aprobado: '',
                alumno_grupo_id: '',
                fecha_inscripcion: '',
                datepicker_fecha_inscripcion: '',
            }));    
        }
        
        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
    }, [dispatch]);

    const handleSend = (values) => {
        if (!values.alumno_id) {
            showError(t('message-title-error'), t('message-error-no-student-selected'));
        } else if (!values.curso_id) {
            showError(t('message-title-error'), t('message-error-no-course-selected'));
        } else {
            const valores = [];
            valores['alumno_id'] = values.alumno_id;
            valores['curso_id'] = values.curso_id;
            if (course.cursos_tipo.nombre === 'Presencial' || course.cursos_tipo.nombre === 'Taller') {
                valores['evaluacion'] = stringTrim(values.evaluacion);
            } else if (course.cursos_tipo.nombre === 'Online') {
                valores['aprobado'] = (values.aprobado) ? 1 : 0;
                valores['alumno_grupo_id'] = values.alumno_grupo_id;
                valores['fecha_inscripcion'] = values.fecha_inscripcion;
            }

            dispatch(startLoading());
            dispatch(
                sendStudentCourse({AlumnosCursos: {...valores}})
            ).then((res) => {
                dispatch(endLoading());
                if (res.ok) {
                    history.goBack();
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });    
        }
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const handleSearchStudent = () => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSearchCourse = () => {
        const elem = document.getElementById('modal-selector-curso');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSearchStudentGroup = () => {
        const elem = document.getElementById('modal-selector-alumno-grupo');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectStudent = (student) => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        if (instance) instance.close();

        setStudent(student);
        dispatch(change('studentCourseForm', 'alumno_id', student.id));
    };

    const handleSelectCourse = (course) => {
        const elem = document.getElementById('modal-selector-curso');
        const instance = M.Modal.getInstance(elem);
        if (instance) instance.close();

        setCourse(course);
        dispatch(change('studentCourseForm', 'curso_id', course.id));

        if (course.cursos_tipo.nombre === 'Presencial' || course.cursos_tipo.nombre === 'Taller') {
            document.getElementById(`input-block-datepicker_fecha_inscripcion`)
                .classList.add('hide');
            document.getElementById(`input-block-aprobado`)
                .classList.add('hide');
            document.getElementById(`input-block-evaluacion`)
                .classList.remove('hide');
            document.getElementById('etiqueta-alumno-grupo')
                .classList.add('hide');
            document.getElementById(`datos-alumno-grupo`)
                .classList.add('hide');
        } else if (course.cursos_tipo.nombre === 'Online') {
            document.getElementById(`input-block-evaluacion`)
                .classList.add('hide');
            document.getElementById(`input-block-datepicker_fecha_inscripcion`)
                .classList.remove('hide');
            document.getElementById(`input-block-aprobado`)
                .classList.remove('hide');
            document.getElementById('etiqueta-alumno-grupo')
                .classList.remove('hide');
            document.getElementById(`datos-alumno-grupo`)
                .classList.remove('hide');
        } else {
            document.getElementById(`input-block-datepicker_fecha_inscripcion`)
                .classList.add('hide');
            document.getElementById(`input-block-aprobado`)
                .classList.add('hide');
            document.getElementById(`input-block-evaluacion`)
                .classList.add('hide');
            document.getElementById('etiqueta-alumno-grupo')
                .classList.add('hide');
            document.getElementById(`datos-alumno-grupo`)
                .classList.add('hide');
        }
    };

    const handleSelectStudentGroup = (student) => {
        const elem = document.getElementById('modal-selector-alumno-grupo');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setStudentGroup(student);
        dispatch(change('studentCourseForm', 'alumno_grupo_id', student.id));
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    <Field type="hidden" name="alumno_id" id="alumno_id"
                        value= { student.id }
                        component={ renderFieldOneColumn }
                    />
                    <label className="ml-1">{t('screens-student-selector-alumno')}</label>
                    <p>
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-alumno"
                            onClick={ handleSearchStudent }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ student.label || t('selector-student-no-selected') }</span>
                    </p>
                </div>

                <div className="row margin">
                    <Field type="hidden" name="curso_id" id="curso_id"
                        value= { course.id }
                        component={ renderFieldOneColumn }
                    />
                    <label className="ml-1">{t('screens-student-course-list-curso')}</label>
                    <p>
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-curso"
                            onClick={ handleSearchCourse }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ course.titulo || t('selector-course-no-selected') }</span>
                    </p>
                </div>

                <Spacer height="3" />

                <div className="row margin">
                    <Field type="number" name="evaluacion" id="evaluacion" 
                        label={ t('screens-student-course-list-evaluacion') } 
                        placeholder=""
                        component={ renderFieldOneColumn } 
                        icon="grade"
                        min="0" 
                        key="evaluacion"
                        visible={false}
                    />

                    <DatePicker form="studentCourseForm" name="fecha_inscripcion" 
                        value={''}
                        label={ t('screens-student-course-list-fecha_inscripcion') }
                        render={ renderFieldOneColumn }
                        visible={false}
                    />

                    <Field type="hidden" name="alumno_grupo_id" id="alumno_grupo_id"
                        value= { studentGroup.id }
                        component={ renderFieldOneColumn }
                    />
                    <label id="etiqueta-alumno-grupo" className="ml-1 hide">{t('screens-student-selector-alumno-grupo')}</label>
                    <p id="datos-alumno-grupo" className="hide">
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-alumno-grupo"
                            onClick={ handleSearchStudentGroup }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ studentGroup.label || t('selector-student-no-selected') }</span>
                    </p>

                    <Spacer height="3" />

                    <Field type="checkbox" name="aprobado" id="aprobado" 
                        className="filled-in"
                        label={ t('screens-student-course-list-aprobado') } 
                        component={ renderCheckFieldOneColumn } 
                        icon="check"
                        key="aprobado"
                        visible={false}
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-student-course-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <div id="modal-selector-alumno" className="modal">
                <div className="modal-content">
                    <ScreensStudentSelector
                        key="selector-alumno-grupo"
                        onSelect={ handleSelectStudent }
                        fetchStudentList={ fetchStudentList }
                    />
                </div>
            </div>

            <div id="modal-selector-curso" className="modal">
                <div className="modal-content">
                    <ScreensCourseSelector
                        onSelect={ handleSelectCourse }
                        fetchCourseList={ fetchCourseList }
                    />
                </div>
            </div>

            <div id="modal-selector-alumno-grupo" className="modal">
                <div className="modal-content">
                    <ScreensStudentSelector
                        key="selector-alumno-grupo"
                        onSelect={ handleSelectStudentGroup }
                        fetchStudentList={ fetchStudentList }
                    />
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.alumno_id) {
        errors.alumno_id = i18n.t('validator-error-alumno');
    }

    if (!values.curso_id) {
        errors.curso_id = i18n.t('validator-error-curso');
    }

    return errors;
};

export default reduxForm({
    form: 'studentCourseForm',
    validate: validate,
    destroyOnUnmount: false, //true
})(ScreensStudentCourseForm);