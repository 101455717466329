import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../components/UI/PageContainer';
import PageTitle from '../../components/UI/PageTitle';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import FormButton from '../../components/UI/FormButton';

import { showError } from '../../helpers/messages';

import { 
    renderFieldTwoColumns ,
    renderFieldOneColumn
} from '../../components/UI/Fileds';

const ScreensStudentSelectorMultiple = ({ onSelect, submitting, handleSubmit, reset, fetchStudentList }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [{ paging, list }, setSearch] = useState({ paging: {}, list: [] });

    const [listSelected, setListSelected] = useState([])

    const handleFetch = (resp) =>  {
        if (resp.ok) {
            const {count, current, page, pageCount } = resp.data.paging;
            setSearch({
                paging: { count, current, page, pageCount },
                list: resp.data.list
            });
        } else {
            showError(t('message-title-error'), t(resp.message));
        }
    };

    const handlePaginate = (index) => {
        const values = {
            nombre: document.getElementById("nombre_selector_alumno_multiple").value,
            apellidos: document.getElementById("apellidos_selector_alumno_multiple").value,
            email: document.getElementById("email_selector_alumno_multiple").value
        };
        dispatch(
            fetchStudentList({ ...paging, ...values, page: index })
        ).then(handleFetch);
    };

    const handleSearch = (values) => {
        dispatch(
            fetchStudentList({ ...paging, ...values, page: 1 })
        ).then(handleFetch);
    }

    const handleReset = () => {
        dispatch(reset('studentSelectorMultipleForm'));
    };

    const onSelectStudent = (item) => {
        const found = listSelected.find(student => student.id === item.id);
        if (!found) {
            setListSelected([...listSelected, item]);
        }
    };

    const handleDelete = (item) => {
        setListSelected(listSelected.filter(student => student.id !== item.id));
    };

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>
                <div className="row margin">
                    <Field type="text" name="nombre" id="nombre_selector_alumno_multiple" 
                        label={ t('screens-book-member-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre_selector_alumno_multiple" 
                    />
                    <Field type="text" name="apellidos" id="apellidos_selector_alumno_multiple" 
                        label={ t('screens-book-member-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="person" 
                        key="apellidos_selector_alumno_multiple" 
                    />                    
                </div>

                <div className="row margin">
                    <Field type="text" name="email" id="email_selector_alumno_multiple" 
                        label={ t('screens-book-member-list-email') } 
                        component={ renderFieldOneColumn } 
                        icon="email" 
                        key="email_selector_alumno_multiple" 
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>
            </form>
        );
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>{ t('screens-list-acciones') }</th>
                        <th>{ t('screens-student-selector-alumno') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>
                                    <ActionButton icon="arrow_forward"
                                        tooltip={ t('screens-list-acciones-select') } 
                                        extraClass="btn-floating btn-small waves-effect waves-light pink darken-4 ml-3" 
                                        onClick={ () => onSelectStudent(item) } />
                                </td>
                                <td>{ item.label }</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    const renderTableSelected = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <caption>Seleccionados</caption>
                <thead>
                    <tr>
                        <th>{ t('screens-student-selector-alumno') }</th>
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listSelected.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.label }</td>
                                <td>
                                    <ActionButton icon="delete_forever"
                                        tooltip={ t('screens-list-acciones-delete') } 
                                        extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-3" 
                                        onClick={ () => handleDelete(item) } />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-student-selector-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <ContentContainer>
                { renderTableSelected() }
            </ContentContainer>

            <div className="row margin">
                <div className="input-field col s12">
                    <button type="button"
                        className="btn btn-large waves-effect waves-light amber darken-4 white-text"
                        onClick={ () => onSelect(listSelected) }
                        disabled={ listSelected.length === 0 }
                    >
                        { t('search-form-button-confirmar') }
                    </button>
                </div>
            </div>


            <Spacer height="5" />
        </PageContainer>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'studentSelectorMultipleForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensStudentSelectorMultiple);