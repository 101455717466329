import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchStudentDegrees = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/alumnos-titulaciones', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudentDegrees(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentDegrees = (data) => ({
   type: TYPES.SET_STUDENT_DEGREES, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/alumnos-titulaciones/excel', 
                'alumnos-titulaciones.xlsx',
                filter, {
                    'nombre': 'Alumnos',
                    'apellidos': 'Alumnos',
                    'ciudad': 'Alumnos',
                    'demarcacion_id': 'Alumnos',
                    'diplomas_tipo_id': 'AlumnosTitulaciones',
                    'fecha_1' :'AlumnosTitulaciones',
                    'fecha_2' :'AlumnosTitulaciones'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'alumnos-titulaciones.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const setStudentDegreeFilter = (filter) => ({
    type: TYPES.SET_STUDENT_DEGREE_FILTER, payload: filter
});

export const deleteStudentDegree = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/alumnos-titulaciones/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendStudentDegree = (studentDegree) => {
    console.log(studentDegree)
    return async () => {
        try {
            const res = await apiClientWithToken().put(
                `/alumnos-titulaciones/${studentDegree.AlumnosTitulaciones.id}`, 
                studentDegree
            );
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectStudentDegree = (data) => ({
    type: TYPES.SELECT_STUDENT_DEGREE, payload: data
});
