import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../components/UI/FormButton';
import { renderFieldTwoColumns, renderSelectFieldTwoColumns } from '../../components/UI/Fileds';

import { setStudentFilter } from '../../actions/student';

import { startLoading, endLoading } from '../../actions/ui';


import { fetchCourseTypeList, fetchDemarcacionList } from '../../actions/ui';
import Divider from '../../components/UI/Divider';

const StudentSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { courseTypeList } = useSelector(state => state.ui);

    const { filter } = useSelector(state => state.student);

    const { demarcacionList } = useSelector(state => state.ui);

    useEffect(() => {
        const collapsibles = document.querySelectorAll('.collapsible');
        M.Collapsible.init(collapsibles, {});

        dispatch(startLoading());
        Promise.all([
            dispatch(fetchCourseTypeList()),
            dispatch(fetchDemarcacionList())
        ]).then(() => {
            const selects = document.querySelectorAll('select');
            M.FormSelect.init(selects, {});
            dispatch(endLoading());
        });

        dispatch(initialize('studentSearchForm', filter));
    }, [dispatch, filter]);
    
    const handleReset = () => {
        dispatch(setStudentFilter({}));
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(setStudentFilter(values));
    }

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>
                <div className="row margin">
                    <Field type="text" name="apellidos" id="apellidos" 
                        label={ t('screens-student-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="person" 
                        key="apellidos" 
                    />

                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-student-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre" 
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="email" id="email" 
                        label={ t('screens-student-list-email') } 
                        component={ renderFieldTwoColumns } 
                        icon="email" 
                        key="email" 
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="pais" id="pais" 
                        label={ t('screens-student-list-pais') } 
                        component={ renderFieldTwoColumns } 
                        icon="map" 
                        key="pais" 
                    />

                    <Field name="demarcacion_id" id="demarcacion_id" 
                        label={ t('screens-organizer-list-demarcacion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="demarcacion_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            demarcacionList.map(demarcacion => (
                                <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                            ))
                        }
                    </Field>
                </div>
                <Divider />
                <div className="row margin">
                    <Field name="cursos_tipo_id" id="cursos_tipo_id" 
                        type="select-multiple"
                        label={ t('screens-course-list-tipo') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="cursos_tipo_id"
                        multiple={ true }
                    >
                        <option value="" disabled>{ t('form-select-choose-one') }</option>
                        {
                            courseTypeList.map(courseType => (
                                <option value={ courseType.id } key={ courseType.id }>{ courseType.nombre }</option>
                            ))
                        }
                    </Field>
                </div>
                <div className="row margin">
                    <Field type="number" name="horas_desde" id="horas_desde" 
                        label={ t('screens-student-list-horas_desde') } 
                        component={ renderFieldTwoColumns } 
                        icon="timer" 
                        key="horas_desde"
                        value="0"
                        min="0" 
                    />
                    <Field type="number" name="horas_hasta" id="horas_hasta" 
                        label={ t('screens-student-list-horas_hasta') } 
                        component={ renderFieldTwoColumns } 
                        icon="timer_off" 
                        key="horas_hasta"
                        value="0"
                        min="0" 
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> { t('filter-title') }
                </div>
                <div className="collapsible-body">
                    { renderForm() }
                </div>
            </li>
            
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'studentSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(StudentSearchForm);