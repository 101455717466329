import React, { useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formValueSelector } from "redux-form";
import { useHistory } from "react-router-dom";

import Can from "../../../routes/Can";

import {
  showError,
  showConfirmation,
  showSuccess,
} from "../../../helpers/messages";

import DocumentCourseSearchForm from "./SearchForm";
import PageTitle from "../../../components/UI/PageTitle";
import PageContainer from "../../../components/UI/PageContainer";
import ContentContainer from "../../../components/UI/ContentContainer";
import Pagination from "../../../components/UI/Pagination";
import ExcelButton from "../../../components/UI/ExcelButton";
import Spacer from "../../../components/UI/Spacer";
import ActionButton from "../../../components/UI/ActionButton";
import SortingHeader from "../../../components/UI/SortingHeader";
import FloatingButtonAction from "../../../components/UI/FloatingButtonAction";

import {
  fetchCourseDocuments,
  downloadExcel,
  deleteCourseDocument,

} from "../../../actions/courseDocument";

import { download } from '../../../actions/document';

import { startLoading, endLoading } from "../../../actions/ui";

const ScreensDocumentCourseList = ({
  titulo,
  nombre_original,
  descripcion,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { list, paging, filter } = useSelector((state) => state.courseDocument);
  const { sort, direction } = paging;

  const history = useHistory();

  const fetchData = useCallback(
    (options) => {
      dispatch(startLoading());
      dispatch(fetchCourseDocuments(options)).then((res) => {
        dispatch(endLoading());
        if (!res.ok) {
          showError(t("message-title-error"), t(res.message));
        }
      });
    },
    [dispatch, t]
  );

  useEffect(() => {
    fetchData({ ...paging, ...filter });
  }, [dispatch, filter, fetchData]);

  const handlePaginate = (index) => {
    fetchData({ ...paging, ...filter, page: index });
  };

  const handleSort = (name) => {
    let newDirection = "asc";
    if (sort === name) {
      newDirection = direction === "asc" ? "desc" : "asc";
    }
    fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
  };

  const handleDownload = (document) => {
    dispatch(download(document));
};

  const handleDelete = (id, name) => {
    showConfirmation(
      t("message-title-confirmation"),
      t("message-delete-course-document-text", { name }),
      t("button-title-delete"),
      t("button-title-cancel"),
      () => {
        dispatch(startLoading());
        dispatch(deleteCourseDocument(id)).then((res) => {
          dispatch(endLoading());
          if (res.ok) {
            showSuccess(t("message-title-success"), t(res.message));
            fetchData({ ...paging, ...filter, page: 1 });
          } else {
            showError(t("message-title-error"), t(res.message));
          }
        });
      }
    );
  };

  const handleExcel = () => {
    dispatch(startLoading());
    dispatch(
      downloadExcel({
        titulo: titulo,
        nombre_original: nombre_original,
        descripcion: descripcion,
      })
    ).then((res) => {
      dispatch(endLoading());
      if (!res.ok) {
        showError(t("message-title-error"), t(res.message));
      }
    });
  };

  const handleAdd = () => {
    history.push("/documentos/cursos/formulario");
  };

  const renderTable = () => {
    return (
      <table className="dataTable striped highlight responsive-table"style={{ fontSize: "12px" }}>
        <thead>
          <tr>
            <th>{"Deal"}</th>
            <SortingHeader
              title={t("screens-course-document-list-titulo")}
              direction={sort === "titulo" ? direction : ""}
              onClick={() => handleSort("titulo")}
            />
            {/* <SortingHeader
              title={t("screens-course-document-list-group")}
              direction={sort === "group" ? direction : ""}
              onClick={() => handleSort("group")}
            /> */}
            <SortingHeader
              title={t("screens-course-document-list-archivo")}
              direction={sort === "nombre_original" ? direction : ""}
              onClick={() => handleSort("nombre_original")}
            />
            <th>{t("screens-course-document-list-descripcion")}</th>
            <th>{t("screens-list-acciones")}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => (
            <tr key={item.id}>
              <td>{item.curso.deal_id_pipedrive + ' - ' + item.curso.p_titulo_deal}</td>
              <td>{item.curso.titulo}</td>
              {/* <td>{item.curso.codigo_grupo_alumnos}</td> */}
              <td>{item.documento.nombre_original}</td>
              <td>{item.documento.descripcion}</td>
              <td>
                <Can
                  perform="DocumentosCursos:delete"
                  yes={() => (
                    <ActionButton
                      icon="delete_forever"
                      tooltip={t("screens-list-acciones-delete")}
                      extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-3"
                      onClick={() =>
                        handleDelete(
                          item.id,
                          `${item.documento.nombre_original} - ${item.curso.titulo}`
                        )
                      }
                    />
                  )}
                />
                <Can perform="Documentos:descargar" yes={() => (
                  <ActionButton icon="file_download"
                    tooltip={t('screens-list-acciones-download')}
                    extraClass="btn-floating btn-small waves-effect waves-light pink darken-4"
                    onClick={() => handleDownload(item.documento)} />
                )} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <PageContainer>
      <PageTitle title={t("screens-course-document-list-title")}>
        <Can
          perform="DocumentosCursos:excel"
          yes={() => <ExcelButton onPressButton={handleExcel} />}
        />
      </PageTitle>

      <DocumentCourseSearchForm />

      <ContentContainer>{renderTable()}</ContentContainer>

      <Pagination paging={paging} onPaginate={handlePaginate} />

      <Spacer height="5" />

      <Can
        perform="DocumentosCursos:add"
        yes={() => (
          <FloatingButtonAction
            icon="add"
            title={t("screens-list-acciones-add")}
            action={handleAdd}
          />
        )}
      />
    </PageContainer>
  );
};

const formSelector = formValueSelector("courseDocumentSearchForm");

export default connect((state) => ({
  titulo: formSelector(state, "titulo"),
  nombre_original: formSelector(state, "nombre_original"),
  descripcion: formSelector(state, "descripcion"),
}))(ScreensDocumentCourseList);
