import React from "react";
import { useTranslation } from "react-i18next";

function CustomAgenda({ event, title, color }) {
  const { t } = useTranslation();
  return (
    <div className="agenda" style={{ color: color }}>
      <h5 className="agenda-title">{title}</h5>

      <p className="agenda-item">
        <strong>{t("screens.planner.eventDetailsModal.customer")}:</strong>{" "}
        {event.client}
      </p>

      <p className="agenda-item">
        <strong>{t("screens.planner.eventDetailsModal.center")}:</strong>{" "}
        {event.center} -{" "}
        <strong>{t("screens.planner.eventDetailsModal.room")}:</strong>{" "}
        {event.room}
      </p>

      <p className="agenda-item">
        <strong>{t("screens.planner.eventDetailsModal.trainer")}:</strong>{" "}
        {event.trainer}
      </p>

      <p className="agenda-item">
        <strong>{t("screens.planner.eventDetailsModal.trainingType")}:</strong>{" "}
        {event.trainingType}
      </p>
    </div>
  );
}

export default CustomAgenda;
