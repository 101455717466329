import React from "react";
import { Form, Button, Space } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CommentPanel = ({
  form,
  commentText,
  setCommentText,
  validateAndSubmit,
  setInputVisible,
}) => (
  <div
    style={{
      marginBottom: "26px",
      //backgroundColor: "#f5f5f5",
      padding: "12px",
      borderRadius: "8px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
      border: "1px solid #f0f0f0",
    }}
  >
    {" "}
    <Form form={form} layout="vertical" name="comment_form">
      <Form.Item
        name="text"
        rules={[{ required: true, message: "No puede estar vacio." }]}
      >
        <ReactQuill
          value={commentText}
          onChange={(_, __, ___, editor) => {
            setCommentText(editor.getHTML());
          }}
          // style={{ height: "150px", marginBottom: "10px" }}
          theme="snow"
        />
      </Form.Item>
      <div>
        <Button
          type="primary"
          onClick={validateAndSubmit}
          style={{ marginRight: "5px" }}
        >
          Guardar
        </Button>
        <Button type="default" onClick={() => setInputVisible(false)}>
          Cancelar
        </Button>
      </div>
    </Form>
  </div>
);

export default CommentPanel;
