import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useHistory } from "react-router-dom";

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Spacer from '../../../components/UI/Spacer';
import FormButton from '../../../components/UI/FormButton';

import { sendOnlineCourses } from '../../../actions/course';
import { startLoading, endLoading } from '../../../actions/ui';

import { showError } from '../../../helpers/messages';

const ScreensCourseOnlineLoad = ({ submitting, handleSubmit, dirty }) => {

    const fileInput = React.createRef();
    
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const history = useHistory();

    const handleSend = (values) => {
        const archivo = fileInput.current.files[0];

        if (!archivo) {
            showError(t('message-title-error'), t('message-error-no-file'));
        } else {
            dispatch(startLoading());
            dispatch(
                sendOnlineCourses({ archivo: archivo })
            ).then((res) => {
                dispatch(endLoading());
                if (res.ok) {
                    history.push('/cursos');
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });
        }
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    <div className={`input-field file-field col s12`}>
                        <div className="btn">
                            <span>{ t('screens-course-online-archivo') }</span>
                            <input name="archivo" type="file" key="archivo" id="archivo" ref={ fileInput } />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" />
                        </div>
                        <small className="errorTxt6">
                            <div id="crole-error" className="error">
                            </div>
                        </small>      
                    </div>
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-course-online-load-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};
    return errors;
};

export default reduxForm({
    form: 'courseOnlineForm',
    validate: validate,
    destroyOnUnmount: true
})(ScreensCourseOnlineLoad);