import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';

import FormButton from '../../../components/UI/FormButton';
import { 
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns
} from '../../../components/UI/Fileds';

import { 
    fetchCourseTypeList
} from '../../../actions/ui';

import { setStudentCourseFilter } from '../../../actions/studentCourse';

const StudentCourseSearchForm = ({ submitting, handleSubmit }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { filter } = useSelector(state => state.studentCourse);

    const { courseTypeList } = useSelector(state => state.ui);

    useEffect(() => {

        const elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});

        dispatch(fetchCourseTypeList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });
        
        dispatch(
            initialize('studentCourseSearchForm', filter)
        );

    }, [dispatch, filter]);
    
    const handleReset = () => {
        dispatch(
            setStudentCourseFilter({})
        );
    };

    const handleSearch = (values) => {
        const collapsibles = document.getElementsByClassName('collapsible-search-form');
        const instance = M.Collapsible.getInstance(collapsibles[0]);
        instance.close();

        dispatch(
            setStudentCourseFilter(values)
        );
    }

    const renderForm = () => {
        return (
            <form className="search-form" onSubmit={ handleSubmit(handleSearch) }>
                <div className="row margin">
                    <Field type="text" name="nombre" id="nombre" 
                        label={ t('screens-student-course-list-nombre') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="nombre" 
                    />
                    <Field type="text" name="apellidos" id="apellidos" 
                        label={ t('screens-student-course-list-apellidos') } 
                        component={ renderFieldTwoColumns } 
                        icon="person" 
                        key="apellidos" 
                    />
                </div>

                <div className="row margin">
                    <Field type="text" name="email" id="email" 
                        label={ t('screens-student-course-list-email') } 
                        component={ renderFieldTwoColumns } 
                        icon="mail" 
                        key="email" 
                    />
                    <Field type="text" name="titulo" id="titulo" 
                        label={ t('screens-student-course-list-curso') } 
                        component={ renderFieldTwoColumns } 
                        icon="event" 
                        key="titulo" 
                    />
                </div>
                <div className="row margin">
                    <Field name="cursos_tipo_id" id="cursos_tipo_id" 
                        label={ t('screens-course-list-tipo') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="cursos_tipo_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            courseTypeList.map(courseType => (
                                <option value={ courseType.id } key={ courseType.id }>{ courseType.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('search-form-button-title') }
                            disabled={ submitting }
                        />
                        <FormButton type="reset"
                            title={ t('reset-form-button-title') }
                            disabled={ submitting }
                            onClick={ handleReset }
                        />
                    </div>
                </div>
            </form>
        );
    };

    return (
        <ul className="collapsible popout collapsible-search-form">
            <li>
                <div className="collapsible-header">
                    <i className="material-icons">tune</i> { t('filter-title') }
                </div>
                <div className="collapsible-body">
                    { renderForm() }
                </div>
            </li>
            
        </ul>
    );
};

const validate = (values) => ({});

export default reduxForm({
    form: 'studentCourseSearchForm',
    validate: validate,
    destroyOnUnmount: false
})(StudentCourseSearchForm);