import TYPES from './types';
import { apiClientWithToken, downloadExcelFile, downloadFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchStudentCourses = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/alumnos-cursos', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudentCourses(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentCourses = (data) => ({
   type: TYPES.SET_STUDENT_COURSES, payload: data
});

export const selectStudentCourse = (data) => ({
    type: TYPES.SELECT_STUDENT_COURSE, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/alumnos-cursos/excel', 
                'inscripciones.xlsx',
                filter,{
                    'nombre': 'Alumnos',
                    'apellidos': 'Alumnos',
                    'email': 'Alumnos',
                    'titulo': 'Cursos',
                    'cursos_tipo_id' :'Cursos'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'inscripciones.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const setStudentCourseFilter = (filter) => ({
    type: TYPES.SET_STUDENT_COURSE_FILTER, payload: filter
});

export const deleteStudentCourse = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/alumnos-cursos/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendStudentCourse = (studentCourse) => {
    return async () => {
        try {
            let res;

            if (studentCourse.AlumnosCursos.id) {
                res = await apiClientWithToken().put(
                    `/alumnos-cursos/${studentCourse.AlumnosCursos.id}`,
                    studentCourse
                );
            } else {
                res = await apiClientWithToken().post(
                    '/alumnos-cursos', studentCourse
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const downloadCertificate = (id) => {
    return async () => {
        try {
            const res = await downloadFile(
                `/cursos/descargarCertificado/${ id }`, 
                `curso-${id}.pdf`
            );
            const blob = await res.blob();
            fileDownload(blob, `curso-${id}.pdf`);

            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const downloadStudentCourseDoneExcel = (alumno_id, filter) => {
    return async () => {
        try {
            const res = await downloadExcelFile(
                `/alumnos-cursos/excelDone/${alumno_id}`, 
                'cursos-realizados.xlsx',
                filter, {}
            );
            const blob = await res.blob();
            fileDownload(blob, 'cursos-realizados.xlsx');
            return { ok: true };
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const fetchStudentCourseDone = (alumno_id, filter) => {
    return async (dispatch) => {
        try {
            const res = await apiClientWithToken().get(
                `/alumnos-cursos/cursosRealizados/${alumno_id}`, { params: { ...filter } }
            );
            const { ok, result: { data } } = res.data;
            if (ok) {
                dispatch(setStudentCourseDone(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentCourseDone = (data) => ({
   type: TYPES.SET_STUDENT_COURSE_DONE, payload: data
});

export const toggleAprobado = (id) => {
    return async () => {
        try {
            const res = await apiClientWithToken().post(
                `/alumnos-cursos/toggleAprobado/${id}`, {}
            );
            const { ok, result: { data } } = res.data;
            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }
        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};
