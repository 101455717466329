import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import M from 'materialize-css';

import { showError } from '../../helpers/messages';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import SortingHeader from '../../components/UI/SortingHeader';

import { fetchStudentTasks } from '../../actions/studentArea';
import { selectStudentTask } from '../../actions/studentTask';
import { startLoading, endLoading } from '../../actions/ui';

const ScreensStudentAreaTasks = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging } = useSelector(state => state.studentTask);
    const { sort, direction } = paging;

    const [tarea, setTarea] = useState({});

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudentTasks(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging });

        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
    }, [dispatch, fetchData]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, sort: name, direction: newDirection });
    };

    const handleFiles = (item) => {
        dispatch(selectStudentTask(item));
        history.push('/tareas/alumnos/archivos');
    };

    const handleView = (item) => {
        setTarea(item);

        const elem = document.getElementById('modal-tarea-info');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const renderTarea = (tarea_alumno) => {
        return (
            <blockquote className="black-text">
                {
                    (tarea_alumno.tarea)
                    ? <>
                        <label>{tarea.tarea.titulo}</label><br />
                        <p>{tarea_alumno.tarea.descripcion}</p>
                    </>
                    : <></>
                }
            </blockquote>
        );
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-student-task-list-titulo') }
                            direction={ (sort === 'titulo') ? direction : '' }
                            onClick={ () => handleSort('titulo') }
                        />
                        <th>{ t('screens-student-task-list-estado') }</th>
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.tarea.titulo }</td>
                                <td>
                                    <span className={ (item.tareas_estado.nombre === 'Aprobado') ? 'badge green darken-2' : ((item.tareas_estado.nombre === 'Entregado') ? 'badge orange darken-2' : 'badge red darken-2') }>
                                        { t(item.tareas_estado.nombre) }
                                    </span>
                                </td>
                                <td>
                                    <ActionButton icon="search" 
                                        tooltip={ t('screens-list-acciones-view') }
                                        extraClass="btn-floating btn-small waves-effect waves-light cyan darken-2 ml-3"                                        onClick={ () => handleView(item) } />
                                    <ActionButton icon="attach_file"
                                        tooltip={ t('screens-list-acciones-archivos') } 
                                        extraClass="btn-floating btn-small waves-effect waves-light blue darken-2 ml-3" 
                                        onClick={ () => handleFiles(item) } />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>
            <PageTitle title={ t('screens-student-task-list-title') } />

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <div id="modal-tarea-info" className="modal">
                <div className="modal-content">
                    {
                        (tarea)
                        ? renderTarea(tarea)
                        : <p className="black-text">No ha seleccionado ninguna tarea.</p>
                    }
                </div>
            </div>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );

};

export default ScreensStudentAreaTasks;