//TODO :
// 1. Customer name get new field from pipedrive --> pending
// 2. implement range date picker--> pending
// 3. implement search by date range--> pending
// 4. implement search by customer name--> done
// 5. implement search by center --> done
// 6. implement search by room --> done
// 7. implement search by course type --> pending
// 8. implement search by course name----> pending
// 9. implement search by course code----> pending
// 10. implement search by course trainer----> pending

// Test cases:
// 11. implement test cases for search form--> pending
// 12. implement test cases for calendar--> pending
// 13. implement test cases for event dialog--> pending
// 14. implement test cases for event form--> pending
// 15. implement test cases for event list--> pending

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, initialize } from "redux-form";
import { useTranslation } from "react-i18next";
import M from "materialize-css";
import {
  fetchCustomerList,
  fetchCenterList,
  fetchRoomList,
  fetchTrainerList,
} from "../../actions/ui";
import { setEventFilter } from "../../actions/planner";
import FormButton from "../../components/UI/FormButton";
import {
  renderSelectFieldTwoColumns,
  renderAutosuggestFieldTwoColumns,
  renderFieldTwoColumns,
} from "../../components/UI/Fileds";
import useSuggestions from "../../hooks/common/useSuggestions";
import { faL } from "@fortawesome/free-solid-svg-icons/faL";

const CalendarSearchForm = ({ handleSubmit, submitting }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.planner);
  const { eventFilter } = useSelector((state) => state.planner);
  const { customerList } = useSelector((state) => state.ui);
  const { centerList } = useSelector((state) => state.ui);
  const { roomList } = useSelector((state) => state.ui);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const { trainerList } = useSelector((state) => state.ui);

  const renderSuggestion = (suggestion) => <div>{suggestion.razon_social}</div>;
  const filterFunc = (customer, inputValue, inputLength) =>
    customer.razon_social.toLowerCase().slice(0, inputLength) === inputValue;
  const {
    suggestions,
    getSuggestionValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    inputProps,
  } = useSuggestions(customerList, filterFunc, "razon_social");

  const renderTrainerSuggestion = (suggestion) => <div>{suggestion.label}</div>;

  const filterTrainerFunc = (trainer, inputValue, inputLength) =>
    trainer.label.toLowerCase().slice(0, inputLength) === inputValue;

  const {
    trainerSuggestions,
    getTrainerSuggestionValue,
    onTrainerSuggestionsFetchRequested,
    onTrainerSuggestionsClearRequested,
    trainerInputProps,
  } = useSuggestions(trainerList, filterTrainerFunc, "label");

  const statusList = [
    { id: "1", nombre: "Planificado", color: "#87FD7E" },
    { id: "2", nombre: "Borrador", color: "#D1D3D1" },
    { id: "3", nombre: "Suspendido", color: "#FE7E6F" },
    { id: "4", nombre: "Cancelado", color: "#6F9FFE" },
    { id: "5", nombre: "Finalizado", color: "#FEF962" },
  ];

  const courseTypeList = [
    { id: "FORMACION ABIERTA", nombre: "Formación Abierta" },
    { id: "FORMACION EMPRESAS", nombre: "Formación Empresas" },
    { id: "GEP SERVICES", nombre: "Gep Services" },
  ];

  useEffect(() => {
    const collapsibles = document.querySelectorAll(".collapsible");
    let isExpandable = true;
    if (Object.keys(eventFilter).length < 2) {
      isExpandable = false;
    }
    M.Collapsible.init(collapsibles, { expandable: isExpandable });

    dispatch(fetchCustomerList()).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });
    const filter = {
      activo: 1,
    };
    dispatch(fetchTrainerList(filter)).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });

    dispatch(fetchCenterList()).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });
    dispatch(fetchRoomList()).then((res) => {
      if (res.ok) {
        const selects = document.querySelectorAll("select");
        M.FormSelect.init(selects, {});
      }
    });
    dispatch(initialize("calendarSearchForm", eventFilter));
  }, [dispatch, filter]);

  useEffect(() => {
    if (selectedCenter) {
      const selects = document.querySelectorAll("select");
      M.FormSelect.init(selects, {});
    }
  }, [selectedCenter, dispatch]);

  const handleCenterChange = (event) => {
    const selectedCenterId = event.target.value;
    setSelectedCenter(selectedCenterId);

    // Filter the roomList based on the selected center
    const roomsForSelectedCenter = roomList.filter(
      (room) => room.centro_id === selectedCenterId
    );
    setFilteredRooms(roomsForSelectedCenter);
  };

  const handleTrainerChange = (event) => {
    const selectedTrainerId = event.target.value;
    setSelectedTrainer(selectedTrainerId);
  };
  const handleReset = () => {
    dispatch(initialize("calendarSearchForm", {}));
    dispatch(setEventFilter({}));
  };

  const handleSearch = (values) => {
    const collapsibles = document.getElementsByClassName(
      "collapsible-search-form"
    );
    const instance = M.Collapsible.getInstance(collapsibles[0]);
    instance.close();
    dispatch(setEventFilter(values));
  };

  const renderForm = () => {
    return (
      <form className="search-form" onSubmit={handleSubmit(handleSearch)}>
        <div className="row margin">
          <Field
            type="text"
            name="deal_id_pipedrive"
            id="deal_id_pipedrive"
            label={t("screens-course-list-deal") + " ID"}
            component={renderFieldTwoColumns}
            icon="text_fields"
            key="deal_id_pipedrive"
          />
          <Field
            name="estados"
            id="estados"
            type="select"
            label={t("screens-calendar-select-state")}
            component={renderSelectFieldTwoColumns}
            icon="bookmark"
            key="estados"
            multiple={true}
          >
            <option value="" disabled>
              {t("form-select-choose-one")}
            </option>
            {statusList.map((status) => (
              <option value={status.id} key={status.id}>
                {status.nombre}
              </option>
            ))}
          </Field>
        </div>

        <div className="row margin">
          <Field
            name="gep_tipo_formacion"
            id="gep_tipo_formacion"
            type="select-multiple"
            label={t("screens-course-list-gep_tipo_formacion")}
            component={renderSelectFieldTwoColumns}
            icon="bookmark"
            key="gep_tipo_formacion"
          >
            <option value="" disabled>
              {t("form-select-choose-one")}
            </option>
            {courseTypeList.map((courseType) => (
              <option value={courseType.id} key={courseType.id}>
                {courseType.nombre}
              </option>
            ))}
          </Field>
          <Field
            name="centro_id"
            id="centro_id"
            type="select"
            label={t("screens-calendar-select-center")}
            component={renderSelectFieldTwoColumns}
            icon="location_city"
            key="centro_id"
            onChange={handleCenterChange}
          >
            <option value="" disabled>
              {t("form-select-choose-one")}
            </option>
            {centerList.map((center) => (
              <option value={center.id} key={center.id}>
                {center.centro}
              </option>
            ))}
          </Field>

          {/* { <Field
            name="sala_id"
            id="sala_id"
            type="select"
            label={t("screens-planner-list-room")}
            component={renderSelectFieldTwoColumns}
            icon="room"
            key="sala_id"
          >
            <option value="" disabled>
              {t("form-select-choose-one")}
            </option>
            {filteredRooms.map((room) => (
              <option value={room.id} key={room.id}>
                {room.nombre}
              </option>
            ))}
          </Field> } */}
        </div>
        <Field
          name="trainer_id"
          id="trainer_id"
          type="select"
          label={t("Formador")}
          component={renderSelectFieldTwoColumns}
          icon="people"
          key="trainer_id"
          onChange={handleTrainerChange}
          multiple={true}
        >
          <option value="" disabled>
            {t("form-select-choose-one")}
          </option>
          {trainerList
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((trainer) => (
              <option value={trainer.id} key={trainer.id}>
                {trainer.label}
              </option>
            ))}
        </Field>
        <div className="row margin">
          <Field
            value
            type="text"
            name="customer_name_pipedrive"
            key="customer_id"
            id="customer_id"
            label={t("screens-course-customer")}
            icon="person"
            //component={renderAutosuggestFieldTwoColumns}
            component={renderFieldTwoColumns}
            //suggestions={suggestions}
            //getSuggestionValue={getSuggestionValue}
            //onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            //onSuggestionsClearRequested={onSuggestionsClearRequested}
            inputProps={inputProps}
            //renderSuggestion={renderSuggestion}
            style={{ marginLeft: "40px" }}
          />

          {/* <Field
            name="titulo"
            id="titulo"
            type="text"
            label={t("screens-course-list-titulo")}
            component={renderFieldTwoColumns}
            icon="title"
            key="titulo"
          /> */}
        </div>
        <div className="row">
          <div className="input-field col s12">
            <FormButton
              type="submit"
              title={t("search-form-button-title")}
              disabled={submitting}
            />

            <FormButton
              type="reset"
              title={t("reset-form-button-title")}
              disabled={submitting}
              onClick={handleReset}
            />
          </div>
        </div>
      </form>
    );
  };

  return (
    <ul className="collapsible popout collapsible-search-form">
      <li>
        <div className="collapsible-header">
          <i className="material-icons">tune</i> {t("filter-title")}
        </div>
        <div className="collapsible-body" >{renderForm()}</div>
      </li>
    </ul>
  );
};

export default reduxForm({
  form: "calendarSearchForm",
  destroyOnUnmount: false,
})(CalendarSearchForm);
