import React, { useState, useEffect, useMemo } from "react";
import { Form, Modal, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CommentListPanel from "./CommentListPanel";
import CommentPanel from "./CommentPanel";

import { showError } from "../../../helpers/messages";

const CommentsTimelineModal = ({
  isOpen,
  onClose,
  comments,
  onSubmit,
  saveCommentToDB,
  onDeleted,
  author,
  sessionIndex,
}) => {
  const [form] = Form.useForm();
  const [isInputVisible, setInputVisible] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [editingComment, setEditingComment] = useState(""); // New state variable
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setInputVisible(false);
      setCommentText("");
      setEditingComment(null); // Reset editing comment when modal is closed
    }
  }, [isOpen, form]);

  const validateAndSubmit = async () => {
    try {
      //setInputVisible(true);
      const newComment = {
        id: editingComment ? editingComment.id : uuidv4(), // Use existing id if editing, else generate new id
        text: commentText,
        user: { author },
        date: new Date(),
      };

      if (newComment.text.trim() === "") {
        showError("Error", "No puede guardar un comentario vacío.");
      } else {
        onSubmit(newComment, sessionIndex);
        setInputVisible(false);
        setCommentText("");
        setEditingComment(null); // Reset editing comment after submission
      }
    } catch (error) {
      console.error("Failed to save comment:", error);
    }
  };

  // const sortedComments = comments.sort(
  //   (a, b) => new Date(b.date) - new Date(a.date)
  // );

  const sortedComments = useMemo(() => {
    if (Array.isArray(comments) && comments.length > 0) {
      return comments.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    }
    return []; // Return an empty array if comments is not valid
  }, [comments])

  return (
    <Modal
      title={`Sesión Número: ${sessionIndex + 1}`}
      visible={isOpen}
      onCancel={onClose}
      footer={[]}
      bodyStyle={{ maxHeight: "500px", overflow: "auto" }}
      width={window.innerWidth > 768 ? "50%" : "90%"}
    >
      {isInputVisible ? (
        <CommentPanel
          form={form}
          commentText={commentText}
          setCommentText={setCommentText}
          validateAndSubmit={validateAndSubmit}
          setInputVisible={setInputVisible}
          isEditing={!!editingComment} // isEditing is true if editingComment is not null
          initialCommentText={editingComment ? editingComment.text : ""} // Use text of editing comment if editing, else use initial text for new comment
        />
      ) : (
        <Button
          type="primary"
          icon={<EditOutlined />}
          style={{ marginBottom: "20px" }}
          onClick={() => {
            form.resetFields();
            setInputVisible(true);
            setEditingComment(null); // Reset editing comment when creating new comment
          }}
        >
          Nuevo Comentario
        </Button>
      )}
      <CommentListPanel
        sortedComments={sortedComments}
        onDeleteComment={onDeleted}
        sessionIndex={sessionIndex}
        onEditComment={(comment) => {
          setCommentText(comment.text);
          setEditingComment(comment);
          comment.date = new Date();
          comment.user = { author };
          onSubmit(comment, sessionIndex);
        }}
        setInputVisible={setInputVisible}
      />
    </Modal>
  );
};

export default CommentsTimelineModal;
