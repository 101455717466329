import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, List, message, Modal, Select, Spin, Upload, Typography } from 'antd';
import { DownloadOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';

import {
  fetchCourseDocuments,
  sendCourseDocument,
  deleteCourseDocument,
} from "../../actions/courseDocument";
import { sendDocument, download } from '../../actions/document';

const { Title } = Typography;

const AttachmentManager = ({ open, onClose, object, objectType, toolbarButtons }) => {

  const [attachments, setAttachments] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [documentsLoading, setDocumentsLoading] = useState(false);


  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      setDocumentsLoading(true);
      fetchAttachments();
    }
  }, [open, object]);

  const fetchAttachments = async () => {

    const filter = { curso_id: object.id };
    setDocumentsLoading(true);
    const res = await dispatch(fetchCourseDocuments(filter));
    if (res.ok) {
      const attachments = res.list.map((item) => {
        return {
          id: item.id,
          name: item.documento.nombre_original,
          documento_id: item.documento_id,
          size: item.size,
        };
      });
      setAttachments(attachments);
      setDocumentsLoading(false);
    }

  };

  const resetAttachmentState = () => {
    setAttachments([]);
    setSelectedAttachment(null);
  };

  const handlePopupClose = () => {
    resetAttachmentState();
    onClose();
  };

  const handleDownload = (document) => {
    const documentDetails = {
      'id': document.documento_id,
      'nombre_original': document.name
    }
    dispatch(download(documentDetails));
  };

  const handleSendCourseDocument = ({ curso_id, documento_id, es_doc_formacion }) => {
    if (!curso_id) {
      message.error('No has seleccionado un curso');
    } else if (!documento_id) {
      message.error('No has seleccionado un documento');
    } else {
      setDocumentsLoading(true);
      dispatch(sendCourseDocument({ DocumentosCursos: { curso_id, documento_id, es_doc_formacion } }))
        .then((res) => {
          setDocumentsLoading(false);
          if (res.ok) {
            message.success('Documento asociado con éxito al curso');
            fetchAttachments();
          } else {
            message.error(res.message || 'Error al asociar el documento al curso');
          }
        });
    }
  };

  const handleUploadChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} fichero subido con éxito.`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} Ha habido un error al subir el fichero.`);
    }
  };

  const beforeUpload = async (file) => {
    const values = {
      nombre_original: file,
      descripcion: file.name,
    };

    setDocumentsLoading(true);
    const subirAFomacion = await new Promise((resolve) => {
      let loading = false;

      const updateModal = (modal, loading) => {
        modal.update({
          okButtonProps: { loading: loading, disabled: loading },
          cancelButtonProps: { disabled: loading },
        });
      };

      const modal = Modal.confirm({
        title: 'Confirmación de Asignación',
        content: 'Por defecto, el fichero se adjunta al grupo seleccionado. ¿Desea asignarlo a la formación completa?',
        okText: 'Asignar',
        cancelText: 'Cancelar',
        onOk() {
          loading = true;
          updateModal(modal, loading);

          setTimeout(() => {
            loading = false;
            resolve(1);

          }, 2000);
        },
        onCancel() {
          resolve(0);
        },
      });
    });

    try {
      const res = await dispatch(sendDocument(values)); // Ensure this is an async operation

      setDocumentsLoading(false);

      if (res.ok) {
        const documentoId = res.documentoId;
        if (documentoId) {
          handleSendCourseDocument({ curso_id: object.id, documento_id: documentoId, es_doc_formacion: subirAFomacion });
        } else {
          message.error('No se pudo obtener el ID del documento subido');
        }
      } else {
        message.error(res.message || 'Error al enviar el documento');
      }
      await fetchAttachments();
    } catch (error) {
      setDocumentsLoading(false);
      message.error('An error occurred while sending the document');
    }
    return false;
  };

  const handleDelete = async ({ itemId, itemName }) => {
    Modal.confirm({
      title: '¿Estás seguro?',
      content: `Esta acción eliminará el fichero "${itemName}" permanentemente y no se puede deshacer.`,
      okText: 'Sí, eliminar',
      okType: 'danger',
      cancelText: 'No, cancelar',
      onOk: () => {
        (async () => {

          const res = await dispatch(deleteCourseDocument(itemId));
          if (res.ok) {
            await fetchAttachments();
            message.success('Fichero eliminado con éxito');
          } else {
            message.error(res.message || 'Error al eliminar el fichero');
          }
          console.log(`Delete item with id: ${itemId}`);
        })();
      },
      onCancel() {
        console.log('Cancelada la eliminación del fichero');
      },
    });
  };

  return (
    <Modal
      title={
        <Title level={4} style={{ textAlign: 'center' }}>
          {`${object?.courseTitle?.trim() ?? 'Curso'} - ${object?.courseStudent?.trim() ?? 'Estudiante'}`}
        </Title>
      }
      open={open}
      onCancel={handlePopupClose}
      footer={null}
    >
      <Spin spinning={documentsLoading}>
        <Upload.Dragger
          beforeUpload={beforeUpload}
          onChange={handleUploadChange}
          showUploadList={false}
          style={{ padding: '20px', borderRadius: '8px', borderColor: '#1890ff', backgroundColor: '#f0f2f5' }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ color: '#1890ff' }} />
          </p>
          <p className="ant-upload-text" style={{ color: '#1890ff' }}>Haz clic o arrastra archivos aquí para subir</p>
        </Upload.Dragger>
      </Spin>
      <div style={{ position: 'relative', maxHeight: '34vh', overflowY: 'auto', marginTop: '20px' }}>

        <div style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff', borderBottom: '1px solid #f0f0f5' }}>
          <Typography.Title level={5} style={{ textAlign: 'center', margin: 0 }}>Ficheros adjuntos</Typography.Title>
        </div>

        <List
          dataSource={attachments}
          renderItem={item => (
            <List.Item
              key={item.id}
              style={{ borderBottom: '1px solid #f0f0f5', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >

              {/* <Typography.Text>{item.name}</Typography.Text>
              
              <Button
                type="text"
                icon={<DownloadOutlined style={{ color: 'red' }} />}
                onClick={() => handleDownload(item)}
                aria-label={`Delete ${item.name}`}
              /> */}
              <Typography.Text>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownload(item);
                  }}                  
                >
                  {item.name}
                </a>
              </Typography.Text>

              <Button
                type="text"
                icon={<DeleteOutlined style={{ color: 'red' }} />}
                onClick={() => handleDelete({ itemId: item.id, itemName: item.name })}
                aria-label={`Delete ${item.name}`}
              />

            </List.Item>
          )}

          style={{ width: '100%' }}
        />
      </div>
    </Modal>
  );

};

export default AttachmentManager;