import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import M from 'materialize-css';

import { i18nDatepickerEs } from '../../i18n/locales/es/datepicker_i18n';
import { i18nDatepickerPt } from '../../i18n/locales/pt/datepicker_i18n';

import { datepickerToDate } from '../../helpers/converter';

const DatePicker = ({ form, name, value, label, visible, render }) => {

    const dispatch = useDispatch();

    const lang = useSelector(state => state.lang);
    
    useEffect(() => {
        const datePickerOptions = {
            firstDay: (lang === 'es') ? 1 : 0,
            format: 'dd/mm/yyyy',
            autoClose: true,
            i18n: (lang === 'es') ? i18nDatepickerEs : i18nDatepickerPt,
            onSelect: (newDate) => { handleChange(newDate); },
            onOpen: () => { handleOpen(); }
        };
        if (value) {
            const parts = value.split('-');
            M.Datepicker.init(document.getElementById(`datepicker_${name}`), { 
                ...datePickerOptions, 
                defaultDate: new Date(
                    parts[0],
                    parseInt(parts[1], 10) - 1,
                    parts[2]
                ),
                setDefaultDate: true,
            });
        } else {
            M.Datepicker.init(document.getElementById(
                `datepicker_${name}`), datePickerOptions
            );
        }
    }, []);

    const handleChange = (newDate) => {
        dispatch(change(form, name, datepickerToDate(newDate)));
    };

    const handleOpen = () => {
        dispatch(change(form, name, ''));
    };

    return (
        <>
            <Field type="text" className="datepicker" 
                name={ `datepicker_${name}` } 
                id={ `datepicker_${name}` } 
                label={ label } 
                component={ render } 
                icon="event" 
                key={ `datepicker_${name}` }
                visible={ visible }
            />
            <Field type="hidden" name={ name } id={ name }
                value= { value } component={ render } visible={ false }
            />
        </>
    );
};

export default DatePicker;
