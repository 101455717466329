import React from 'react';

//-----------------------
const PageTitle = ({ title, children }) => {

    return (
        <div className="row">
            <div className="pt-1 pb-0" id="breadcrumbs-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <h1 className="breadcrumbs-title">
                                <span>{ title }</span>
                                { children }
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default PageTitle;