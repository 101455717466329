import TYPES from './types';
import { apiClientWithToken, downloadExcelFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchBooks = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/biblioteca-libros', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setBooks(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setBooks = (data) => ({
   type: TYPES.SET_BOOKS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/biblioteca-libros/excel', 
                'libros-biblioteca.xlsx',
                filter, {
                    autor: 'BibliotecaLibros',
                    titulo: 'BibliotecaLibros',
                    editorial: 'BibliotecaLibros',
                    biblioteca_seccion_id: 'BibliotecaLibros'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'libros-biblioteca.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectBook = (data) => ({
    type: TYPES.SELECT_BOOK, payload: data
});

export const setBookFilter = (filter) => ({
    type: TYPES.SET_BOOK_FILTER, payload: filter
});

export const deleteBook = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/biblioteca-libros/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendBook = (book) => {
    return async () => {
        try {
            let res;

            if (book.BibliotecaLibros.id) {
                res = await apiClientWithToken().put(
                    `/biblioteca-libros/${book.BibliotecaLibros.id}`,
                    book
                );
            } else {
                res = await apiClientWithToken().post(
                    '/biblioteca-libros', book
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};