import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, change, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Spacer from '../../../components/UI/Spacer';
import FormButton from '../../../components/UI/FormButton';
import ScreensStudentSelector from '../../Student/Selector';
import DatePicker from '../../../components/UI/DatePicker';

import { 
    renderFieldOneColumn ,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns
} from '../../../components/UI/Fileds';

import { sendStudentDegree } from '../../../actions/studentDegree';
import { startLoading, endLoading } from '../../../actions/ui';
import { fetchStudentList } from '../../../actions/student';
import { fetchDiplomaTipoList } from '../../../actions/ui';

import { showError, showConfirmation } from '../../../helpers/messages';
import { dateToDatepicker } from '../../../helpers/converter';

const ScreensDegreeStudentForm = ({ submitting, handleSubmit, dirty }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const history = useHistory();

    const { selected } = useSelector(state => state.studentDegree);

    const [student, setStudent] = useState({ id: '', label: t('selector-student-no-selected') });

    const { diplomaTipoList } = useSelector(state => state.ui);
    
    useEffect(() => {
        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});

        dispatch(fetchDiplomaTipoList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            dispatch(initialize('studentDegreeForm', { 
                id: selected.id,
                fecha_1: selected.fecha_1,
                alumno_id: selected.alumno_id,
                diplomas_tipo_id: selected.diplomas_tipo_id,
                datepicker_fecha_1: dateToDatepicker(selected.fecha_1)
             }));
            setStudent({
                ...selected.alumno,
                label: `${selected.alumno.apellidos}, ${selected.alumno.nombre} (${selected.alumno.email})`
            });
        } else {
            dispatch(initialize('studentDegreeForm', {
                fecha_1: '',
                datepicker_fecha_1: '',
                alumno_id: '',
                diplomas_tipo_id: ''
            }));
        }
    }, [dispatch]);

    const handleSend = (values) => {
        if (!values.alumno_id) {
            showError(t('message-title-error'), t('message-error-no-student-selected'));
        } else {
            dispatch(startLoading());
            dispatch(
                sendStudentDegree({AlumnosTitulaciones: {
                    id: values.id,
                    fecha_1: values.fecha_1,
                    alumno_id: values.alumno_id,
                    diplomas_tipo_id: values.diplomas_tipo_id
                }})
            ).then((res) => {
                dispatch(endLoading());
                if (res.ok) {
                    history.goBack();
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });    
        }
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const handleSearchStudent = () => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectStudent = (student) => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setStudent(student);
        dispatch(change('studentDegreeForm', 'alumno_id', student.id));
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field type="hidden" name="id" id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <Field type="hidden" name="alumno_id" id="alumno_id"
                        value= { student.id }
                        component={ renderFieldOneColumn }
                    />
                    <label className="ml-1">{ t('screens-student-degree-alumno') }</label>
                    <p>
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-alumno"
                            onClick={ handleSearchStudent }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ student.label || t('selector-student-no-selected') }</span>
                    </p>
                </div>

                <Spacer height="3" />

                <div className="row margin">
                    <Field name="diplomas_tipo_id" id="diplomas_tipo_id" 
                        label={ t('screens-diploma-list-tipo') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="diplomas_tipo_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            diplomaTipoList.map(tipo => (
                                <option value={ tipo.id } key={ tipo.id }>{ tipo.nombre }</option>
                            ))
                        }
                    </Field>

                    <DatePicker form="studentDegreeForm" name="fecha_1" 
                        value={(selected) ? selected.fecha_1 : ''}
                        label={ t('screens-student-degree-list-fecha_1') }
                        render={ renderFieldTwoColumns }
                    />
                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-student-degree-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <div id="modal-selector-alumno" className="modal">
                <div className="modal-content">
                    <ScreensStudentSelector
                        onSelect={ handleSelectStudent }
                        fetchStudentList={ fetchStudentList }
                    />
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.alumno_id) {
        errors.alumno_id = i18n.t('validator-error-alumno');
    }

    if (!values.diplomas_tipo_id) {
        errors.diplomas_tipo_id = i18n.t('validator-error-tipo');
    }

    if (!values.fecha_1) {
        errors.datepicker_fecha_1 = i18n.t('validator-error-fecha');
    }
    return errors;
};

export default reduxForm({
    form: 'studentDegreeForm',
    validate: validate,
    destroyOnUnmount: false
})(ScreensDegreeStudentForm);
