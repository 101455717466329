import TYPES from '../actions/types';

//-------------------------
const INITIAL_STATE = 'es';


//----------------------------------------------------------
export const languageReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case TYPES.SET_LANGUAGE:
            return action.payload || state;
    
        default:
            return state;
    }
};
