import React from "react";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { makeStyles } from "@material-ui/core/styles";
import { de } from "date-fns/esm/locale";

// Define custom styles
const useStyles = makeStyles((theme) => ({
  searchField: {
    "& .MuiToolbar-root": {
      backgroundColor: "#f0f0f0",
    },
  },
}));

const CustomMaterialTable = ({
  dealId,
  data,
  columns,
  actions,
  options,
  groupingEnabled = true,
}) => {
  const classes = useStyles();

  // Remove action by key and rule
  function removeActionByRuleAndKey(actionKey, rule) {
    return actions?.filter((action) => !(action.key === actionKey && rule()));
  }

  const rule = () => dealId !== 0o1;

  const modifiedActions = removeActionByRuleAndKey("viewDealsAsociados", rule);

  const getDefaultGroupOrder = (dealId, field) => {
    const mapping = {
      0o1: { meses: 1 },
      default: { name: 1 },
    };

    const dealMapping = mapping[dealId] || mapping["default"];

    return dealMapping[field];
  };

  const applyDefaultGroupOrder = (columns, dealId) =>
    columns.map((column) => ({
      ...column,
      defaultGroupOrder: getDefaultGroupOrder(dealId, column.field),
    }));

  if (groupingEnabled) {
    columns = applyDefaultGroupOrder(columns, dealId);
  }
  const defaultOptions = {
    rowStyle: (rowData, index) => ({
      backgroundColor: index % 2 === 0 ? "#f6f6f6" : "#fff",
    }),
    headerStyle: {
      backgroundColor: "#f0f0f0",
      color: "#202020",
      fontWeight: "bold",
    },
    tableStyle: {
      border: "2px solid #ccc",
    },
    actionsColumnIndex: -1, // Ensure actions are on the right
  };

  const mergedOptions = { ...defaultOptions, ...options };

  const statusColors = [
    {
      estado: "1",
      color: "#87FD7E",
      label: "plannerQuantity",
      spanish: "Planificadas",
    },
    {
      estado: "2",
      color: "#D1D3D1",
      label: "draftQuantity",
      spanish: "Reservadas",
    },
    {
      estado: "3",
      color: "#FEF962",
      label: "suspendedQuantity",
      spanish: "Suspendidas",
    },
    {
      estado: "4",
      color: "#FE7E6F",
      label: "canceledQuantity",
      spanish: "Canceladas",
    },
    {
      estado: "5",
      color: "#6F9FFE",
      label: "finishedQuantity",
      spanish: "Finalizadas",
    },
  ];

  const getEstadoColorAndLabel = (estado) => {
    const estadoObj = statusColors.find((e) => e.label === estado.toString());
    return estadoObj
      ? { color: estadoObj.color, label: estadoObj.spanish }
      : { color: "transparent", label: "" };
  };

  const renderStatusBubble = (cantidad, estado) => {
    if (cantidad > 0) {
      const { color, label } = getEstadoColorAndLabel(estado);
      return (
        <div
          style={{ display: "flex", alignItems: "center" }}
          title={`${label}: ${cantidad}`}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: color,
              borderRadius: "50%",
              width: "25px",
              height: "25px",
              margin: "auto",
              fontWeight: "bold",
              fontSize: "0.8vw",
              border: "2px solid #000",
            }}
          >
            {cantidad}
          </span>

          {/* <span style={{justifyContent:"center", fontSize: "0.8vw",fontWeight: "bold", margin: "auto", }}>{label}</span> */}
        </div>
      );
    } else if (cantidad === 0) {
      // If cantidad is zero, return the number zero without the bubble
      return <div>0</div>;
    }
    return null; // Return null if cantidad is not greater than zero
  };

  const modifiedColumns = columns.map((column) => {
    const matchingStatusColor = statusColors.find(
      (sc) => sc.label === column.field
    );
    if (matchingStatusColor) {
      return {
        ...column,
        render: (rowData) =>
          renderStatusBubble(rowData[column.field], column.field),
      };
    }
    return column;
  });

  return (
    <MaterialTable
      title=""
      columns={modifiedColumns}
      actions={modifiedActions}
      data={data}
      components={{
        Toolbar: (props) => (
          <div className={classes.searchField}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      options={mergedOptions}
    />
  );
};

export default CustomMaterialTable;
