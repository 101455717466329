import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, change, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../../components/UI/PageTitle';
import PageContainer from '../../../components/UI/PageContainer';
import ContentContainer from '../../../components/UI/ContentContainer';
import Spacer from '../../../components/UI/Spacer';
import FormButton from '../../../components/UI/FormButton';
import ScreensStudentSelector from '../../Student/Selector';
import ScreensTaskSelector from '../../Task/Selector';

import { renderFieldOneColumn } from '../../../components/UI/Fileds';

import { sendStudentTask } from '../../../actions/studentTask';
import { startLoading, endLoading } from '../../../actions/ui';
import { fetchStudentList } from '../../../actions/student';
import { fetchTaskList } from '../../../actions/task';

import { showError, showConfirmation } from '../../../helpers/messages';

const ScreensTaskStudentForm = ({ submitting, handleSubmit, dirty }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const history = useHistory();

    const [student, setStudent] = useState({ id: '', label: t('selector-student-no-selected') });
    
    const [task, setTask] = useState({ id: '', label: t('selector-task-no-selected') });

    useEffect(() => {
        const elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
        
        dispatch(initialize('studentTaskForm', { alumno_id: '', tarea_id: '' }));
    }, [dispatch]);

    const handleSend = (values) => {
        if (!values.alumno_id) {
            showError(t('message-title-error'), t('message-error-no-student-selected'));
        } else if (!values.tarea_id) {
            showError(t('message-title-error'), t('message-error-no-task-selected'));
        } else {
            dispatch(startLoading());
            dispatch(
                sendStudentTask({TareasAlumnos: values})
            ).then((res) => {
                dispatch(endLoading());
                if (res.ok) {
                    history.goBack();
                } else {
                    showError(t('message-title-error'), t(res.message));
                }
            });    
        }
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const handleSearchStudent = () => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSearchTask = () => {
        const elem = document.getElementById('modal-selector-tarea');
        const instance = M.Modal.getInstance(elem);
        instance.open();
    };

    const handleSelectStudent = (student) => {
        const elem = document.getElementById('modal-selector-alumno');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setStudent(student);
        dispatch(change('studentTaskForm', 'alumno_id', student.id));
    };

    const handleSelectTask = (task) => {
        const elem = document.getElementById('modal-selector-tarea');
        const instance = M.Modal.getInstance(elem);
        instance.close();

        setTask(task);
        dispatch(change('studentTaskForm', 'tarea_id', task.id));
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                <div className="row margin">
                    <Field type="hidden" name="alumno_id" id="alumno_id"
                        value= { student.id }
                        component={ renderFieldOneColumn }
                    />
                    <label className="ml-1">{ t('screens-student-task-document-alumno') }</label>
                    <p>
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-alumno"
                            onClick={ handleSearchStudent }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ student.label || t('selector-student-no-selected') }</span>
                    </p>
                </div>

                <div className="row margin">
                    <Field type="hidden" name="tarea_id" id="tarea_id"
                        value= { task.id }
                        component={ renderFieldOneColumn }
                    />
                    <label className="ml-1">{ t('screens-student-task-document-tarea') }</label>
                    <p>
                        <button 
                            className="btn modal-trigger ml-1" 
                            data-target="modal-selector-tarea"
                            onClick={ handleSearchTask }
                        >
                            <i className="material-icons">search</i>
                        </button>
                        <span style={{marginLeft: '1em'}}>{ task.titulo || t('selector-task-no-selected') }</span>
                    </p>

                </div>

                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-student-task-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <div id="modal-selector-alumno" className="modal">
                <div className="modal-content">
                    <ScreensStudentSelector
                        onSelect={ handleSelectStudent }
                        fetchStudentList={ fetchStudentList }
                    />
                </div>
            </div>

            <div id="modal-selector-tarea" className="modal">
                <div className="modal-content">
                    <ScreensTaskSelector
                        onSelect={ handleSelectTask }
                        fetchTaskList={ fetchTaskList }
                    />
                </div>
            </div>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.alumno_id) {
        errors.alumno_id = i18n.t('validator-error-alumno');
    }

    if (!values.tarea_id) {
        errors.tarea_id = i18n.t('validator-error-tarea');
    }

    return errors;
};

export default reduxForm({
    form: 'studentTaskForm',
    validate: validate,
    destroyOnUnmount: false, //true
})(ScreensTaskStudentForm);