import React from "react";
import CustomMaterialTable from "../../../components/UI/CustomMaterialTable";
import {
  PictureAsPdf,
  InsertDriveFile,
  MusicNote,
  Movie,
} from "@material-ui/icons";
import { act } from "react-dom/test-utils";

// Function to determine the icon based on file extension
const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  switch (extension) {

    case "pdf":
      return <PictureAsPdf />;
    case "mp3":
    case "wav":
      return <MusicNote />;
    case "mp4":
    case "avi":
      return <Movie />;
    default:
      return <InsertDriveFile />;
  }
};

const AttachmentListView = ({ attachments, actions }) => {
  const columns = [

    { title: "Fichero", field: "name", width: "80%", cellStyle: {
      color: '#2196f3'
     } },
    { title: "Última modificación", field: "modifiedDate", width: "15%" },
    { title: "Tamaño", field: "size" , width: "10%" },
    {
      title: "Tipo",
      field: "icon",
      render: (rowData) => getFileIcon(rowData.name),
      sorting: false,
      searchable: false,
      width: "10%"
    },
    // Add more attributes here as needed
  ];

  const data = attachments?.map((attachment) => ({
    ...attachment,
    icon: getFileIcon(attachment.name),
  }));

  return (
    <CustomMaterialTable
      title="Attachments"
      columns={columns}
      data={data}
      actions={actions}
      options={{
        search: true,
        paging: true,
        filtering: false,
        exportButton: false,
        grouping: false,
      }}
      groupingEnabled={false}
    />
  );
};

export default AttachmentListView;
