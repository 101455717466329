import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';
import M from 'materialize-css';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';

import { 
    renderFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns
} from '../../components/UI/Fileds';

import { sendBook } from '../../actions/book';
import { startLoading, endLoading, fetchBookSectionList } from '../../actions/ui';

import { showError, showConfirmation } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const ScreensBookForm = ({ submitting, handleSubmit, dirty }) => {
    
    const { t } = useTranslation();

    const { selected } = useSelector(state => state.book);

    const { bookSectionList } = useSelector(state => state.ui);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchBookSectionList()).then((res) => {
            if (res.ok) {
                const selects = document.querySelectorAll('select');
                M.FormSelect.init(selects, {});
            }
        });

        if (selected) {
            dispatch(initialize('bookForm', {
                id: selected.id,
                autor: selected.autor,
                titulo: selected.titulo,
                editorial: selected.editorial,
                biblioteca_seccion_id: selected.biblioteca_seccion_id
            }));
        } else {
            dispatch(initialize('bookForm', { 
                autor: '', 
                titulo: '', 
                editorial: '', 
                biblioteca_seccion_id: '' 
            }));
        }
    }, [dispatch, selected]);

    const handleSend = (values) => {
        dispatch(startLoading());

        dispatch(
            sendBook({BibliotecaLibros: {
                ...values,
                autor: stringTrim(values.autor),
                titulo: stringTrim(values.titulo),
                editorial: stringTrim(values.editorial)
            }})
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                } 
            );
        } else {
            history.goBack();
        }        
    };

    const renderForm = () => {
        return (
            <form onSubmit={ handleSubmit(handleSend) }>
                {/* Campos del formulario */}
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden" 
                                    name="id" 
                                    id="id"
                                    component={ renderFieldOneColumn }
                                />
                            )
                            : ''
                    }

                    <Field type="text" name="autor" id="autor" 
                        label={ t('screens-book-list-autor') } 
                        component={ renderFieldTwoColumns } 
                        icon="person_outline" 
                        key="autor"
                    />

                    <Field type="text" name="titulo" id="titulo" 
                        label={ t('screens-book-list-titulo') } 
                        component={ renderFieldTwoColumns } 
                        icon="text_fields" 
                        key="titulo" 
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="editorial" id="editorial" 
                        label={ t('screens-book-list-editorial') } 
                        component={ renderFieldTwoColumns } 
                        icon="business" 
                        key="editorial" 
                    />
                    <Field name="biblioteca_seccion_id" id="biblioteca_seccion_id" 
                        label={ t('screens-book-list-seccion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="bookmark" 
                        key="seccion" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            bookSectionList.map(section => (
                                <option value={ section.id } key={ section.id }>{ section.nombre }</option>
                            ))
                        }
                    </Field>
                </div>

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={ t('form-button-send') }
                            disabled={ submitting }
                        />

                        <FormButton type="reset"
                            title={ t('form-button-cancel') }
                            disabled={ submitting }
                            onClick={ handleCancel }
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>    
            <PageTitle title={ t('screens-book-form-title') } />

            <ContentContainer>
                { renderForm() }
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.autor) {
        errors.autor = i18n.t('validator-error-autor');
    }

    if (!values.titulo) {
        errors.titulo = i18n.t('validator-error-titulo');
    }

    if (!values.editorial) {
        errors.editorial = i18n.t('validator-error-editorial');
    }

    if (!values.biblioteca_seccion_id) {
        errors.biblioteca_seccion_id = i18n.t('validator-error-seccion');
    }

    return errors;
};

export default reduxForm({
    form: 'bookForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensBookForm);