import validator from 'validator';

export const noValidation = (value) => true;

//---------------------------------------------
/*export const isNotEmpty = (value) => {
    
    const valueToValidate = (typeof value === 'string') 
        ? value.trim() 
        : value;

    return !(!valueToValidate);
};*/
export const isNotEmpty = (value) => { return !validator.isEmpty(value) };

export const max4digits = (value) => {
    if (value < 0) {
        return 0;
    } else if(value > 9999) {
        return 9999;
    } else {
        return value;
    }
};

export const max2digits = (value) => {
    if (value < 0) {
        return 0;
    } else if(value > 20) {
        return 20;
    } else {
        return value;
    }
};

export const isEmail = (value) => validator.isEmail(value);