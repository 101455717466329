import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { reducer as reduxForm } from 'redux-form';

import TYPES from '../actions/types';

import { authReducer } from '../reducers/authReducer';
import { uiReducer } from '../reducers/uiReducer';
import { languageReducer } from '../reducers/languageReducer';
import { studentReducer } from '../reducers/studentReducer';
import { courseReducer } from '../reducers/courseReducer';
import { bookSectionReducer } from '../reducers/bookSectionReducer';
import { bookMemberReducer } from '../reducers/bookMemberReducer';
import { bookReducer } from '../reducers/bookReducer';
import { documentReducer } from '../reducers/documentReducer';
import { courseDocumentReducer } from '../reducers/courseDocumentReducer';
import { studentDocumentReducer } from '../reducers/studentDocumentReducer';
import { diplomaReducer } from '../reducers/diplomaReducer';
import { studentDiplomaReducer } from '../reducers/studentDiplomaReducer';
import { taskReducer } from '../reducers/taskReducer';
import { studentTaskReducer } from '../reducers/studentTaskReducer';
import { studentTaskDocumentReducer } from '../reducers/studentTaskDocumentReducer';
import { userReducer } from '../reducers/userReducer';
import { organizerReducer } from '../reducers/organizerReducer';
import { customerReducer } from '../reducers/customerReducer';
import { trainerReducer } from '../reducers/trainerReducer';
import { studentDegreeReducer } from '../reducers/studentDegreeReducer';
import { areaReducer } from '../reducers/areaReducer';
import { studentCourseReducer } from '../reducers/studentCourseReducer';
import { courseStudentReducer } from '../reducers/courseStudentReducer';
import { studentCourseDoneReducer } from '../reducers/studentCourseDoneReducer';
import { pipeDriveReducer } from '../reducers/pipeDriveReducer'
import { plannerReducer } from '../reducers/plannerReducer ';

const reducers = combineReducers({
    form: reduxForm,
    auth: authReducer,
    ui: uiReducer,
    lang: languageReducer,
    student: studentReducer,
    course: courseReducer,
    book: bookReducer,
    bookSection: bookSectionReducer,
    bookMember: bookMemberReducer,
    document: documentReducer,
    courseDocument: courseDocumentReducer,
    studentDocument: studentDocumentReducer,
    diploma: diplomaReducer,
    studentDiploma: studentDiplomaReducer,
    task: taskReducer,
    studentTask: studentTaskReducer,
    studentTaskDocument: studentTaskDocumentReducer,
    user: userReducer,
    organizer: organizerReducer,
    customer: customerReducer,
    trainer: trainerReducer,
    studentDegree: studentDegreeReducer,
    area: areaReducer,
    studentCourse: studentCourseReducer,
    courseStudent: courseStudentReducer,
    studentCourseDone: studentCourseDoneReducer,
    pipeDrive: pipeDriveReducer,
    planner: plannerReducer
});

const rootReducer = (state, action) => {
    if (action.type === TYPES.STORE_CLEAN) {
      state = undefined;
    }
    return reducers(state, action);
};

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    rootReducer, //reducers,
    composeEnhancers(
        applyMiddleware(reduxThunk)
    )
    //{},
    //applyMiddleware(reduxThunk)
);