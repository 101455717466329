import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { showError } from '../../helpers/messages';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import Spacer from '../../components/UI/Spacer';
import SortingHeader from '../../components/UI/SortingHeader';
import PdfButton from '../../components/UI/PdfButton';

import { 
    fetchStudentDiplomas,  
    selectStudentDiploma,
    download
} from '../../actions/studentDiploma';
import { 
    startLoading, 
    endLoading
} from '../../actions/ui';

import { dateToDatepicker } from '../../helpers/converter';

const ScreensStudentAreaDiplomas = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging } = useSelector(state => state.studentDiploma);
    const { sort, direction } = paging;

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudentDiplomas(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging });
    }, [dispatch, fetchData]);

    useEffect(() => {
        dispatch(selectStudentDiploma(null));
    }, [dispatch]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, sort: name, direction: newDirection });
    };

    const handleDownload = (id) => {
        dispatch(download(id));
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={ t('screens-diploma-list-ciudad') }
                            direction={ (sort === 'ciudad') ? direction : '' }
                            onClick={ () => handleSort('ciudad') }
                        />
                        <SortingHeader title={ t('screens-diploma-list-fecha') }
                            direction={ (sort === 'fecha') ? direction : '' }
                            onClick={ () => handleSort('fecha') }
                        />
                        <th>
                            { t('screens-diploma-list-tipo') }
                        </th>
                        <th>{ t('screens-list-acciones') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={ item.id }>
                                <td>{ item.diploma.ciudad }</td>
                                <td>{ dateToDatepicker(item.diploma.fecha) }</td>
                                <td>{ item.diploma.diplomas_tipo.nombre }</td>
                                <td>
                                    {
                                        (item.diploma.tiene_documento)
                                        ? <PdfButton 
                                            onPressButton={ () => handleDownload(item.id) } />
                                        : ''
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };
    
    return (
        <PageContainer>
            <PageTitle title={ t('screens-student-diploma-list-title') } />

            <ContentContainer>
                { renderTable() }
            </ContentContainer>

            <Pagination paging={ paging } onPaginate={ handlePaginate } />

            <Spacer height="5" />
        </PageContainer>
    );

};

export default ScreensStudentAreaDiplomas;