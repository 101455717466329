import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../helpers/messages';

import { dateToDatepicker } from '../../helpers/converter';

import CourseSearchForm from '../Course/SearchForm';
import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import ExcelButton from '../../components/UI/ExcelButton';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import SortingHeader from '../../components/UI/SortingHeader';
import FloatingButtonAction from '../../components/UI/FloatingButtonAction';
import PdfButton from '../../components/UI/PdfButton';

import {
    fetchCourses,
    downloadExcel,
    selectCourse,
    deleteCourse,
    downloadCertificates,
    downloadDealFilesAttached,
    generateCertificates
} from '../../actions/course';
import {
    startLoading,
    endLoading
} from '../../actions/ui';

const ScreensCourseList = ({ titulo, cursos_tipo_id, programados }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, filter } = useSelector(state => state.course);
    const { sort, direction } = paging;

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchCourses(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging, ...filter });
    }, [dispatch, filter, fetchData]);

    useEffect(() => {
        dispatch(selectCourse(null));
    }, [dispatch]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
    };

    const handleEdit = (item) => {
        dispatch(selectCourse(item));
        history.push('/cursos/formulario');
    };

    const handleDelete = (id, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-course-text', { name }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteCourse(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadExcel({
                titulo: titulo,
                cursos_tipo_id: cursos_tipo_id,
                programados: programados
            })
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleAdd = () => {
        dispatch(selectCourse(null));
        history.push('/cursos/formulario');
    };

    const handleGenerateCertificates = (item) => {
        dispatch(startLoading());
        dispatch(
            generateCertificates(item.id)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            } else {
                showSuccess(t('message-title-success'), t(res.message));
                fetchData({ ...paging, ...filter, page: 1 });
            }
        });
    };

    const handleDownload = (item) => {
        dispatch(downloadCertificates(item.id));
    };

    const handleDownloadFilesApi = (id_fichero, apiFileName) => {
        dispatch(downloadDealFilesAttached(id_fichero, apiFileName));
    };

    const handleLoadIncriptions = (item) => {
        dispatch(selectCourse(item));
        if (item.cursos_tipo.nombre === 'Presencial' || item.cursos_tipo.nombre === 'Taller') {
            history.push('/cursos/carga-inscripciones-presencial');
        } else if (item.cursos_tipo.nombre === 'Videoconferencia') {
            history.push('/cursos/carga-inscripciones-video');
        }
    };

    const handleViewIncriptions = (item) => {
        dispatch(selectCourse(item));
        history.push('/cursos/alumnos-inscritos');
    };

    const handleView = (item) => {
        dispatch(selectCourse(item));
        history.push('/cursos/detalle');
    }

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>{t("Cliente")}</th>
                        <SortingHeader
                            title={t("screens-course-list-titulo")}
                            direction={sort === "titulo" ? direction : ""}
                            onClick={() => handleSort("titulo")}
                        />

                        <th>{t("screens-course-list-student_group_code")}</th>
                        <th>{t("screens-course-list-tipo")}</th>

                        <SortingHeader
                            title={t("screens-course-list-fecha_inicio")}
                            direction={sort === "fecha_inicio" ? direction : ""}
                            onClick={() => handleSort("fecha_inicio")}
                        />
                        <th>{t("screens-list-acciones")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <a
                                        href={item.customers}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {(item.customer != null) ?  item.customer.nombre_comercial : 'Sin Asignar'}
                                        

                                    </a>
                                </td>
                                <td><a
                                    href={item.url_deal_pipedrive}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {item.titulo}
                                </a></td>
                                <td>{item.codigo_grupo_alumnos}</td>
                                <td>{item.gep_tipo_formacion}</td>
                                <td>{item.fecha_inicio}</td>
                                <td>
                                    <Can perform="Cursos:edit" yes={() => (
                                        <ActionButton icon="edit"
                                            tooltip={t('screens-list-acciones-edit')}
                                            extraClass="btn-floating btn-small waves-effect waves-light green darken-3" onClick={() => handleEdit(item)} />
                                    )} data={item} />
                                    <Can perform="Alumnos:view" yes={() => (
                                        <ActionButton icon="search"
                                            tooltip={t('screens-list-acciones-view')}
                                            extraClass="btn-floating btn-small waves-effect waves-light cyan darken-2 ml-2"
                                            onClick={() => handleView(item)} />
                                    )} data={item} />
                                    <Can perform="Cursos:delete" yes={() => (
                                        <ActionButton icon="delete_forever"
                                            tooltip={t('screens-list-acciones-delete')}
                                            extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-2"
                                            onClick={() => handleDelete(item.id, item.titulo)} />
                                    )} data={item} />
                                    {
                                        ((item.cursos_tipo.nombre === 'Presencial' || item.cursos_tipo.nombre === 'Taller') && !item.certificado_generado)
                                            ? <Can perform="Cursos:generarCertificados" yes={() => (
                                                <ActionButton icon="check"
                                                    tooltip={t('screens-list-acciones-certificados')}
                                                    extraClass="btn-floating btn-small waves-effect waves-light blue darken-2 ml-2"
                                                    onClick={() => handleGenerateCertificates(item)} />
                                            )} />
                                            : ''
                                    }
                                    {
                                        ((item.cursos_tipo.nombre === 'Presencial' || item.cursos_tipo.nombre === 'Taller') && item.certificado_generado)
                                            ? <Can perform="Cursos:descargarCertificados" yes={() => (
                                                <PdfButton
                                                    onPressButton={() => handleDownload(item)} />
                                            )} />
                                            : ''
                                    }
                                    {
                                        ((item.cursos_tipo.nombre === 'Presencial' || item.cursos_tipo.nombre === 'Taller') && item.certificado_generado)
                                            ? <Can perform="Cursos:descargarCertificados" yes={() => (
                                                <PdfButton
                                                    onPressButton={() => handleDownloadFilesApi(1197, 'prueba_descarga_fichero.txt')} />
                                            )} />
                                            : ''
                                    }
                                    {
                                        (item.cursos_tipo.nombre === 'Presencial' || item.cursos_tipo.nombre === 'Taller')
                                            ? <Can perform="Cursos:cargarInscripcionesPresencial" yes={() => (
                                                <ActionButton icon="file_upload"
                                                    tooltip={t('screens-list-carga-inscripciones')}
                                                    extraClass="btn-floating btn-small waves-effect waves-light orange darken-2 ml-2"
                                                    onClick={() => handleLoadIncriptions(item)} />
                                            )} />
                                            : ''
                                    }
                                    <Can perform="Cursos:verInscripciones" yes={() => (
                                        <ActionButton icon="people"
                                            tooltip={t('screens-list-inscripciones')}
                                            extraClass="btn-floating btn-small waves-effect waves-light pink darken-2 ml-2"
                                            onClick={() => handleViewIncriptions(item)} />
                                    )} />
                                    {
                                        (item.cursos_tipo.nombre === 'Videoconferencia')
                                            ? <Can perform="Cursos:cargarInscripcionesVideo" yes={() => (
                                                <ActionButton icon="file_upload"
                                                    tooltip={t('screens-list-carga-inscripciones')}
                                                    extraClass="btn-floating btn-small waves-effect waves-light orange darken-2 ml-2"
                                                    onClick={() => handleLoadIncriptions(item)} />
                                            )} />
                                            : ''
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>

            <PageTitle title={t('screens-course-list-title')}>
                <Can perform="Cursos:excel" yes={() => (
                    <ExcelButton onPressButton={handleExcel} />
                )} />
            </PageTitle>

            <CourseSearchForm />

            <ContentContainer>
                {renderTable()}
            </ContentContainer>

            <Pagination paging={paging} onPaginate={handlePaginate} />

            <Spacer height="5" />

            <Can perform="Cursos:add" yes={() => (
                <FloatingButtonAction icon="add"
                    title={t('screens-list-acciones-add')} action={handleAdd}
                />
            )} />

        </PageContainer>
    );

};

const formSelector = formValueSelector('courseSearchForm');

export default connect(state => ({
    titulo: formSelector(state, 'titulo'),
    cursos_tipo_id: formSelector(state, 'cursos_tipo_id'),
    programados: formSelector(state, 'programados')
}))(ScreensCourseList);