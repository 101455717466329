import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../helpers/messages';

import StudentSearchForm from './SearchForm';
import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import ExcelButton from '../../components/UI/ExcelButton';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import SortingHeader from '../../components/UI/SortingHeader';
import FloatingButtonAction from '../../components/UI/FloatingButtonAction';

import {
    fetchStudents,
    downloadExcel,
    selectStudent,
    deleteStudent,
    downloadFoto,
    downloadAdjunto
} from '../../actions/student';
import {
    startLoading,
    endLoading
} from '../../actions/ui';

const ScreensStudentList = ({ nombre, apellidos, email, pais, demarcacion_id, cursos_tipo_id, horas_desde, horas_hasta }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, filter } = useSelector(state => state.student);
    const { sort, direction } = paging;

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchStudents(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging, ...filter });
    }, [dispatch, filter, fetchData]);

    useEffect(() => {
        dispatch(selectStudent(null));
    }, [dispatch]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, page: index });
    };

    const handleSort = (name) => {
        let newDirection = 'asc';
        if (sort === name) {
            newDirection = (direction === 'asc') ? 'desc' : 'asc';
        }
        fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
    };

    const handleEdit = (item) => {
        dispatch(selectStudent(item));
        history.push('/alumnos/formulario');
    };

    const handleDelete = (id, name) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-student-text', { name }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteStudent(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    const handleExcel = () => {
        dispatch(startLoading());
        dispatch(
            downloadExcel({
                nombre: nombre,
                apellidos: apellidos,
                email: email,
                pais: pais,
                demarcacion_id: demarcacion_id,
                cursos_tipo_id: cursos_tipo_id,
                horas_desde: horas_desde,
                horas_hasta: horas_hasta
            })
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleAdd = () => {
        dispatch(selectStudent(null));
        history.push('/alumnos/formulario');
    };

    const handleCoursesDone = (item) => {
        dispatch(selectStudent(item));
        history.push('/alumnos/cursos-realizados');
    };

    const handleTitulaciones = (item) => {
        dispatch(selectStudent(item));
        history.push('/alumnos/titulaciones');
    };

    const handleDownloadFoto = (alumno) => {
        dispatch(downloadFoto(alumno));
    };

    const handleDownloadAdjunto = (alumno) => {
        dispatch(downloadAdjunto(alumno));
    };

    const handleView = (item) => {
        dispatch(selectStudent(item));
        history.push('/alumnos/detalle');
    }

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={t('screens-student-list-apellidos')}
                            direction={(sort === 'apellidos') ? direction : ''}
                            onClick={() => handleSort('apellidos')}
                        />
                        <SortingHeader title={t('screens-student-list-nombre')}
                            direction={(sort === 'nombre') ? direction : ''}
                            onClick={() => handleSort('nombre')}
                        />
                        <SortingHeader title={t('screens-student-list-email')}
                            direction={(sort === 'email') ? direction : ''}
                            onClick={() => handleSort('email')}
                        />
                        <th>{t('screens-list-acciones')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={item.id}>
                                <td>{item.apellidos}</td>
                                <td>{item.nombre}</td>
                                <td>{item.email}</td>
                                <td>
                                    <Can perform="Alumnos:edit" yes={() => (
                                        <ActionButton icon="edit"
                                            tooltip={t('screens-list-acciones-edit')}
                                            extraClass="btn-floating btn-small waves-effect waves-light green darken-3"
                                            onClick={() => handleEdit(item)} />
                                    )} data={item} />
                                    <Can perform="Alumnos:view" yes={() => (
                                        <ActionButton icon="search"
                                            tooltip={t('screens-list-acciones-view')}
                                            extraClass="btn-floating btn-small waves-effect waves-light cyan darken-2 ml-3"
                                            onClick={() => handleView(item)} />
                                    )} data={item} />
                                    <Can perform="AlumnosCursos:cursosRealizados" yes={() => (
                                        <ActionButton icon="event_available"
                                            tooltip={t('screens-list-acciones-courses')}
                                            extraClass="btn-floating btn-small waves-effect waves-light blue darken-3 ml-3"
                                            onClick={() => handleCoursesDone(item)} />
                                    )} />
                                    <Can perform="Alumnos:titulaciones" yes={() => (
                                        <ActionButton icon="card_membership"
                                            tooltip={t('screens-list-acciones-titulaciones')}
                                            extraClass="btn-floating btn-small waves-effect waves-light orange darken-3 ml-3"
                                            onClick={() => handleTitulaciones(item)} />
                                    )} />
                                    {
                                        (item.foto)
                                            ? <Can perform="Alumnos:downloadFoto" yes={() => (
                                                <ActionButton icon="photo_camera"
                                                    tooltip={t('screens-list-acciones-foto')}
                                                    extraClass="btn-floating btn-small waves-effect waves-light grey darken-3 ml-3"
                                                    onClick={() => handleDownloadFoto(item)} />
                                            )} />
                                            : ''
                                    }
                                    {
                                        (item.adjunto)
                                            ? <Can perform="Alumnos:downloadAdjunto" yes={() => (
                                                <ActionButton icon="attach_file"
                                                    tooltip={t('screens-list-acciones-adjunto')}
                                                    extraClass="btn-floating btn-small waves-effect waves-light grey darken-3 ml-3"
                                                    onClick={() => handleDownloadAdjunto(item)} />
                                            )} />
                                            : ''
                                    }
                                    <Can perform="Alumnos:delete" yes={() => (
                                        <ActionButton icon="delete_forever"
                                            tooltip={t('screens-list-acciones-delete')}
                                            extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-3"
                                            onClick={() => handleDelete(item.id, `${item.nombre} ${item.apellidos}`)} />
                                    )} data={item} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>

            <PageTitle title={t('screens-student-list-title')}>
                <Can perform="Alumnos:excel" yes={() => (
                    <ExcelButton onPressButton={handleExcel} />
                )} />
            </PageTitle>

            <StudentSearchForm />

            <ContentContainer>
                {renderTable()}
            </ContentContainer>

            <Pagination paging={paging} onPaginate={handlePaginate} />

            <Spacer height="5" />

            <Can perform="Alumnos:add" yes={() => (
                <FloatingButtonAction icon="add"
                    title={t('screens-list-acciones-add')} action={handleAdd}
                />
            )} />

        </PageContainer>
    );

};

const formSelector = formValueSelector('studentSearchForm');

export default connect(state => ({
    nombre: formSelector(state, 'nombre'),
    apellidos: formSelector(state, 'apellidos'),
    email: formSelector(state, 'email'),
    pais: formSelector(state, 'pais'),
    demarcacion_id: formSelector(state, 'demarcacion_id'),
    cursos_tipo_id: formSelector(state, 'cursos_tipo_id'),
    horas_desde: formSelector(state, 'horas_desde'),
    horas_hasta: formSelector(state, 'horas_hasta'),
}))(ScreensStudentList);