import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initialize, reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import i18n from 'i18next';

import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Spacer from '../../components/UI/Spacer';
import FormButton from '../../components/UI/FormButton';
import Divider from '../../components/UI/Divider';

import {
    renderFieldOneColumn,
    renderFieldTwoColumns,
    renderSelectFieldTwoColumns,
    renderTextareaOneColumn,
    renderCheckFieldTwoColumns
} from '../../components/UI/Fileds';

import { sendTrainer } from '../../actions/trainer';
import { startLoading, endLoading } from '../../actions/ui';

import { showError, showConfirmation } from '../../helpers/messages';
import { stringTrim } from '../../helpers/converter';

const ScreensTrainerForm = ({ submitting, handleSubmit, dirty }) => {

    const { t } = useTranslation();

    const { selected } = useSelector(state => state.trainer);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        if (selected) {
            dispatch(initialize('trainerForm', {
                id: selected.id,
                label: selected.label,
                nombre: selected.nombre,
                apellido: selected.apellido,
                email: selected.email,
                movil: selected.movil,
                dni: selected.dni,
                domicilio: selected.domicilio,
                //provincia: selected.provincia.id,                
                cp: selected.cp,
                especialidad: selected.especialidad,
                titulaciones: selected.titulaciones,
                acceso_drive: selected.acceso_drive,
                activo: selected.activo,
                observaciones: selected.observaciones,
                // foto: selected.foto,
                // adjunto: selected.adjunto,
                // constelador_acreditado: selected.constelador_acreditado,
                // es_constelador: selected.es_constelador,
                // capacidad_consteladora: selected.capacidad_consteladora,
                // libros_comentados: selected.libros_comentados,
                // fecha_capacidad_consteladora: selected.fecha_capacidad_consteladora,
                // master: selected.master,
                // maestria: selected.maestria,
                // especialista: selected.especialista,
                // experto: selected.experto,
                // fecha_master: selected.fecha_master,
                // datepicker_comienzo: dateToDatepicker(selected.comienzo),
                // datepicker_fecha_master: dateToDatepicker(selected.fecha_master),
                // datepicker_fecha_capacidad_consteladora: dateToDatepicker(selected.fecha_capacidad_consteladora),
                // acepta_politica_privacidad: (selected.user) ? selected.user.acepta_politica_privacidad : '',
                // acepta_emails: (selected.user) ? selected.user.acepta_emails : ''
            }));

        } else {
            dispatch(initialize('trainerForm', {
                id: '',
                label: '',
                nombre: '',
                apellido: '',
                email: '',
                movil: '',
                dni: '',
                domicilio: '',
                //provincia: '',
                cp: '',
                especialidad: '',
                titulaciones: '',
                acceso_drive: false,
                activo: false,
                observaciones: '',
            }));
        }
    }, [dispatch, selected]);

    const handleSend = (values) => {       
        dispatch(startLoading());
        dispatch(
            sendTrainer({ Trainers: values })
        ).then((res) => {
            dispatch(endLoading());
            if (res.ok) {
                history.goBack();
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleCancel = () => {
        if (dirty) {
            showConfirmation(
                t('message-title-confirmation'),
                t('message-abandom-form-text'),
                t('button-title-exit'),
                t('button-title-keep-here'),
                () => {
                    history.goBack();
                }
            );
        } else {
            history.goBack();
        }
    };

    const renderForm = () => {
        return (
            <form onSubmit={handleSubmit(handleSend)}>
                <Divider />
                <div className="row margin">
                    <Field type="checkbox" name="acceso_drive" id="acceso_drive"
                        className="filled-in"
                        label={t('Acceso a Google Drive')}
                        component={renderCheckFieldTwoColumns}
                        icon="check"
                        key="acceso_drive"
                    />
                    <Field type="checkbox" name="activo" id="activo"
                        className="filled-in"
                        label={t('activo')}
                        component={renderCheckFieldTwoColumns}
                        icon="check"
                        key="activo"
                    />
                </div>
                <Divider />

                {/* Campos del formulario */}
                <div className="row margin">
                    {
                        (selected)
                            ? (
                                <Field
                                    type="hidden"
                                    name="id"
                                    id="id"
                                    component={renderFieldOneColumn}
                                />
                            )
                            : ''
                    }

                    <Field type="text" name="nombre" id="nombre"
                        label={t('screens-student-list-nombre')}
                        component={renderFieldTwoColumns}
                        icon="person_outline"
                        key="nombre"
                        maxLength="128"
                    />

                    <Field type="text" name="apellido" id="apellido"
                        label={t('screens-student-list-apellidos')}
                        component={renderFieldTwoColumns}
                        icon="person"
                        key="apellido"
                        maxLength="128"
                    />
                </div>

                <div className="row margin">
                    <Field type="text" name="email" id="email"
                        label={t('screens-student-list-email')}
                        component={renderFieldTwoColumns}
                        icon="email"
                        key="email"
                        maxLength="256"
                    />
                    <Field type="text" name="dni" id="dni"
                        label={t('screens-student-list-dni')}
                        component={renderFieldTwoColumns}
                        icon="fingerprint"
                        key="dni"
                        maxLength="9"
                    />


                    {/* <Field name="demarcacion_id" id="demarcacion_id" 
                        label={ t('screens-student-list-demarcacion') } 
                        component={ renderSelectFieldTwoColumns } 
                        icon="location_searching" 
                        key="demarcacion_id" 
                    >
                        <option value="">{ t('form-select-choose-one') }</option>
                        {
                            demarcacionList.map(demarcacion => (
                                (isValidArea(demarcacion.id))
                                ? <option value={ demarcacion.id } key={ demarcacion.id }>{ demarcacion.nombre }</option>
                                : <></>
                            ))
                        }
                    </Field> */}
                </div>

                <div className="row margin">
                    <Field type="text" name="movil" id="movil"
                        label={t('screens-student-list-telefono_movil')}
                        component={renderFieldTwoColumns}
                        icon="phone_android"
                        key="movil"
                        maxLength="24"
                    />
                    
                    <Field type="text" name="cp" id="cp"
                        label={t('screens-student-list-codigo_postal')}
                        component={renderFieldTwoColumns}
                        icon="domain"
                        key="cp"
                        maxLength="5"
                    />
                </div>

                <div className="row margin">
                    
                <Field type="text" name="domicilio" id="domicilio"
                        label={t('screens-student-list-direccion')}
                        component={renderTextareaOneColumn}
                        icon="contact_mail"
                        key="domicilio"
                        maxLength="256"
                    />
                </div>

                <div className="row margin">
                    {/* <Field type="text" name="provincia" id="provincia" 
                        label={ t('screens-student-list-provincia') } 
                        component={ renderFieldTwoColumns } 
                        icon="map" 
                        key="provincia" 
                        maxLength="128"
                    /> */}

                    
                </div>


                <Divider />
                <div className="row margin">
                    <Field type="text" name="especialidad" id="especialidad"
                        label={t('Especialidades')}
                        component={renderTextareaOneColumn}
                        icon="Especial"
                        key="especialidad"
                        maxLength="512"
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="titulaciones" id="titulaciones"
                        label={t('Titulaciones')}
                        component={renderTextareaOneColumn}
                        icon="titles"
                        key="titulaciones"
                        maxLength="512"
                    />
                </div>
                <div className="row margin">
                    <Field type="text" name="observaciones" id="observaciones"
                        label={t('screens-student-list-otros_datos')}
                        component={renderTextareaOneColumn}
                        icon="assignment"
                        key="observaciones"
                        maxLength="512"
                    />
                </div>

                <Spacer height="2" />

                <Divider />

                {/* Botones del formulario */}
                <div className="row">
                    <div className="input-field col s12">
                        <FormButton type="submit"
                            title={t('form-button-send')}
                            disabled={submitting}
                        />

                        <FormButton type="reset"
                            title={t('form-button-cancel')}
                            disabled={submitting}
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </form>
        );
    };
    return (
        <PageContainer>
            <PageTitle title={t('screens-trainer-form-title')} />

            <ContentContainer>
                {renderForm()}
            </ContentContainer>

            <Spacer height="5" />
        </PageContainer>
    );

};

const validate = (values) => {
    const errors = {};

    if (!values.nombre) {
        errors.nombre = i18n.t('validator-error-nombre');
    }

    if (!values.descripcion) {
        errors.descripcion = i18n.t('validator-error-descripcion');
    }

    return errors;
};

export default reduxForm({
    form: 'trainerForm',
    validate: validate,
    destroyOnUnmount: false //true
})(ScreensTrainerForm);

