import TYPES from './types';
import { apiClientWithToken, downloadExcelFile, downloadFile } from '../services/backend';
import fileDownload from 'js-file-download';

export const fetchStudentDiplomas = (filter) => {
    return async (dispatch) => {
        try {
            
            const res = await apiClientWithToken().get(
                '/alumnos-diplomas', { params: { ...filter } }
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                dispatch(setStudentDiplomas(data));
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: data.message };
            }

        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' }
        }
    };
};

const setStudentDiplomas = (data) => ({
   type: TYPES.SET_STUDENT_DIPLOMAS, payload: data
});

export const downloadExcel = (filter) => {
    return async () => {
        try {

            const res = await downloadExcelFile(
                '/alumnos-diplomas/excel', 
                'alumnos-diplomas.xlsx',
                filter, {
                    ciudad: 'Diplomas',
                    fecha: 'Diplomas',
                    nombre: 'Alumnos',
                    apellidos: 'Alumnos',
                    diplomas_tipo_id: 'Diplomas'
                }
            );
            const blob = await res.blob();
            fileDownload(blob, 'alumnos-diplomas.xlsx');

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const download = (id) => {
    return async () => {
        try {

            const res = await downloadFile(
                `/alumnos-diplomas/descargarDiplomaAlumno/${ id }`, 
                `diploma-${id}.pdf`
            );
            const blob = await res.blob();
            fileDownload(blob, `diploma-${id}.pdf`);

            return { ok: true };
    
        } catch (error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const selectStudentDiploma = (data) => ({
    type: TYPES.SELECT_STUDENT_DIPLOMA, payload: data
});

export const setStudentDiplomaFilter = (filter) => ({
    type: TYPES.SET_STUDENT_DIPLOMA_FILTER, payload: filter
});

export const deleteStudentDiploma = (id) => {
    return async () => {
        try {
            
            const res = await apiClientWithToken().delete(
                `/alumnos-diplomas/${id}`
            );

            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};

export const sendStudentDiploma = (studentDiploma) => {
    return async () => {
        try {
            let res;

            if (studentDiploma.AlumnosDiplomas.id) {
                res = await apiClientWithToken().put(
                    `/alumnos-diplomas/${studentDiploma.AlumnosDiplomas.id}`,
                    studentDiploma
                );
            } else {
                res = await apiClientWithToken().post(
                    '/alumnos-diplomas', studentDiploma
                );
            }
            
            const { ok, result: { data } } = res.data;

            if (ok) {
                return { ok: true, message: data.message };
            } else {
                return { ok: false, message: data.message };
            }

        } catch(error) {
            console.log(error);
            return { ok: false, message: 'message-server-error' };
        }
    };
};