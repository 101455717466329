import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Can from "../../../routes/Can";
import KPITiles from "../../../components/UI/KPITiles";
import { fetchKpiList } from "../../../actions/ui";
import { setCourseFilter } from "../../../actions/course";
import { useHistory } from "react-router-dom";
import { setEventFilter } from "../../../actions/planner";
import { fi } from "date-fns/locale";

const CustomKPI = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchKpiList());

    const interval = setInterval(() => {
      dispatch(fetchKpiList());
    }, 30000); // Fetches new KPI data every 30 seconds

    return () => clearInterval(interval);
  }, [dispatch]);
  const { kpiList } = useSelector((state) => state.ui);

  const statusIcons = {
    sin_planificar: "schedule",
    planificado: "check_circle",
    borrador: "drafts",
    suspendido: "pause_circle_filled",
    cancelado: "cancel",
    finalizado: "done",
  };

  /* const mapEstadoToFilter = (estado) => {
    switch (estado) {
      case "sin_planificar":
        return 0;
      case "planificado":
        return 1;
      case "borrador":
        return 2;
      case "suspendido":
        return 3;
      default:
        return 0;
    }
  }; */
  const handleOnClick = async (value) => {
    const filterValue = value.tipo;

    const filter = {};

    console.log("filterValue", filterValue);
    console.log("estado", value.codigo);

    if (value.codigo === "sin_planificar") {
      //set filter for planner
      filter.group = 1;
      filter.estados = filterValue;
      dispatch(setCourseFilter(filter));
      history.push("/planner");
    } else {

      //set filter for calendar
      filter.activo = 1;
      filter.estados = [filterValue]; // set multiple values in array
      if (filterValue == 6) {
        filter.estados = 6;
      }
      dispatch(setEventFilter(filter));
      history.push("/planner/calendario");
    }
  };

  const kpis = Object.entries(kpiList).map(([estado, value]) => ({
    title: value.label,
    value: value.count,
    description: `Total ${value.count}`,
    icon: statusIcons[estado] || "info",
    estado,
    onClick: () => handleOnClick(value),
    show: value.show,
  }));

  return (
    <Can
      perform="KPI:index"
      yes={() => <KPITiles kpis={kpis} />}
      no={() => null}
    />
  );
};

export default CustomKPI;
