import React from 'react';
import { useSelector } from 'react-redux';

const Loading = () => {

    const { loading } = useSelector(state => state.ui);

    if (loading) {
        return (
            <div className="progress">
                <div className="indeterminate"></div>
            </div>
        );
    } else {
        return (<></>);
    }

};

export default Loading;