import TYPES from "./types";
import { apiClientWithToken, downloadExcelFile } from "../services/backend";
import fileDownload from "js-file-download";
import List from "../screens/Document/Course/List";

export const fetchCourseDocuments = (filter) => {
  return async (dispatch) => {
    const timestamp = Date.now();
    try {

      const res = await apiClientWithToken().get(
        `/documentos-cursos?timestamp=${timestamp}`,
        { params: { ...filter } }
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        dispatch(setCourseDocuments(data));
        return { ok: true, message: data.message, list: data.list };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

const setCourseDocuments = (data) => ({
  type: TYPES.SET_COURSE_DOCUMENTS,
  payload: data,
});

export const downloadExcel = (filter) => {
  return async () => {
    try {
      const res = await downloadExcelFile(
        "/documentos-cursos/excel",
        "documentos-cursos.xlsx",
        filter,
        {
          titulo: "Cursos",
          nombre_original: "Documentos",
          descripcion: "Documentos",
        }
      );
      const blob = await res.blob();
      fileDownload(blob, "documentos-cursos.xlsx");

      return { ok: true };
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const setCourseDocumentFilter = (filter) => ({
  type: TYPES.SET_COURSE_DOCUMENT_FILTER,
  payload: filter,
});

export const deleteCourseDocument = (id) => {
  return async () => {
    try {
      const res = await apiClientWithToken().delete(`/documentos-cursos/${id}`);

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};

export const sendCourseDocument = (courseDocument) => {
  return async () => {
    try {
      const res = await apiClientWithToken().post(
        "/documentos-cursos",
        courseDocument
      );

      const {
        ok,
        result: { data },
      } = res.data;

      if (ok) {
        return { ok: true, message: data.message };
      } else {
        return { ok: false, message: data.message };
      }
    } catch (error) {
      console.log(error);
      return { ok: false, message: "message-server-error" };
    }
  };
};
