import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValueSelector } from 'redux-form';
import { useHistory } from "react-router-dom";

import Can from '../../routes/Can';

import { showError, showConfirmation, showSuccess } from '../../helpers/messages';

import CustomerSearchForm from './SearchForm';
import PageTitle from '../../components/UI/PageTitle';
import PageContainer from '../../components/UI/PageContainer';
import ContentContainer from '../../components/UI/ContentContainer';
import Pagination from '../../components/UI/Pagination';
import ExcelButton from '../../components/UI/ExcelButton';
import Spacer from '../../components/UI/Spacer';
import ActionButton from '../../components/UI/ActionButton';
import SortingHeader from '../../components/UI/SortingHeader';
import FloatingButtonAction from '../../components/UI/FloatingButtonAction';

import {
    fetchCustomers,
    // downloadExcel, 
    selectCustomer,
    deleteCustomer
} from '../../actions/customer';

import {
    startLoading,
    endLoading
} from '../../actions/ui';

const ScreensCustomerList = ({ nombre_comercial, id }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { list, paging, filter } = useSelector(state => state.customer);
    const { sort, direction } = paging;

    const history = useHistory();

    const fetchData = useCallback((options) => {
        dispatch(startLoading());
        dispatch(
            fetchCustomers(options)
        ).then((res) => {
            dispatch(endLoading());
            if (!res.ok) {
                showError(t('message-title-error'), t(res.message));
            }
        });
    }, [dispatch, t]);

    useEffect(() => {
        fetchData({ ...paging, ...filter });
    }, [dispatch, filter, fetchData]);

    useEffect(() => {
        dispatch(selectCustomer(null));
    }, [dispatch]);

    const handlePaginate = (index) => {
        fetchData({ ...paging, ...filter, page: index });
    };

    // const handleSort = (name) => {
    //     let newDirection = 'asc';
    //     if (sort === name) {
    //         newDirection = (direction === 'asc') ? 'desc' : 'asc';
    //     }
    //     fetchData({ ...paging, ...filter, sort: name, direction: newDirection });
    // };

    const handleEdit = (item) => {
        dispatch(selectCustomer(item));
        history.push('/clientes/formulario');
    };

    const handleDelete = (id, razon_social) => {
        showConfirmation(
            t('message-title-confirmation'),
            t('message-delete-customer-text', { razon_social }),
            t('button-title-delete'),
            t('button-title-cancel'),
            () => {
                dispatch(startLoading());
                dispatch(
                    deleteCustomer(id)
                ).then((res) => {
                    dispatch(endLoading());
                    if (res.ok) {
                        showSuccess(t('message-title-success'), t(res.message));
                        fetchData({ ...paging, ...filter, page: 1 });
                    } else {
                        showError(t('message-title-error'), t(res.message));
                    }
                });
            }
        );
    };

    // const handleExcel = () => {
    //     dispatch(startLoading());
    //     dispatch(
    //         downloadExcel({
    //             nombre: nombre,
    //             apellidos: apellidos,
    //             demarcacion_id: demarcacion_id
    //         })
    //     ).then((res) => {
    //         dispatch(endLoading());
    //         if (!res.ok) {
    //             showError(t('message-title-error'), t(res.message));
    //         }
    //     });
    // };

    const handleAdd = () => {
        dispatch(selectCustomer(null));
        history.push('/clientes/formulario');
    };

    const renderTable = () => {
        return (
            <table className="dataTable striped highlight responsive-table">
                <thead>
                    <tr>
                        <SortingHeader title={t('screens-customer-list-nombre_comercial')}
                            direction={(sort === 'nombre_comercial') ? direction : ''}
                        />
                        <SortingHeader title={t('screens-customer-list-razon_social')}
                            direction={(sort === 'razon_social') ? direction : ''}

                        />
                        <SortingHeader title={t('screens-customer-list-nif')}
                            direction={(sort === 'nif') ? direction : ''}
                        />
                        <th>{t("screens-customer-list-telefono")}</th>
                        <th>{t("screens-customer-list-direccion")}</th>
                        <th>{t("screens-customer-list-activo")}</th>
                        <th>{t('screens-list-acciones')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map((item) => (
                            <tr key={item.id}>
                                <td>{item.nombre_comercial}</td>
                                <td>{item.razon_social}</td>
                                <td>{item.nif}</td>
                                <td>{item.telefono}</td>
                                <td>{item.direccion}</td>
                                <td>
                                    {item.activo ? (
                                        <span className="chip green lighten-5">
                                            <span className="green-text">{t("Activo")}</span>
                                        </span>
                                    ) : (
                                        <span className="chip red lighten-5">
                                            <span className="red-text">{t("Desactivado")}</span>
                                        </span>
                                    )}
                                </td>
                                {
                                    <td>

                                        <Can perform="Customers:edit" yes={() => (
                                            <ActionButton icon="edit"
                                                tooltip={t('screens-list-acciones-edit')}
                                                extraClass="btn-floating btn-small waves-effect waves-light green darken-3" onClick={() => handleEdit(item)} />
                                        )} />
                                        <span className="mr-3"></span>


                                        <Can perform="Customers:delete" yes={() => (
                                            <ActionButton icon="delete_forever"
                                                tooltip={t('screens-list-acciones-delete')}

                                                extraClass="btn-floating btn-small waves-effect waves-light red darken-3 ml-3"
                                                onClick={() => handleDelete(item.id, item.razon_social)} />
                                        )} />
                                    </td>}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    };

    return (
        <PageContainer>

            <PageTitle title={t('screens-customer-list-title')}>
                <Can perform="Customers:excel" />
            </PageTitle>

            {<CustomerSearchForm />}

            <ContentContainer>
                {renderTable()}
            </ContentContainer>

            <Pagination paging={paging} onPaginate={handlePaginate} />

            <Spacer height="5" />

            <Can perform="Customers:add" yes={() => (
                <FloatingButtonAction icon="add"
                    title={t('screens-list-acciones-add')} action={handleAdd}
                />
            )} />

        </PageContainer>
    );

};

const formSelector = formValueSelector('organizerSearchForm');

export default connect(state => ({
    nombre_comercial: formSelector(state, 'nombre_comercial'),
    id: formSelector(state, 'id')
}))(ScreensCustomerList);